import { Box, alpha } from "@mui/material";
import { ReactNode } from "react";
import Dragatron from "../../../assets/img/dragatron.png";
import { CalendarProvider } from "../../../context/CalendarContext";

type Props = {
  children: ReactNode;
};

const Topbar = () => {
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "center",
        px: "2rem",
      })}
    >
      <Box
        sx={{
          position: "absolute",
          height: "auto",
        }}
      >
        <a href="https://www.dragatron.com.au" target="_blank" rel="noreferrer">
          <img
            src={Dragatron}
            alt="Dragatron"
            className=" object-cover aspect-auto"
          />
        </a>
      </Box>
    </Box>
  );
};

const UpdateReservationLayout = ({ children }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        background: alpha(theme.palette.primary.main, 0.2),
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      })}
    >
      <Box
        sx={(theme) => ({
          position: "sticky",
          top: 0,
          zIndex: theme.zIndex.appBar,
        })}
      >
        <Topbar />
      </Box>
      <Box
        position="relative"
        display="flex"
        justifyContent={"center"}
        alignItems={"start"}
      >
        <Box
          sx={(theme) => ({
            width: "45%",
            p: "1rem",
            [theme.breakpoints.between("xs", "sm")]: {
              width: "100%",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "100%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "70%",
            },
          })}
        >
          <CalendarProvider>{children}</CalendarProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateReservationLayout;
