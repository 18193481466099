import {
  ServiceCategory,
  Service,
  PharmacyAvailability,
} from "@/codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { useApp } from "@/context/AppContext";
import { useAvailabilities } from "@/hooks/useAvailabilities";
import { usePharmacy } from "@/hooks/usePharmacy";
import { useServiceCategories } from "@/hooks/useServiceCategories";
import { useServices } from "@/hooks/useServices";
import { IPharmacy } from "@/interfaces/IPharamacy";
import {
  formatToApharmacy,
  formatToAServiceCategory,
  formatToAService,
  formatToAvailability,
} from "@/lib/layers";
import { FormEvent, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, Card, Divider, Typography } from "@mui/material";
import { BackNavigation } from "@/components/new/BackNavigation";
import { dateLib } from "@/lib/_calendar";
import {
  LocalPharmacyOutlined,
  AccessTimeOutlined,
  PaymentOutlined,
  SummarizeOutlined,
} from "@mui/icons-material";
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";

import { PaymentIntentResult } from "@stripe/stripe-js";
import { formatToPrice, revertCalculatedPrice } from "@/lib/utils";
import { useModal } from "@/context/DialogContext";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import { IService } from "@/interfaces/IService";
import { IBooking } from "@/interfaces/IBooking";

type CardTitleProps = {
  Icon: ReactNode;
  title: string;
};

interface IPaymentFormProps {
  clientSecret?: string;
  service: IService | null;
  booking: IBooking | null;
}

const CardTitle = ({ Icon, title }: CardTitleProps) => {
  return (
    <Box display="flex" gap=".5rem">
      <Box>{Icon}</Box>
      <Typography variant="body1" fontWeight="bold">
        {title}
      </Typography>
    </Box>
  );
};

const PaymentForm = (props: IPaymentFormProps) => {
  const [currentIntent, setCurrentIntent] =
    useState<PaymentIntentResult | null>(null);
  const paramsData = useParams();
  const { openModal, closeModal } = useModal();
  const {
    setSelectedAvailability,
    setSelectedPharmacy,
    setSelectedService,
    setLoading,
    loading,
    setSelectedCategory,
    selectedPharmacy,
    selectedAvailability,
  } = useApp();

  const stripe = useStripe();
  const elements = useElements();

  const pharmacyID = paramsData.pharmacyID;
  const categoryID = paramsData.categoryID;
  const serviceID = paramsData.serviceID;
  const availabilityID = paramsData.availabilityID;

  const { getPharmacy } = usePharmacy();
  const { getServiceCategory } = useServiceCategories();
  const { getService } = useServices();
  const { getAvailability } = useAvailabilities();

  const [message, setMessage] = useState<string | null>(null);

  // set selected pharmacy based on the route
  useEffect(() => {
    if (pharmacyID) {
      setLoading(true);
      getPharmacy({
        variables: {
          id: pharmacyID,
        },
        onCompleted(data) {
          const pharmacy = data.getPharmacy as IPharmacy;
          setSelectedPharmacy(formatToApharmacy(pharmacy));
          setLoading(false);
        },
        onError(error) {
          setLoading(false);
        },
      });
    }
  }, [pharmacyID]);

  // set selected category based on the route
  useEffect(() => {
    if (categoryID) {
      setLoading(true);
      getServiceCategory({
        variables: {
          categoryID: categoryID,
        },
        onCompleted(data) {
          const category = data.getServiceCategory as ServiceCategory;
          setSelectedCategory(formatToAServiceCategory(category));
          setLoading(false);
        },
        onError(error) {
          setLoading(false);
        },
      });
    }
  }, [categoryID]);

  // set selected service based on the route
  useEffect(() => {
    if (serviceID) {
      setLoading(true);
      getService({
        variables: {
          serviceId: serviceID,
        },
        onCompleted(data) {
          const service = data.getService as Service;
          // console.log(service, "service")
          setSelectedService(formatToAService(service));
          setLoading(false);
        },
        onError(error) {
          setLoading(false);
        },
      });
    }
  }, [serviceID]);

  // set selected availability based on the route
  useEffect(() => {
    if (availabilityID) {
      setLoading(true);
      getAvailability({
        variables: {
          availabilityID: availabilityID,
        },
        onCompleted(data) {
          const availability =
            data.getPharmacyAvailability as PharmacyAvailability;
          setSelectedAvailability(formatToAvailability(availability));
          setLoading(false);
        },
        onError(error) {
          setLoading(false);
        },
      });
    }
  }, [availabilityID]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error: validationError } = await elements.submit();

    if (validationError) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/payments/${props.booking?.bookingID}/callback`,
      },
      redirect: "always",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message || null);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (props.clientSecret && stripe) {
      stripe.retrievePaymentIntent(props.clientSecret).then((value) => {
        // console.log(value);
        setCurrentIntent(value);
      });
    }
  }, [props.clientSecret, stripe]);

  useEffect(() => {
    if (loading) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
    } else {
      closeModal();
    }
  }, [loading]);

  return (
    <Box
      width="100%"
      component="form"
      id="payment-form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {/* Payment Detail */}
      <Card
        sx={(theme) => ({
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        })}
      >
        <BackNavigation
          title="Payment"
          description="Please complete the payment process."
          onClick={() => console.log("Show modal confirm")}
        />
        <div className="lg:py-8 md:py-8 xl:py-8 lg:px-8 xl:px-8 md:px-8 flex flex-col gap-2 py-4 px-3 my-4 bg-primary/10 rounded-md">
          <div className="flex gap-4">
            <LocalPharmacyOutlined color="primary" />
            <div>
              <Typography className="xl:font-semibold lg:font-semibold md:font-semibold font-medium text-base mb-1">
                {selectedPharmacy?.pharmacyName}
              </Typography>

              <Typography className=" font-normal text-grey">
                <a
                  href={`http://maps.google.com/maps?q=${selectedPharmacy?.pharmacyAddress}`}
                  target="_blank"
                  className="font-normal text-grey"
                >
                  {selectedPharmacy?.pharmacyAddress}
                </a>
              </Typography>
              <Typography className=" font-normal text-grey">
                <a
                  href={`tel:${selectedPharmacy?.pharmacyPhoneNumber}`}
                  target="_blank"
                  className="font-normal text-grey"
                >
                  {selectedPharmacy?.pharmacyPhoneNumber}
                </a>
              </Typography>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <AccessTimeOutlined color="primary" />

            <Typography className=" xl:font-semibold lg:font-semibold md:font-semibold font-medium text-base">
              {dateLib(selectedAvailability?.timeslot)
                .clone()
                .format("dddd, MMMM Do YYYY, h:mm a")}
            </Typography>
          </div>
        </div>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            mb: "1rem",
          })}
        >
          <CardTitle
            Icon={<PaymentOutlined color="primary" />}
            title="Billing Detail"
          />
          <Box>
            <AddressElement
              options={{
                mode: "billing",
                allowedCountries: ["au", "id", "us"],
                fields: {
                  phone: "always",
                },
                autocomplete: {
                  mode: "automatic",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          })}
        >
          <CardTitle
            Icon={<PaymentOutlined color="primary" />}
            title="Credit Card"
          />
          {message && <Alert severity="error">{message}</Alert>}

          <Box>
            <PaymentElement
              onLoadError={(event) => {
                setMessage(
                  event.error.message || "An unexpected error occurred."
                );
              }}
            />
          </Box>
        </Box>
      </Card>

      {/* Summary */}
      <Card
        sx={(theme) => ({
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        })}
      >
        <CardTitle
          Icon={<SummarizeOutlined color="primary" />}
          title="Summary"
        />
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          })}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Subtotal</Typography>
            <Typography>
              {formatToPrice(
                revertCalculatedPrice(props.service!.price).originalAmount,
                currentIntent?.paymentIntent?.currency || "AUD"
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>GST +10%</Typography>
            <Typography>
              {formatToPrice(
                revertCalculatedPrice(props.service!.price).gst,
                currentIntent?.paymentIntent?.currency || "AUD"
              )}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight="bold">Billed Now</Typography>
            <Typography fontWeight="bold">
              {formatToPrice(
                currentIntent?.paymentIntent?.amount || 0,
                currentIntent?.paymentIntent?.currency || "AUD"
              )}
            </Typography>
          </Box>
        </Box>
      </Card>
      {/* Submit button */}
      <Card
        sx={(theme) => ({
          padding: "1rem",
        })}
      >
        <Box
          sx={(theme) => ({
            p: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            [theme.breakpoints.between("xs", "sm")]: {
              p: 0,
            },
          })}
        >
          <Button
            disabled={loading || !stripe || !elements}
            fullWidth
            type="submit"
            variant="contained"
          >
            Process
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default PaymentForm;
