import { Steps } from "../context/StepperProvider";

export const steps: Steps[] = [
  {
    index: 0,
    name: "SELECT_CLINIC",
    label: "Select Clinic",
    path: "select-clinic",
    isVisible: true,
  },
  {
    index: 1,
    name: "SELECT_TIME",
    label: "Select Time",
    path: "select-time",
    isVisible: true,
  },
  {
    index: 2,
    name: "REVIEW_APPOINMENT",
    label: "Review",
    path: "review-appointment",
    isVisible: true,
  },
  {
    index: 3,
    name: "PAYMENT_DETAIL",
    label: "Payment",
    path: "payment-detail",
    isVisible: false,
  },
];

export const chooseTimeAndReviewStep: Steps[] = [
  {
    index: 0,
    name: "SELECT_SERVICE",
    label: "Select Service",
    path: "select-service",
    isVisible: true,
  },
  {
    index: 1,
    name: "SELECT_TIME",
    label: "Select Time",
    path: "select-time",
    isVisible: true,
  },
  {
    index: 2,
    name: "REVIEW_APPOINMENT",
    label: "Review",
    path: "review-appointment",
    isVisible: true,
  },
  {
    index: 3,
    name: "PAYMENT_DETAIL",
    label: "Payment",
    path: "payment-detail",
    isVisible: false,
  },
];

export enum steeperPath {
  SELECT_CLINIC = "select-clinic",
  SELECT_TIME = "select-time",
  REVIEW_APPOINMENT = "review-appointment",
  SELECT_SERVICE = "select-service",
  PAYMENT_DETAIL = "payment-detail",
}
