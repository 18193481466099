import { ServiceSchedule } from "@/codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { steeperPath } from "@/constant/stepper";
import { useApp } from "@/context/AppContext";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { IHour } from "@/interfaces/IHour";
import { IWeekSchedule } from "@/interfaces/IPharmacySchedule";
import { IService } from "@/interfaces/IService";
import { IServiceSchedule } from "@/interfaces/IServiceSchedule";
import { dateLib } from "@/lib/_calendar";
import { formatToAServiceSchedule } from "@/lib/layers";
import { cn } from "@/lib/utils";
import { ApolloError } from "@apollo/client/errors";
import { ScheduleOutlined } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Chip,
  Container,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type PharmacyServiceProps = {
  service: IService;
} & BoxProps;

type ScheduleTextProps = {
  loading: boolean;
  error?: ApolloError | Error;
  openTime: Moment;
  closeTime: Moment;
};

const ScheduleText = ({
  loading,
  error,
  openTime,
  closeTime,
}: ScheduleTextProps) => {
  if (loading) {
    return <Skeleton variant="text" width="50%" />;
  }

  if (error) {
    return <Typography>N/A</Typography>;
  }

  return (
    <Typography>
      {openTime.format("hh:mm A") || "N/A"} -{" "}
      {closeTime.format("hh:mm A") || "N/A"}
    </Typography>
  );
};

const PharmacyService = ({ service, ...rest }: PharmacyServiceProps) => {
  const { selectedService, setSelectedService, schedule } = useApp();
  // const [schedule, setSchedule] = useState<IServiceSchedule | null>(null);
  const { getServiceSchedule, loading, error } = useReservationEndpoint();
  const navigate = useNavigate();
  const params = useParams();
  const pharmacyID = params.pharmacyID;
  const categoryID = params.categoryID;
  const serviceID = params.serviceID;

  // useEffect(() => {
  //   if (service) {
  //     getServiceSchedule(service.serviceScheduleID)
  //       .then((response) => {
  //         setSchedule(
  //           formatToAServiceSchedule(
  //             (response?.data as ServiceSchedule) || null
  //           )
  //         );
  //       })
  //       .catch((reason) =>
  //         console.error("Error getting service schedule ", reason)
  //       );
  //   }
  // }, [service]);

  const getHoursInNumber = (hours: string | null | undefined) => {
    if (!hours) {
      return 0;
    }

    const hour = hours.split(":")[0];

    return Number(hour);
  };

  const getMinutesHoursInNumber = (hours: string | null | undefined) => {
    if (!hours) {
      return 0;
    }

    const minute = hours.split(":")[1];

    return Number(minute);
  };

  const isActive = selectedService?.serviceId === service.serviceId;
  const today = dateLib().clone().tz("Australia/Sydney");
  const todaySchedule = schedule?.[
    today.format("dddd").toLowerCase() as keyof IWeekSchedule
  ] as IHour;

  const openTime = today
    .clone()
    .set("hour", getHoursInNumber(todaySchedule?.openingHour))
    .set("minute", getMinutesHoursInNumber(todaySchedule?.openingHour))
    .set("millisecond", 0);

  const closeTime = today
    .clone()
    .set("hour", getHoursInNumber(todaySchedule?.closingHour))
    .set("minute", getMinutesHoursInNumber(todaySchedule?.closingHour))
    .set("millisecond", 0);

  return (
    <Box component={"li"} {...rest}>
      <Paper
        className={cn(
          "p-4 shadow-md bg-white border-white border-2 border-solid shadow-grey-400/20 flex flex-col gap-2 rounded-lg my-2 cursor-pointer hover:border-primary hover:border-solid hover:border-2 hover:shadow-md hover:shadow-primary-400/20 hover:transition-all hover:ease-out",
          isActive &&
            "border-primary border-solid border-2 shadow-md shadow-primary-400/20"
        )}
        onClick={() => {
          if (!pharmacyID) {
            return;
          }

          setSelectedService(service || null);

          navigate(
            `/pharmacies/${pharmacyID}/appointment/${service.serviceId}/${steeperPath.SELECT_TIME}`
          );
        }}
      >
        <Container className="lg:px-0 xl:px-0 md:px-0 px-0 flex justify-between items-center w-full">
          <Typography fontWeight="bold">{service.serviceName}</Typography>
          {loading ? (
            <Typography variant="h5">
              <Skeleton
                variant="rounded"
                sx={{
                  maxWidth: "5rem",
                }}
              />
            </Typography>
          ) : (
            <Chip label={`${service!.durationInSeconds / 60} mins`} />
            // <Typography
            //   className={cn(
            //     "px-4 py-1 rounded-full text-white text-sm sm:w-fit md:w-fit xl:w-fit lg:w-fit",
            //     isOpen ? "bg-success" : "bg-danger"
            //   )}
            // >
            //   {isOpen ? "Open Now" : "Closed"}
            // </Typography>
          )}
        </Container>
        {/* <Container className="lg:px-0 xl:px-0 md:px-0 px-0"></Container> */}
        {/* <Container className="lg:px-0 xl:px-0 md:px-0 px-0 flex gap-4 items-center">
          <LocationIcon className="fill-transparent stroke-primary w-4 h-4" />
          <Typography>{service.description}</Typography>
        </Container> */}
        <Container className="lg:px-0 xl:px-0 md:px-0 px-0 flex gap-4 items-center">
          <ScheduleOutlined color="primary" />
          <ScheduleText
            loading={loading}
            error={error || undefined}
            openTime={openTime}
            closeTime={closeTime}
          />
        </Container>
      </Paper>
    </Box>
  );
};

export default PharmacyService;
