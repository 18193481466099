import { gql } from "@apollo/client";

// SERVICE
export const MUTATION_CREATE_SERVICE = gql`
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input) {
      createdDate
      durationInSeconds
      isActive
      pharmacyID
      pharmacyName
      serviceId
      serviceName
      serviceScheduleID
      suburb
      updatedDate
    }
  }
`;

// SERVICE SCHEDULE
export const MUTATION_CREATE_SERVICE_SCHEDULE = gql`
  mutation CreateServiceSchedule($input: CreateServiceScheduleInput!) {
    createServiceSchedule(input: $input) {
      createdDate
      isActive
      pharmacyID
      serviceScheduleID
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

// HOURS

export const MUTATION_CREATE_HOUR = gql`
  mutation CreateHour($input: CreateHoursInput!) {
    createHours(input: $input) {
      ID
      closingHour
      isClosed
      openingHour
      pharmacyID
    }
  }
`;

// PHARMACY SCHEDULE

export const MUTATION_CREATE_PHARMACY_SCHEDULE = gql`
  mutation CreatePharmacyScheduleMutation(
    $input: CreatePharmacyScheduleInput!
  ) {
    createPharmacySchedule(input: $input) {
      createdDate
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      pharmacyID
      isActive
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      id
    }
  }
`;

// AVAILABILITY

export const MUTATION_CREATE_AVAILABILITY = gql`
  mutation CreatePharmacyAvailability(
    $input: CreatePharmacyAvailabilityInput!
  ) {
    createPharmacyAvailability(input: $input) {
      availabilityID
      durationInSeconds
      expire_at
      isAvailable
      maxSlotsAvailable
      maxSlotsInStore
      pharmacyID
      rooms {
        isAvailable
        roomID
        roomName
      }
      serviceId
      serviceName
      timeslot
    }
  }
`;

export const MUTATION_UPDATE_AVAILABILITY = gql`
  mutation UpdatePharmacyAvailability(
    $input: UpdatePharmacyAvailabilityInput!
  ) {
    updatePharmacyAvailability(input: $input) {
      availabilityID
      durationInSeconds
      expire_at
      isAvailable
      maxSlotsAvailable
      maxSlotsInStore
      pharmacyID
      serviceId
      serviceName
      timeslot
      rooms {
        isAvailable
        roomID
        roomName
      }
    }
  }
`;

// BOOKING

export const MUTATION_CREATE_BOOKING = gql`
  mutation CreateBooking($input: CreateBookingsInput!) {
    createBookings(input: $input) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        token
        clientSecret
        status
      }
      notes
      IsAssessmentCompleted
    }
  }
`;

export const MUTATION_UPDATE_BOOKING = gql`
  mutation UpdateBooking($input: UpdateBookingsInput!) {
    updateBookings(input: $input) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        token
        clientSecret
        status
      }
      notes
      IsAssessmentCompleted
    }
  }
`;
