import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from "@mui/material";
import AddOutlined from "@mui/icons-material/AddOutlined";
import RemoveOutlined from "@mui/icons-material/RemoveOutlined";

interface ScreeningAccordionProps {
  title: string;
  AccordionDetail: ReturnType<typeof AccordionDetails>;
}
const ScreeningAccordion = ({
  AccordionDetail,
  title,
}: ScreeningAccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  const alignedSizeTitleAndContent = 40;

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        boxShadow: "unset",
      }}
    >
      <AccordionSummary
        sx={{
          flexDirection: "row-reverse",
          px: 0,
        }}
        expandIcon={
          <IconButton
            sx={{
              width: alignedSizeTitleAndContent,
              height: alignedSizeTitleAndContent,
            }}
            color="primary"
          >
            {expanded ? <RemoveOutlined /> : <AddOutlined />}
          </IconButton>
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography
          sx={(theme) => ({
            [theme.breakpoints.between("xs", "sm")]: {
              textAlign: "justify",
            },
          })}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          ml: `${alignedSizeTitleAndContent}px`,
          pl: 0,
          [theme.breakpoints.between("xs", "sm")]: {
            px: 0,
          },
        })}
      >
        {AccordionDetail}
      </AccordionDetails>
    </Accordion>
  );
};

export default ScreeningAccordion;
