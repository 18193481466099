import { useState } from "react";
import PageWrapper from "../../PageWrapper";
import {
  Box,
  Button,
  Card,
  Fade,
  InputAdornment,
  OutlinedInput,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useApp } from "../../../context/AppContext";
import { SearchIcon } from "../../../components/Icons/Icons";
import Pharmacies from "./Pharmacies";
import { useNavigate, useParams } from "react-router-dom";
import { steeperPath } from "../../../constant/stepper";
import { BackNavigation } from "../../../components/new/BackNavigation";

const InputSearchClinics = ({
  onSearch,
}: {
  onSearch?: (value: string) => void;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  return (
    <OutlinedInput
      value={searchValue}
      fullWidth
      size={isMobile ? "small" : "medium"}
      onChange={(e) => {
        setSearchValue(e.target.value);
        if (typeof onSearch === "function") {
          onSearch(e.target.value);
        }
      }}
      placeholder="Type the clinic name"
      name="search"
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

const SelectPharmacy = () => {
  const {
    selectedService,
    selectedPharmacy,
    setSelectedAvailability,
    setSelectedPharmacy,
    setSelectedService,
  } = useApp();
  const params = useParams();
  const categoryID = params.categoryID;
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  return (
    <PageWrapper title="Select Clinic">
      <Card
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          p: "2rem",
          mb: selectedPharmacy && "9rem",
          [theme.breakpoints.between("xs", "sm")]: {
            p: "1rem",
            mb: selectedPharmacy && "6rem",
          },
        })}
      >
        <BackNavigation
          title="Select Clinic"
          description="List of the available clinic and service based on the selected location or selected category will show here."
          onClick={() => {
            setSelectedAvailability(null);
            setSelectedPharmacy(null);
            setSelectedService(null);
            navigate(-1);
          }}
        />

        <Box display="flex" alignItems="center" gap="1rem">
          <Box flex={1}>
            <InputSearchClinics onSearch={(value) => setSearchValue(value)} />
          </Box>
        </Box>

        <Pharmacies searchBy={searchValue} categoryID={categoryID || null} />

        <Fade
          in={selectedPharmacy !== null}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: theme.palette.common.white,
            width: "100%",
          }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="1rem"
          >
            <Box
              sx={(theme) => ({
                width: "30%",
                flexDirection: "column",
                display: "flex",
                [theme.breakpoints.between("xs", "sm")]: {
                  width: "100%",
                  px: ".5rem",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  width: "50%",
                  px: ".5rem",
                },
              })}
            >
              <Typography className="text-center" gutterBottom>
                <span>You've selected</span>&nbsp;
                <span className="font-semibold">
                  {selectedPharmacy?.pharmacyName}
                </span>
              </Typography>
              <Button
                fullWidth
                size={isMobile ? "medium" : "large"}
                variant="contained"
                onClick={() => {
                  navigate(
                    `${selectedService?.serviceId}/${steeperPath.SELECT_TIME}`
                  );
                }}
              >
                Next: Select Date & Time
              </Button>
            </Box>
          </Box>
        </Fade>
      </Card>
    </PageWrapper>
  );
};

export default SelectPharmacy;
