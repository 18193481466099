import { IAvailability } from "@/interfaces/IAvailability";
import { ServiceCategory } from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { TResult } from "../components/PlacesAutocompleteService/PlacesAutocompleteService";
import { steps } from "../constant/stepper";
import { IPrevReservation } from "../context/AppContext";
import { Steps } from "../context/StepperProvider";
import { IPharmacy } from "../interfaces/IPharamacy";
import { IService } from "../interfaces/IService";

interface IGlobalStates {
  selectedLocation: TResult | null;
  selectedCategory: ServiceCategory | null;
  selectedService: IService | null;
  selectedPharmacy: IPharmacy | null;
  selectedAvailability: IAvailability | null;
  steps: Steps;
}

interface IPrevReservationStates {
  prevReservation: IPrevReservation | null;
}

interface IVerifiedScreeningAcces {
  isVerified: boolean;
}

enum STATE_DESTINATION {
  GLOBAL = "GLOBAL",
  PREV_RESERVATION = "PREV_RESERVATION",
}

const GLOBAL_STATES_KEY = "_gs";
const PREV_RESERVATION_KEY = "_pr";
const PATIENT_VERIFIED = "_pv";

export class App {
  states: IGlobalStates | null = null;
  prevReservation: IPrevReservationStates | null = null;

  static setVeirifiedForScreening(payload: IVerifiedScreeningAcces) {
    return localStorage.setItem(PATIENT_VERIFIED, JSON.stringify(payload));
  }

  static getVeirifiedForScreening() {
    const verifiedStatus = JSON.parse(
      localStorage.getItem(PATIENT_VERIFIED) ||
        JSON.stringify({
          isVerified: false,
        })
    );

    return verifiedStatus as IVerifiedScreeningAcces;
  }

  saveStates(value: string) {
    localStorage.setItem(GLOBAL_STATES_KEY, value);
  }

  savePrevReservationStates(states: IPrevReservation | null) {
    const currentStates = this.getPrevReservationStates();
    currentStates.prevReservation = states;
    localStorage.setItem(PREV_RESERVATION_KEY, JSON.stringify(currentStates));
    return currentStates;
  }

  getPrevReservationStates() {
    const states = this.getStates(STATE_DESTINATION.PREV_RESERVATION);

    if (states === null) {
      const initState: IPrevReservationStates = {
        prevReservation: null,
      };

      localStorage.setItem(PREV_RESERVATION_KEY, JSON.stringify(initState));

      return initState;
    }

    const prevValue = JSON.parse(states) as IPrevReservationStates;

    return prevValue;
  }

  getStates(destination: STATE_DESTINATION = STATE_DESTINATION.GLOBAL) {
    if (destination === STATE_DESTINATION.GLOBAL) {
      const states = localStorage.getItem(GLOBAL_STATES_KEY);
      return states;
    }

    const states = localStorage.getItem(PREV_RESERVATION_KEY);
    return states;
  }

  getCurrentStates() {
    const states = this.getStates(STATE_DESTINATION.GLOBAL);

    if (states === null) {
      const initState: IGlobalStates = {
        selectedLocation: null,
        selectedPharmacy: null,
        selectedService: null,
        selectedCategory: null,
        steps: steps[0],
        selectedAvailability: null,
      };

      this.saveStates(JSON.stringify(initState));

      return initState;
    }

    return JSON.parse(states) as IGlobalStates;
  }

  saveSelectedCategory(selectedCategory: ServiceCategory | null) {
    const currentStates = this.getCurrentStates();
    currentStates.selectedCategory = selectedCategory;
    this.saveStates(JSON.stringify(currentStates));
    return currentStates;
  }

  saveSelectedService(selectedService: IService | null) {
    const currentStates = this.getCurrentStates();
    currentStates.selectedService = selectedService;
    this.saveStates(JSON.stringify(currentStates));
    return currentStates;
  }

  saveSteps(steps: Steps) {
    const currentStates = this.getCurrentStates();
    currentStates.steps = steps;
    this.saveStates(JSON.stringify(currentStates));
    return currentStates;
  }

  saveSelectedPharmacy(selectedPharmacy: IPharmacy | null) {
    const currentStates = this.getCurrentStates();
    currentStates.selectedPharmacy = selectedPharmacy;
    this.saveStates(JSON.stringify(currentStates));
    return currentStates;
  }

  saveSelectedLocation(selectedLocation: TResult | null) {
    const currentStates = this.getCurrentStates();
    currentStates.selectedLocation = selectedLocation;
    this.saveStates(JSON.stringify(currentStates));
    return currentStates;
  }

  saveSelectedAvailability(selectedAvailability: IAvailability | null) {
    const currentStates = this.getCurrentStates();
    currentStates.selectedAvailability = selectedAvailability;
    this.saveStates(JSON.stringify(currentStates));
    return currentStates;
  }

  getSelectedCategory() {
    const currentStates = this.getCurrentStates();
    return currentStates.selectedCategory;
  }

  getSelectedService() {
    const currentStates = this.getCurrentStates();
    return currentStates.selectedService;
  }

  getSelectedLocation() {
    const currentStates = this.getCurrentStates();
    return currentStates.selectedLocation;
  }

  getSelectedPharmacy() {
    const currentStates = this.getCurrentStates();
    return currentStates.selectedPharmacy;
  }

  getSteps() {
    const currentStates = this.getCurrentStates();
    return currentStates.steps;
  }

  getSelectedAvailability() {
    const currentStates = this.getCurrentStates();
    return currentStates.selectedAvailability;
  }

  resetGlobalState() {
    localStorage.removeItem(GLOBAL_STATES_KEY);
  }
}
