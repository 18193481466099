import { Box, Button, Typography, alpha } from "@mui/material";
import SuccessGif from "../../../assets/gif/success-animation.gif";
import { Link } from "react-router-dom";
import { useModal } from "../../../context/DialogContext";
import { useApp } from "../../../context/AppContext";
import { BOOKING_STATUS, IBooking } from "../../../interfaces/IBooking";

type Props = {
  booking: IBooking;
};

const ModalSuccessContent = ({ booking }: Props) => {
  const { closeModal } = useModal();
  const { resetGlobalState } = useApp();

  const isCancel =
    booking.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
    booking.status === BOOKING_STATUS.CANCELED;

  const message = isCancel ? "Canceled" : "Updated";
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={250} height={250} src={SuccessGif} alt="" />
      <Typography variant="h5">Operation Success!</Typography>
      <Typography
        sx={(theme) => ({
          color: alpha(theme.palette.common.black, 0.5),
          textAlign: "center",
        })}
        variant="h6"
      >
        Your reservation has been {message}!
      </Typography>

      <Box
        sx={{
          width: "50%",
        }}
      >
        {isCancel ? (
          <Link
            to={"/"}
            replace={true}
            onClick={() => {
              closeModal();
              resetGlobalState();
            }}
          >
            <Button fullWidth size="large" variant="outlined">
              Home Page
            </Button>
          </Link>
        ) : (
          <Link
            to={`/appointments/${booking.bookingID}/detail`}
            replace={true}
            onClick={() => {
              closeModal();
              resetGlobalState();
            }}
          >
            <Button fullWidth size="large" variant="outlined">
              Detail
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default ModalSuccessContent;
