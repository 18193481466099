import React from "react";
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment, parseZone } from "moment";

type DatePickerMUIProps = DatePickerProps<Moment>;

const DatePickerMUI = React.forwardRef<HTMLInputElement, DatePickerMUIProps>(
  (props: DatePickerMUIProps, ref) => {
    // Convert the value to Moment if it's not already
    const value = props.value ? parseZone(props.value) : null;
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker {...props} value={value} inputRef={ref} />
      </LocalizationProvider>
    );
  }
);

export default DatePickerMUI;
