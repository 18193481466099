import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useModal } from "../../../context/DialogContext";

type Props = {
  onOk?: () => Promise<void>;
};

const ModalConfirm = ({ onOk }: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModal();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Typography>
        Once you cancel the appointment, you cannot changed the status. You
        always can make another one.
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Button
          fullWidth
          disabled={loading}
          variant="outlined"
          onClick={() => closeModal()}
        >
          No
        </Button>
        <Button
          fullWidth
          disabled={loading}
          variant="contained"
          startIcon={
            loading ? (
              <InputAdornment position="start">
                <CircularProgress size={24} />
              </InputAdornment>
            ) : undefined
          }
          onClick={async () => {
            if (typeof onOk === "function") {
              setLoading(true);
              onOk().finally(() => {
                setLoading(false);
              });
            }
          }}
        >
          Yes
        </Button>
      </Box>
    </Box>
  );
};

export default ModalConfirm;
