import { Box } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode;
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_test_TYooMQauvdEDq54NiTphI7jx"
);

const CallbackWrapper = (props: Props) => {
  const [secret, setSecret] = useState<string | null>(null);
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  if (!clientSecret) {
    return <Navigate to={"/"} />;
  }

  return (
    <Box>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: secret || undefined,
          appearance: {
            theme: "stripe",
          },
        }}
      >
        {props.children}
      </Elements>
    </Box>
  );
};

export default CallbackWrapper;
