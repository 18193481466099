import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { cn, uuid } from "../../lib/utils";
import { Steps, useStepper } from "../../context/StepperProvider";
// import { steps } from "../../constant/stepper";
import { Typography } from "@mui/material";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: `calc(50% - 1.25rem)`,
    left: "calc(-50% + 14px)",
    right: "calc(50% + 14px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#00A0AA",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#00A0AA",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 7,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#FFF",
    boxShadow: "inset 0px 20px 20px -25px black",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#fff",
  zIndex: 1,
  color: "#fff",
  width: "2rem",
  height: "2rem",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "inset 4px 8px 20px -12px black",
  [theme.breakpoints.between("xs", "sm")]: {
    width: "1.5rem",
    height: "1.5rem",
  },

  ...(ownerState.active && {
    backgroundColor: "#FFF",

    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#FFF",
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <div
        className={cn(
          "w-5 h-5 rounded-full flex items-center justify-start",
          completed || active ? "bg-primary" : "bg-[#BFBFBF]"
        )}
      >
        <div
          className={cn(
            "w-3 h-3 rounded-full m-auto flex items-center justify-center",
            completed || active ? "bg-white" : "bg-[#BFBFBF]"
          )}
        >
          {!completed && !active && (
            <Typography className="m-0 text-center font-semibold">
              {props.icon}
            </Typography>
          )}
        </div>
      </div>
    </ColorlibStepIconRoot>
  );
}

const CustomStepper = ({ customSteps }: { customSteps: Steps[] }) => {
  const { activeStep, steps, setSteps } = useStepper();

  useEffect(() => {
    if (typeof setSteps === "function" && customSteps.length > 0) {
      setSteps(customSteps);
    }
  }, []);

  return (
    <div className="lg:max-w-lg w-full my-4">
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep.index}
          connector={<ColorlibConnector />}
        >
          {Array.isArray(steps) &&
            steps
              .filter((step) => step.isVisible)
              .map(({ label }) => (
                <Step
                  key={uuid()}
                  sx={(theme) => ({
                    [theme.breakpoints.between("xs", "sm")]: {
                      px: 0,
                    },
                  })}
                >
                  <StepLabel
                    sx={(theme) => ({
                      background: "transparent",
                      "& .MuiStepLabel-label.Mui-active": {
                        color: theme.palette.primary.main,
                      },
                    })}
                    StepIconComponent={ColorlibStepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
        </Stepper>
      </Stack>
    </div>
  );
};

export default CustomStepper;
