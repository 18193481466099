import GooglePlacesInput from "@/components/GooglePlacesInput/GooglePlacesInput";
import { IClinicAgedCareForm } from "@/interfaces/IAgedCare";
import {
  getSuburbsFromPlacesResult,
  getPostalCodeFromPlacesResult,
  uuid,
} from "@/lib/utils";
import { Box, TextField } from "@mui/material";
import React from "react";
import {
  Controller,
  FormProvider,
  useForm,
  UseFormReturn,
} from "react-hook-form";

interface IClinicFormProps {
  methods: UseFormReturn<IClinicAgedCareForm>;
  disabled?: boolean;
}

const ClinicForm = ({ methods, disabled }: IClinicFormProps) => {
  const defaultMethods = useForm<IClinicAgedCareForm>({
    defaultValues: {
      clinicName: "",
      address: "",
      email: "",
      phone: "",
      postcode: "",
      suburb: "",
      location: "",
      notes: "",
      clinicID: uuid(),
    },
  });

  const { handleSubmit, control, setValue } = methods || defaultMethods;

  const onSubmit = (data: IClinicAgedCareForm) => {};

  const formMethods = methods || defaultMethods;

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={(_theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        })}
      >
        <Controller
          name="clinicName"
          control={control}
          rules={{
            required: "Clinic name is required",
            validate: (value) =>
              value?.trim().length !== 0 || "Empty value are not allowed",
          }}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                fullWidth
                label="Clinic Name"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            );
          }}
        />
        <Controller
          name="address"
          rules={{
            required: "Address is required",
            validate: (value) =>
              value?.trim().length !== 0 || "Empty value are not allowed",
          }}
          control={control}
          render={({ field, fieldState }) => {
            const { ref, onChange, ...rest } = field;
            return (
              <GooglePlacesInput
                {...rest}
                autoComplete="new-password"
                label="Location"
                size="medium"
                helperText={fieldState.error?.message}
                onChange={(e) => {
                  const { inputType } = e.nativeEvent as InputEvent;

                  if (
                    inputType === "insertText" ||
                    inputType === "deleteContentBackward"
                  ) {
                    onChange(e.target.value);
                  }
                }}
                error={!!fieldState.error}
                onPlaceSelected={(place) => {
                  if (place && place.formatted_address) {
                    const suburbs = getSuburbsFromPlacesResult(place);
                    const postCode = getPostalCodeFromPlacesResult(place);
                    setValue("address", place.formatted_address || "");
                    setValue("location", place.formatted_address || "");
                    setValue("suburb", suburbs || "");
                    setValue("postcode", postCode || "");

                    onChange(place.formatted_address);
                  }
                }}
                disabled={disabled}
              />
            );
          }}
        />
        <Box display="flex" gap="1rem">
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email address is required",
              validate: (value) =>
                value?.trim().length !== 0 || "Empty value are not allowed",
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  label="Email Address"
                  type="email"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={disabled}
                />
              );
            }}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone number is required",
              validate: (value) =>
                value?.trim().length !== 0 || "Empty value are not allowed",
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  label="Phone Number"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={disabled}
                />
              );
            }}
          />
        </Box>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-multiline-static"
              label="Notes"
              multiline
              fullWidth
              rows={4}
              placeholder="Is there anything we should know?"
              disabled={disabled}
            />
          )}
        />
      </Box>
    </FormProvider>
  );
};

export default ClinicForm;
