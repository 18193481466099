import { Box, Button, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import PatientDetailForm, { IAppointmentForm } from "./PatientDetailForm";
import { AccountCircleOutlined } from "@mui/icons-material";
import { EMultipleType } from "./PatienGroup";

interface IPatientDetailProps {
  groupType?: EMultipleType | null;
  onAddToGroup?: () => void;
}

const PatientDetail = ({ groupType, onAddToGroup }: IPatientDetailProps) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<IAppointmentForm>();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box mb="1rem" display="flex" alignItems="center">
        <Box display="flex" gap="1.5rem" flex={1}>
          <AccountCircleOutlined color="primary" />
          <Typography className=" text-grey-200 font-medium">
            Patients Details
          </Typography>
        </Box>
        {groupType && (
          <Box flex={1} justifyContent="flex-end" display="flex">
            <Button
              onClick={() => {
                if (typeof onAddToGroup === "function") onAddToGroup();
              }}
              variant="outlined"
            >
              Add member
            </Button>
          </Box>
        )}
      </Box>
      <PatientDetailForm
        errors={errors}
        setValue={setValue}
        control={control}
        sx={(_theme) => ({
          px: "0 !important",
        })}
      />
    </Box>
  );
};

export default PatientDetail;
