import { ReactNode, useEffect, useState } from "react";

import {
  InputLabel,
  InputAdornment,
  IconButton,
  CircularProgress,
  Typography,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useApp } from "@/context/AppContext";
import { ServiceCategory } from "@/codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";

const SelectServiceCategory = () => {
  const { selectedCategory, setSelectedCategory } = useApp();
  const [category, setCategory] = useState<string>("");
  const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>(
    []
  );
  const [open, setOpen] = useState(false);

  const { getCategories, loading, error } = useReservationEndpoint();

  useEffect(() => {
    getCategories()
      .then((response) => {
        const { data } = response!.data!;

        setServiceCategories(data);
      })
      .catch((err) => console.error(err));
  }, []);

  function handleChangeSelect(e: SelectChangeEvent<string>, _child: ReactNode) {
    const { value } = e.target;
    setCategory(value);
    // console.log(value);

    const selected = serviceCategories.find(
      (category) => category.categoryID === value
    );

    // console.log(selected);
    setSelectedCategory(selected ? selected : null);
  }

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory.categoryID || "");
    }
  }, [selectedCategory]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="services">Category Service</InputLabel>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          fullWidth
          labelId="services"
          name="service"
          value=""
          IconComponent={() => (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(!open)}>
                <CircularProgress size={24} />
              </IconButton>
            </InputAdornment>
          )}
          label="Category Service"
        >
          <MenuItem
            sx={{
              py: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={24} />
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (error) {
    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="services">Category Service</InputLabel>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          fullWidth
          labelId="services"
          name="service"
          value=""
          IconComponent={() => (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(!open)}>
                <ArrowDropDownOutlined />
              </IconButton>
            </InputAdornment>
          )}
          label="Category Service"
        >
          <MenuItem
            sx={{
              py: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            No service available
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (serviceCategories.length === 0 && !loading) {
    return (
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="services">Category Service</InputLabel>
        <Select
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          fullWidth
          labelId="services"
          name="service"
          value=""
          IconComponent={() => (
            <InputAdornment position="end">
              <IconButton onClick={() => setOpen(!open)}>
                <ArrowDropDownOutlined />
              </IconButton>
            </InputAdornment>
          )}
          label="Category Service"
        >
          <MenuItem
            sx={{
              py: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            No service available
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel id="services">Category Service</InputLabel>
      <Select
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        fullWidth
        labelId="services"
        id="services"
        name="service"
        value={category}
        IconComponent={() => (
          <InputAdornment position="end">
            <IconButton color="primary" onClick={() => setOpen(!open)}>
              <ArrowDropDownOutlined />
            </IconButton>
          </InputAdornment>
        )}
        label="Category Service"
        onChange={handleChangeSelect}
      >
        {serviceCategories.map((category) => (
          <MenuItem
            key={category.categoryID as string}
            value={category.categoryID as string}
            sx={{ py: "1rem" }}
          >
            <Typography>{category.categoryName}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectServiceCategory;
