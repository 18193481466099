import { useEffect, useState } from "react";
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  useMutation,
} from "@apollo/client";
import {
  Exact,
  UpdateBookingMutation,
  UpdateBookingMutationVariables,
  UpdateBookingsInput,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { MUTATION_UPDATE_BOOKING } from "../codegens/HEALTCARE-RESERVATION/mutations";

export const useUpdateBooking = () => {
  const [loadingUpdateBooking, setLoadingUpdateBooking] = useState(false);
  const [mutationUpdateBooking, { loading, error, data }] = useMutation<
    UpdateBookingMutation,
    UpdateBookingMutationVariables
  >(MUTATION_UPDATE_BOOKING);

  useEffect(() => {
    setLoadingUpdateBooking(loading);
  }, [loading]);

  const updateBooking = (
    options: MutationFunctionOptions<
      UpdateBookingMutation,
      Exact<{
        input: UpdateBookingsInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return mutationUpdateBooking({
      ...options,
    });
  };

  return {
    loadingUpdateBooking,
    errorUpdateBooking: error,
    updateBooking,
    responseUpdateBooking: data,
  };
};
