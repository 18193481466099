import { ArrowDropDownOutlined } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Skeleton,
} from "@mui/material";
import { useCalendar } from "../../../context/CalendarContext";
import { ReactElement } from "react";

export interface IDayNavigationSlots {
  renderDayNavigationView?: (
    onNext: () => void,
    onPrev: () => void
  ) => ReactElement;
  prevIcon?: ReactElement;
  nextIcon?: ReactElement;
  prevIconProps?: IconButtonProps;
  nextIconProps?: IconButtonProps;
  containerDayNavigationSlotProps?: BoxProps;
}

export interface IDayNavigationProps extends IDayNavigationSlots {}

const DayNavigation = (props: IDayNavigationProps) => {
  const {
    renderDayNavigationView,
    prevIcon,
    nextIcon,
    prevIconProps,
    nextIconProps,
    containerDayNavigationSlotProps,
  } = props;

  const { currentMonth, onWeekChange, maxDate, minDate, loading } =
    useCalendar();
  const onNext = () => {
    if (currentMonth.isSameOrAfter(maxDate, "date")) {
      return;
    }

    const nextWeek = currentMonth.clone().add(1, "week");
    onWeekChange(nextWeek, "right");
  };

  const onPrev = () => {
    if (currentMonth.isSameOrBefore(minDate, "date")) {
      return;
    }
    const prevWeek = currentMonth.clone().subtract(1, "week");
    onWeekChange(prevWeek, "left");
  };

  if (
    renderDayNavigationView &&
    typeof renderDayNavigationView === "function"
  ) {
    return renderDayNavigationView(onNext, onPrev);
  }

  if (loading) {
    return (
      <Box display="flex" alignItems="center" gap="1rem">
        <Skeleton
          variant="circular"
          width={40} // Width and height to match icon button size
          height={40}
        />
        <Skeleton
          variant="circular"
          width={40} // Width and height to match icon button size
          height={40}
        />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="1rem"
      {...containerDayNavigationSlotProps}
    >
      <IconButton
        {...prevIconProps}
        onClick={onPrev}
        disabled={currentMonth.isSameOrBefore(minDate, "date")}
      >
        {prevIcon || (
          <ArrowDropDownOutlined
            sx={{
              rotate: "90deg",
            }}
          />
        )}
      </IconButton>
      <IconButton
        {...nextIconProps}
        onClick={onNext}
        disabled={currentMonth.isSameOrAfter(maxDate, "date")}
      >
        {nextIcon || (
          <ArrowDropDownOutlined
            sx={{
              rotate: "-90deg",
            }}
          />
        )}
      </IconButton>
    </Box>
  );
};

export default DayNavigation;
