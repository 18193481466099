import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookExecOptions,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { useApp } from "../context/AppContext";
import {
  GET_SERVICE,
  LIST_SERVICE,
  QUERY_SERVICE_BY_CATEGORY_ID,
  QUERY_SERVICE_BY_PHARMACY_ID,
} from "../codegens/HEALTCARE-RESERVATION/queries";
import {
  CreateServiceInput,
  CreateServiceMutationVariables,
  Exact,
  GetServiceQuery,
  GetServiceQueryVariables,
  InputMaybe,
  ListServiceQuery,
  ListServiceQueryVariables,
  ServiceByCategoryIdQuery,
  ServiceByCategoryIdQueryVariables,
  ServiceByPharmacyIdQuery,
  ServiceByPharmacyIdQueryVariables,
  TableServiceFilterInput,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { MUTATION_CREATE_SERVICE } from "../codegens/HEALTCARE-RESERVATION/mutations";

export const useServices = () => {
  const [fetchServices, { loading, error }] = useLazyQuery<
    ListServiceQuery,
    ListServiceQueryVariables
  >(LIST_SERVICE);

  const [
    fetchServicesbyPharmacyId,
    { loading: loadingServiceByPharmacyId, error: errorServiceByPharmacyId },
  ] = useLazyQuery<ServiceByPharmacyIdQuery, ServiceByPharmacyIdQueryVariables>(
    QUERY_SERVICE_BY_PHARMACY_ID
  );

  const [
    fetchServicesByCategoryId,
    { loading: loadingServiceByCategoryId, error: errorServiceByCategoryId },
  ] = useLazyQuery<ServiceByCategoryIdQuery, ServiceByCategoryIdQueryVariables>(
    QUERY_SERVICE_BY_CATEGORY_ID
  );

  const [
    getServiceQuery,
    { loading: loadingGetService, error: errorGetService },
  ] = useLazyQuery<GetServiceQuery, GetServiceQueryVariables>(GET_SERVICE);

  const [createServiceMutation, { loading: loadingSave, error: errorSave }] =
    useMutation<CreateServiceInput, CreateServiceMutationVariables>(
      MUTATION_CREATE_SERVICE
    );

  const getServices = (
    options: Partial<
      LazyQueryHookExecOptions<
        ListServiceQuery,
        Exact<{
          filter?: InputMaybe<TableServiceFilterInput> | undefined;
          limit?: InputMaybe<number> | undefined;
          nextToken?: InputMaybe<string> | undefined;
        }>
      >
    >
  ) => {
    return fetchServices({
      fetchPolicy: "network-only",
      onError(error) {
        console.error("error fething services data ", error);
      },
      ...options,
    });
  };

  const getServicesByPharmacyId = (
    options: Partial<
      LazyQueryHookExecOptions<
        ServiceByPharmacyIdQuery,
        Exact<{
          pharmacyID: string;
          first?: InputMaybe<number> | undefined;
          after?: InputMaybe<string> | undefined;
        }>
      >
    >
  ) => {
    return fetchServicesbyPharmacyId({
      fetchPolicy: "network-only",
      ...options,
    });
  };

  const saveService = (
    options: MutationFunctionOptions<
      CreateServiceInput,
      Exact<{
        input: CreateServiceInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return createServiceMutation({
      ...options,
    });
  };

  const getServicesByCategoryId = (
    options: Partial<
      LazyQueryHookExecOptions<
        ServiceByCategoryIdQuery,
        Exact<{
          categoryID: string;
          after?: InputMaybe<string> | undefined;
          first?: InputMaybe<number> | undefined;
        }>
      >
    >
  ) => {
    return fetchServicesByCategoryId({
      ...options,
    });
  };

  const getService = (
    options?: Partial<
      LazyQueryHookExecOptions<
        GetServiceQuery,
        Exact<{
          serviceId: string;
        }>
      >
    >
  ) => {
    return getServiceQuery({
      ...options,
    });
  };

  return {
    getServices,
    error,
    loadingService: loading,
    loadingSave,
    errorSave,
    saveService,
    getServicesByPharmacyId,
    loadingServiceByPharmacyId,
    errorServiceByPharmacyId,
    getServicesByCategoryId,
    loadingServiceByCategoryId,
    errorServiceByCategoryId,
    getService,
    loadingGetService,
    errorGetService,
  };
};
