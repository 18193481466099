import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Pharmacy, { IServicePharmacy } from "./Pharmacy";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { IServiceCategory } from "@/interfaces/IService";

type PharmaciesProps = {
  categoryID: IServiceCategory["categoryID"] | null;
  searchBy?: string;
};

const Pharmacies = ({ categoryID, searchBy }: PharmaciesProps) => {
  const [services, setServices] = useState<IServicePharmacy[]>([]);
  const [initServices, setInitServices] = useState<IServicePharmacy[]>([]);
  const { getServicesPharmacy, loading, error } = useReservationEndpoint();

  useEffect(() => {
    if (categoryID) {
      getServicesPharmacy(categoryID)
        .then((response) => {
          console.log(response);
          const { data } = response!.data!;
          const result = data as IServicePharmacy[];

          const activeService = result.filter((service) => service.isActive);

          const sortedService = activeService.sort((a, b) => {
            return a.pharmacy.pharmacyName.localeCompare(
              b.pharmacy.pharmacyName
            );
          });

          setServices(sortedService);
          setInitServices(result.filter((service) => service.isActive));
        })
        .catch((reason) => console.error("Error getting service ", reason));
    }
  }, [categoryID]);

  useEffect(() => {
    if (searchBy && searchBy.trim().length > 0) {
      const filteredService = services.filter((service) =>
        service.pharmacy.pharmacyName
          ?.toUpperCase()
          .includes(searchBy.toUpperCase())
      );
      setServices(filteredService);
    } else {
      setServices(initServices);
    }
  }, [searchBy]);

  if (loading) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>An error occured</Typography>
      </Box>
    );
  }

  return (
    <Box
      component="ul"
      sx={{
        p: 0,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        li: {
          listStyle: "none",
          width: "100%",
        },
      }}
    >
      {services?.map((service) => (
        <Pharmacy key={service.serviceId} service={service} />
      ))}
    </Box>
  );
};

export default Pharmacies;
