import { BackNavigation } from "@/components/new/BackNavigation";
import PageWrapper from "@/pages/PageWrapper";
import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClinicForm from "./ClinicForm";
import {
  AccountCircleOutlined,
  Diversity3Outlined,
  LocalPharmacyOutlined,
} from "@mui/icons-material";
import PatientsAgedCare from "./PatientsAgedCare";
import { useForm } from "react-hook-form";
import useApi from "@/hooks/useApi";
import {
  IClinicAgedCareForm,
  ICreateAgedCarePayload,
  IPatientClinicFields,
} from "@/interfaces/IAgedCare";
import { uuid } from "@/lib/utils";
import { useModal } from "@/context/DialogContext";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import ModalFailedContent from "@/components/ModalFailedContent/ModalFailedContent";
import ModalSuccessAgedCare from "./ModalSuccessAgedCare";
import { dateLib } from "@/lib/_calendar";
import moment from "moment";
import {
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import AgedCarePatientsTable from "./AgedCarePatientsTable";
import { useSnackbar } from "@/context/SnackbarContext";

type Props = {};

const AgedCarePage = (props: Props) => {
  const navigate = useNavigate();
  const { pharmacyID } = useParams<{ pharmacyID: string }>();
  const [loading, setLoading] = useState(false);
  const { openModal, closeModal } = useModal();
  const api = useApi();
  const clinicMethods = useForm<IClinicAgedCareForm>({
    defaultValues: {
      clinicName: "",
      address: "",
      email: "",
      phone: "",
      postcode: "",
      suburb: "",
      notes: "",
      clinicID: uuid(),
    },
  });

  const [patients, setPatients] = useState<IPatientClinicFields[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  // const [hasPatientErrors, setHasPatientErrors] = useState<boolean>(true);
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSaveClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => {
    setPatients(patients.filter((row) => row.clinicPatientID !== id));
  };

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  useEffect(() => {
    if (loading) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
    }
  }, [loading]);

  function validatePatientData(data: IPatientClinicFields[]): {
    isValid: boolean;
    errors: any[];
  } {
    const errors: any[] = [];

    data.forEach((patient, index) => {
      const patientErrors: { [key: string]: string } = {};

      if (!patient.firstName || patient.firstName.trim() === "") {
        patientErrors.firstName = "First name is required.";
      }

      if (!patient.dateOfBirth) {
        patientErrors.dateOfBirth = "Date of birth is required.";
      }

      if (!patient.lastName || patient.lastName.trim() === "") {
        patientErrors.lastName = "Last name is required.";
      }

      if (!patient.medicareIRN || patient.medicareIRN.trim() === "") {
        patientErrors.medicareIRN = "Medicare IRN is required.";
      }

      if (!patient.medicareNumber || patient.medicareNumber.trim() === "") {
        patientErrors.medicareNumber = "Medicare number is required.";
      }

      if (Object.keys(patientErrors).length > 0) {
        errors.push({
          index,
          clinicPatientID: patient.clinicPatientID,
          errors: patientErrors,
        });
      }
    });

    return {
      isValid: errors.length === 0,
      errors,
    };
  }

  const handleSubmit = async () => {
    const isClinicValid = await clinicMethods.trigger();

    if (!isClinicValid) {
      console.info("Clinic is not passed validation ");
      return;
    }

    if (typeof pharmacyID === "string" && pharmacyID.trim().length === 0) {
      console.info("Empty pharmacy ID detected");
      return;
    }

    if (!pharmacyID) {
      console.info("pharmacy ID not initialized yet");
      return;
    }

    const patientValidationResult = validatePatientData(patients);

    if (!patientValidationResult.isValid) {
      console.info(
        "did not pass patientValidationResult ",
        patientValidationResult.errors
      );

      openSnackbar({
        severity: "error",
        message: "Patients field are required",
      });
      return;
    }

    setLoading(true);

    try {
      const clinicData = clinicMethods.getValues();

      // Format the patients data according to the required structure
      const formattedPatients = patients.map((patient) => ({
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateOfBirth: moment(patient.dateOfBirth).format("YYYY-MM-DD"),
        medicareNumber: patient.medicareNumber,
        medicareIRN: patient.medicareIRN,
        postcode: clinicData.postcode, // Get postcode from clinic data
        status: "SCHEDULED",
        service: "FLU_VACCINATION",
        clinicID: clinicData.clinicID,
        pharmacyID: pharmacyID,
      }));

      // Post to the aged care API
      const request = await api.post(
        `${process.env.REACT_APP_AGED_CARE_API}/bulkUploadPatients`,
        formattedPatients
      );

      if (request.status !== 200) {
        throw new Error(`Failed to submit: ${request.statusText}`);
      }

      openModal({
        title: null,
        backdropClose: false,
        content: (
          <ModalSuccessAgedCare
            pharmacyID={pharmacyID}
            clinicID={clinicData.clinicID}
          />
        ),
      });
    } catch (error) {
      console.error(
        "An error occurred processing aged care submissions",
        error
      );
      openSnackbar({
        severity: "error",
        message: "Failed to submit aged care data",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = (id: GridRowId) => {
    console.log("Running cancel method ", id);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = patients.find((row) => row.clinicPatientID === id);
    if (editedRow!.isNew) {
      setPatients(patients.filter((row) => row.clinicPatientID !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel<IPatientClinicFields>) => {
    console.log("Process row update for ", newRow);

    // Validate row fields
    if (!newRow.firstName || newRow.firstName.length < 3) {
      // Throw an error if validation fails
      console.error(
        "Validation failed: First Name must be at least 3 characters."
      );
      throw new Error("First Name must be at least 3 characters.");
    }

    // setHasPatientErrors(false);

    const updatedRow = { ...newRow, isNew: false };
    setPatients(
      patients.map((row) =>
        row.clinicPatientID === newRow.clinicPatientID ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    console.log("handleRowEditStop event", event);
    console.log("handleRowEditStop params", params);

    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const { openSnackbar } = useSnackbar();

  return (
    <PageWrapper title="Aged Care">
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "150%",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          [theme.breakpoints.between("xs", "sm")]: {
            width: "100%",
            px: "1rem",
            left: "unset",
            transform: "none",
          },
          [theme.breakpoints.between("sm", "md")]: {
            width: "100%",
            left: "unset",
            transform: "none",
          },
          [theme.breakpoints.between("md", "lg")]: {
            width: "90%",
            left: "unset",
            transform: "none",
          },
        })}
      >
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <BackNavigation
            title="Aged Care Detail"
            description="Please make sure your details belong to the individual visiting clinic"
            onClick={() => navigate(-1)}
          />
        </Card>
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Box mb="1rem" display="flex" alignItems="center">
            <Box display="flex" gap="1.5rem" flex={1}>
              <LocalPharmacyOutlined color="primary" />
              <Typography className=" text-grey-200 font-medium">
                Clinic Details
              </Typography>
            </Box>
          </Box>
          <ClinicForm methods={clinicMethods} />
        </Card>
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Box mb="1rem" display="flex" alignItems="center">
            <Box display="flex" gap="1.5rem" flex={1}>
              <AccountCircleOutlined color="primary" />
              <Typography className=" text-grey-200 font-medium">
                Patient Details
              </Typography>
            </Box>

            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Button
                onClick={() => {
                  const id = uuid();
                  setPatients([
                    ...patients,
                    {
                      firstName: "",
                      dateOfBirth: null,
                      lastName: "",
                      medicareIRN: "",
                      medicareNumber: "",
                      clinicPatientID: id,
                      isNew: true,
                    },
                  ]);

                  setRowModesModel((oldModel) => ({
                    ...oldModel,
                    [id]: {
                      mode: GridRowModes.Edit,
                      fieldToFocus: "firstName",
                    },
                  }));
                }}
                variant="contained"
                startIcon={<Diversity3Outlined />}
              >
                Add Patient
              </Button>
            </Box>
          </Box>

          <AgedCarePatientsTable
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            patients={patients}
            setPatients={setPatients}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              const err = error as Error;
              // setHasPatientErrors(true);

              openSnackbar({
                severity: "error",
                message: err.message,
              });
            }}
          />
        </Card>
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Button
            // disabled={hasPatientErrors}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Card>
      </Box>
    </PageWrapper>
  );
};

export default AgedCarePage;
