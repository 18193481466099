import {
  Box,
  BoxProps,
  Chip,
  Container,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { IPharmacy } from "../../../interfaces/IPharamacy";
import { TimeIcon } from "@mui/x-date-pickers";
import { cn } from "../../../lib/utils";
import { Moment } from "moment";
import { useApp } from "../../../context/AppContext";
import { LocationIcon } from "../../../components/Icons/Icons";
import { useEffect, useState } from "react";
import { Hours } from "../../../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { dateLib } from "../../../lib/_calendar";
import { ApolloError } from "@apollo/client";
import { IService } from "@/interfaces/IService";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { IServiceSchedule } from "@/interfaces/IServiceSchedule";
import { AccessTimeOutlined, PlaceOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

export interface IServicePharmacy extends IService {
  pharmacy: IPharmacy;
}

interface PharmacyProps extends BoxProps {
  service: IServicePharmacy;
}

const ScheduleText = ({
  loading,
  error,
  openTime,
  closeTime,
}: {
  loading: boolean;
  error?: ApolloError;
  openTime: Moment;
  closeTime: Moment;
}) => {
  if (loading) {
    return <Skeleton variant="text" width="50%" />;
  }

  if (error) {
    return <Typography>N/A</Typography>;
  }

  return (
    <Typography>
      {openTime.format("hh:mm A") || "N/A"} -{" "}
      {closeTime.format("hh:mm A") || "N/A"}
    </Typography>
  );
};

const Pharmacy = ({ service, ...rest }: PharmacyProps) => {
  const { selectedService, setSelectedPharmacy, setSelectedService } = useApp();
  const { getServiceSchedule, loading, error } = useReservationEndpoint();
  const [schedule, setSchedule] = useState<IServiceSchedule | null>(null);

  useEffect(() => {
    if (service) {
      getServiceSchedule(service.serviceScheduleID).then((response) => {
        setSchedule(response?.data as IServiceSchedule);
      });
    }
  }, [service]);

  if (error) {
    return (
      <Box component={"li"} {...rest}>
        <Paper
          className={cn(
            "p-4 shadow-md bg-white border-white border-2 border-solid shadow-grey-400/20 flex flex-col gap-2 rounded-lg my-2 cursor-pointer hover:border-primary hover:border-solid hover:border-2 hover:shadow-md hover:shadow-primary-400/20 hover:transition-all hover:ease-out"
          )}
        >
          <Typography>Failed fetching data...</Typography>
        </Paper>
      </Box>
    );
  }

  const getHoursInNumber = (hours: string | null | undefined) => {
    if (!hours) {
      return 0;
    }

    const hour = hours.split(":")[0];

    return Number(hour);
  };

  const getMinutesHoursInNumber = (hours: string | null | undefined) => {
    if (!hours) {
      return 0;
    }

    const minute = hours.split(":")[1];

    return Number(minute);
  };

  const isActive = selectedService?.serviceId === service.serviceId;
  const today = dateLib().clone().tz("Australia/Sydney");
  const todaySchedule = schedule?.[
    today.format("dddd").toLowerCase() as keyof IServiceSchedule
  ] as Hours;

  const openTime = today
    .clone()
    .set("hour", getHoursInNumber(todaySchedule?.openingHour))
    .set("minute", getMinutesHoursInNumber(todaySchedule?.openingHour))
    .set("millisecond", 0);

  const closeTime = today
    .clone()
    .set("hour", getHoursInNumber(todaySchedule?.closingHour))
    .set("minute", getMinutesHoursInNumber(todaySchedule?.closingHour))
    .set("millisecond", 0);

  const isOpen =
    todaySchedule &&
    !todaySchedule.isClosed &&
    today.clone().isBetween(openTime, closeTime, "hour");

  return (
    <Box component={"li"} {...rest}>
      <Paper
        className={cn(
          "p-4 shadow-md bg-white border-white border-2 border-solid shadow-grey-400/20 flex flex-col gap-2 rounded-lg my-2 cursor-pointer hover:border-primary hover:border-solid hover:border-2 hover:shadow-md hover:shadow-primary-400/20 hover:transition-all hover:ease-out",
          isActive &&
            "border-primary border-solid border-2 shadow-md shadow-primary-400/20"
        )}
        onClick={() => {
          setSelectedPharmacy(service.pharmacy);
          setSelectedService(service || null);
        }}
      >
        <Container className="lg:px-0 xl:px-0 md:px-0 px-0 flex justify-between items-center w-full">
          <Typography>{service.pharmacy.pharmacyName}</Typography>
          <span>
            <Chip label={service.serviceName} />
          </span>
        </Container>
        <Container className="lg:px-0 xl:px-0 md:px-0 px-0">
          {loading ? (
            <Typography variant="h5">
              <Skeleton
                variant="rounded"
                sx={{
                  maxWidth: "5rem",
                }}
              />
            </Typography>
          ) : (
            <Typography
              className={cn(
                "px-4 py-1 rounded-full text-white text-sm sm:w-fit md:w-fit xl:w-fit lg:w-fit",
                isOpen ? "bg-success" : "bg-danger"
              )}
            >
              {isOpen ? "Available" : "Unavailable"}
            </Typography>
          )}
        </Container>
        <Container className="lg:px-0 xl:px-0 md:px-0 px-0 flex gap-4 items-center">
          <PlaceOutlined
            sx={{
              fontSize: "1.25rem",
            }}
            color="primary"
          />
          <Typography
            sx={{
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Link
              target="_blank"
              to={`https://maps.google.com/maps?q=${service.pharmacy.pharmacyAddress}`}
            >
              {service.pharmacy.pharmacyAddress}
            </Link>
          </Typography>
        </Container>
        <Container className="lg:px-0 xl:px-0 md:px-0 px-0 flex gap-4 items-center">
          <AccessTimeOutlined
            sx={{
              fontSize: "1.25rem",
            }}
            color="primary"
          />
          <ScheduleText
            loading={loading}
            error={error || undefined}
            openTime={openTime}
            closeTime={closeTime}
          />
        </Container>
      </Paper>
    </Box>
  );
};

export default Pharmacy;
