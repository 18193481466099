import { Box, Button, Typography, alpha } from "@mui/material";
import FailedGif from "../../../assets/gif/failed-animation.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import { useModal } from "../../../context/DialogContext";
import { ReportOutlined } from "@mui/icons-material";

type Props = {
  error: Error | ApolloError;
};

const ModalReservationFailed = ({ error }: Props) => {
  const { pathname } = useLocation();
  const { closeModal } = useModal();
  const navigate = useNavigate();
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={250} height={250} src={FailedGif} alt="" />
      <Typography variant="h5">Sorry,</Typography>
      <Typography
        sx={(theme) => ({
          color: alpha(theme.palette.common.black, 0.5),
          textAlign: "center",
        })}
        variant="h6"
      >
        We are having a problem creating reservation. Please, feel free to
        report this problem
      </Typography>

      <Box
        sx={{
          width: "50%",
        }}
      >
        <Button
          onClick={() => {
            const bodyString = `Error: ${JSON.stringify(
              error
            )} path: ${pathname}`;
            const encodedBodyString = encodeURIComponent(bodyString).replace(
              /%0A/g,
              "%0D%0A"
            );
            window.open(
              `mailto:admin@dragatron.com.au?subject=Healthcare Reservation Issue&body=${encodedBodyString}`
            );
            closeModal();
            navigate("/");
          }}
          fullWidth
          startIcon={<ReportOutlined />}
          size="large"
          color="error"
          variant="outlined"
        >
          Report
        </Button>
      </Box>
    </Box>
  );
};

export default ModalReservationFailed;
