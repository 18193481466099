import { useState } from "react";
import useApi from "./useApi";
import { AxiosError } from "axios";
import { IServiceSchedule } from "@/interfaces/IServiceSchedule";
import { Moment } from "moment";
import { IAvailability } from "@/interfaces/IAvailability";
import { IService } from "@/interfaces/IService";
import { IPharmacy } from "@/interfaces/IPharamacy";
import { IRoom, IRoomScheduleResult } from "@/interfaces/IRoom";
import { IBooking } from "@/interfaces/IBooking";
import { IPatientAppointment } from "@/interfaces/IPatientAppointment";

interface IAPIResponse<T> {
  message: string;
  data: T;
}

interface IAvailabilitiesByDayFilter {
  pharmacyID: string;
  serviceId?: string;
  date?: Moment;
}

export const useReservationEndpoint = () => {
  const api = useApi(process.env.REACT_APP_API_ENDPOINT);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  const getCategories = async () => {
    setLoading(true);

    return await api
      .get("/serviceCategories")
      .then((response) => {
        setError(null);
        return response;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getServicesPharmacy = async (categoryID: string) => {
    setLoading(true);

    return await api
      .post("/servicesPharmacy", { categoryID })
      .then((response) => {
        setError(null);
        return response;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getService = async (serviceId: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IService>>(`/services/${serviceId}`)
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getServiceByPharmacyID = async (pharmacyID: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IService>>(`/services?pharmacyID=${pharmacyID}`)
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getPharmacy = async (pharmacyID: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IPharmacy>>(`/pharmacies/${pharmacyID}`)
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getServiceSchedule = async (scheduleID: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IServiceSchedule>>(`/serviceSchedules/${scheduleID}`)
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getAvailabilitiesByDay = async (filter: IAvailabilitiesByDayFilter) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IAvailability[]>>(
        `/availabilities?pharmacyID=${
          filter.pharmacyID
        }&date=${filter.date?.format("YYYY-MM-DD")}${
          filter.serviceId ? `&serviceId=${filter.serviceId}` : ""
        }`
      )
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getRoomSchedule = async (pharmacyID: string, selectedDate: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IRoomScheduleResult>>(
        `/availability-room?pharmacyID=${pharmacyID}&date=${selectedDate}`
      )
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getAvailabilitiyById = async (availablityID: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IAvailability[]>>(`/availabilities/${availablityID}`)
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getBooking = async (bookingID: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IBooking>>(`/bookings/${bookingID}`)
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const getPatientByPatientID = async (patientID: string) => {
    setLoading(true);

    return await api
      .get<IAPIResponse<IPatientAppointment>>(
        `/patients?patientID=${patientID}`
      )
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  const updateBooking = async (
    booking: Pick<IBooking, "bookingID"> & Partial<Omit<IBooking, "bookingID">>
  ) => {
    setLoading(true);
    const { bookingID, ...restBooking } = booking;

    return await api
      .put<IAPIResponse<IBooking>>(`/bookings/${bookingID}`, { ...restBooking })
      .then((response) => {
        setError(null);
        return response.data;
      })
      .catch((error) => {
        const err = error as AxiosError;
        setError(err);
        return err.response;
      })
      .finally(() => setLoading(false));
  };

  return {
    getCategories,
    getServicesPharmacy,
    getServiceSchedule,
    getAvailabilitiesByDay,
    getServiceByPharmacyID,
    getService,
    getPharmacy,
    getAvailabilitiyById,
    getRoomSchedule,
    getBooking,
    getPatientByPatientID,
    updateBooking,
    loading,
    error,
  };
};
