export interface IBooking {
  bookingID: string;
  availabilityID: string;
  BookingMonth: string;
  createdDate: string;
  durationInSeconds: number;
  patient: Patient;
  patientID: string;
  pharmacyID: string;
  serviceId: string;
  status: string;
  timeslot: string;
  payment: IPaymentBooking;
  notes: string;
  IsAssessmentCompleted: boolean;
}

export interface Patient {
  addressLineOne: string;
  dateOfBirth: string;
  emailAddress: string;
  firstName: string;
  gender: string;
  lastName: string;
  patientID: string;
  pharmacyID: string;
  phoneNumber: string;
  postCode: string;
  locality: string;
  state: string
}

interface IPaymentBooking {
  channel: string;
  status: string;
  token: string;
  clientSecret: string;
}

export enum BOOKING_STATUS {
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  CREATED = "CREATED",
  CANCELED_BY_PHARMACY = "CANCELED_BY_PHARMACY",
  CANCELED_BY_PATIENT = "CANCELED_BY_PATIENT",
}
