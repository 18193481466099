import {
  HTMLAttributes,
  ReactNode,
  Suspense,
  forwardRef,
  useEffect,
} from "react";
import { cn } from "../lib/utils";

type Props = {
  children: ReactNode;
  title?: string;
} & HTMLAttributes<HTMLElement>;

const PageWrapper = forwardRef<HTMLElement, Props>((props: Props, ref) => {
  const { children, title, className } = props;
  const prefix = "Dragatron MA | ";

  useEffect(() => {
    document.title = title ? `${prefix} ${title}` : `${prefix} Default Title`;
  }, [title]);
  return (
    <Suspense fallback={<>Loading....</>}>
      <section className={cn(className)} ref={ref}>
        {children}
      </section>
    </Suspense>
  );
});

export default PageWrapper;
