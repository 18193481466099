import { StepperProvider, Steps } from "../../../context/StepperProvider";
import { Box, alpha } from "@mui/material";
import Dragatron from "../../../assets/img/dragatron.png";
import CustomStepper from "../../CustomStepper/CustomStepper";
import { CalendarProvider } from "../../../context/CalendarContext";
import { Outlet } from "react-router-dom";
type CreateReservationLayoutProps = {
  steps: Steps[];
};

const Topbar = ({ steps }: CreateReservationLayoutProps) => {
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          flexDirection: "column",
          px: 0,
          pt: "1rem",
        },
        [theme.breakpoints.between("sm", "md")]: {
          flexDirection: "column",
          px: 0,
          pt: "1rem",
        },
        [theme.breakpoints.between("md", "lg")]: {
          flexDirection: "column",
          px: 0,
          pt: "1rem",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          position: "absolute",
          height: "auto",
          [theme.breakpoints.between("xs", "sm")]: {
            position: "relative",
          },
          [theme.breakpoints.between("sm", "md")]: {
            position: "relative",
          },
          [theme.breakpoints.between("md", "lg")]: {
            position: "relative",
          },
        })}
      >
        <a href={window.location.origin} target="_blank" rel="noreferrer">
          <img
            src={Dragatron}
            alt="Dragatron"
            className=" object-cover aspect-auto"
          />
        </a>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          [theme.breakpoints.between("md", "lg")]: {
            width: "60%",
          },
        })}
      >
        <CustomStepper customSteps={steps} />
      </Box>
    </Box>
  );
};

const CreateReservationLayout = ({ steps }: CreateReservationLayoutProps) => {
  return (
    <Box
      sx={(theme) => ({
        background: alpha(theme.palette.primary.main, 0.2),
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      })}
    >
      <Box
        sx={(theme) => ({
          position: "sticky",
          top: 0,
          zIndex: theme.zIndex.appBar,
        })}
      >
        <Topbar steps={steps} />
      </Box>
      <Box
        position="relative"
        display="flex"
        justifyContent={"center"}
        alignItems={"start"}
      >
        <Box
          sx={(theme) => ({
            width: "45%",
            p: "1rem",
            [theme.breakpoints.between("xs", "sm")]: {
              width: "100%",
            },
            [theme.breakpoints.between("sm", "md")]: {
              width: "100%",
            },
            [theme.breakpoints.between("md", "lg")]: {
              width: "70%",
            },
          })}
        >
          <CalendarProvider>
            <StepperProvider>
              <Outlet />
            </StepperProvider>
          </CalendarProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateReservationLayout;
