import { IDay, useCalendar } from "../../../context/CalendarContext";
import { Box, CircularProgress } from "@mui/material";
import { nanoid } from "nanoid";
import { dateLib } from "../../../lib/_calendar";
import { ReactElement } from "react";

export type TCalendarDayProps = {
  renderViewDay?: (days: IDay[]) => ReactElement;
};

const CalendarDay = (props: TCalendarDayProps) => {
  const { days, disablePast, loading } = useCalendar();

  if (props.renderViewDay && typeof props.renderViewDay === "function") {
    return props.renderViewDay(days);
  }

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <ul>
        {days.map((day) => (
          <li
            key={nanoid()}
            style={{
              cursor:
                (disablePast && day.date.clone().isBefore(dateLib().clone())) ||
                day.disable
                  ? "not-allowed"
                  : "pointer",
              color: day.isToday ? "red" : "inherit",
            }}
          >
            {day.date.format("DD MMM hh:mm A")}
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default CalendarDay;
