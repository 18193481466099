import { Route, Routes as RouterDomRoutes } from "react-router-dom";

import Pricing from "./pages/Pricing/Pricing";

import CreateReservationLayout from "./components/Layouts/CreateReservationLayout/CreateReservationLayout";

// restructure
import NewHome from "./pages/Reservations/Home/Home";

// /reservations path
import SelectPharmacy from "./pages/Reservations/SelectPharmacy/SelectPharmacy";
import NewChooseTime from "./pages/Reservations/ChooseTime/ChooseTime";
import NewReviewAppointment from "./pages/Reservations/ReviewAppointment/ReviewAppointment";
import { chooseTimeAndReviewStep, steeperPath } from "./constant/stepper";
import UpdateReservationLayout from "./components/Layouts/UpdateReservationLayout/UpdateReservationLayout";
import UpdateAppointment from "./pages/Reservations/UpdateAppointment/UpdateAppointment";
import DetailAppointment from "./pages/Reservations/DetailAppointment/DetailAppointment";
import NotFound from "./components/NotFound/NotFound";
import { steps as defaultSteps } from "@/constant/stepper";

// /pharmacies path
// import Pharmacy from "./pages/Pharmacies/Pharmacy";
import PharmacySelectServicePage from "./pages/Pharmacies/PharmacySelectServicePage/PharmacySelectServicePage";
import PharmacySelectTimePage from "./pages/Pharmacies/PharmacySelectTimePage/PharmacySelectTimePage";
import PharmacyReviewAppointmentPage from "./pages/Pharmacies/PharmacyReviewAppointmentPage/PharmacyReviewAppointmentPage";
import PaymentDetail from "./pages/PaymentDetail/PaymentDetail";
import PaymentCallback from "./pages/Payments/PaymentCallback";
import CallbackWrapper from "./pages/Payments/CallbackWrapper";
import PageWrapper from "./pages/PageWrapper";
// import AppointmentHistories from "./pages/AppointmentHistory/AppointmentHistories";
import AppointmentHistoryDetail from "./pages/AppointmentHistory/AppointmentHistoryDetail";
import ScreeningsPage from "./pages/Screenings/ScreeningsPage";
import PrivacyPage from "./pages/Privacy/PrivacyPage";
import SubmitSuccessStatus from "./pages/Screenings/SubmitSuccessStatus";
import PrivacyStatement from "./pages/Privacy/PrivacyStatement";
import TermsConditions from "./pages/Privacy/TermsConditions";
import AgedCarePage from "./pages/Reservations/AgedCare/AgedCarePage";
import ClinicDetail from "./pages/Reservations/AgedCare/ClinicDetail";
import Success from "./pages/Reservations/AgedCare/Success";
const Routes = () => {
  return (
    <RouterDomRoutes>
      <Route path="/" element={<NewHome />} />

      <Route element={<CreateReservationLayout steps={defaultSteps} />}>
        <Route
          path="/appointments/:categoryID/:pharmacyLocation"
          element={
            <PageWrapper title="Select Pharmacy">
              <SelectPharmacy />
            </PageWrapper>
          }
        />
        <Route
          path={`/appointments/:categoryID/:pharmacyLocation/:serviceID/${steeperPath.SELECT_TIME}`}
          element={
            <PageWrapper title="Choose Time">
              <NewChooseTime />
            </PageWrapper>
          }
        />
        <Route
          path={`/appointments/:categoryID/:pharmacyLocation/:serviceID/${steeperPath.REVIEW_APPOINMENT}`}
          element={
            <PageWrapper title="Review Detail">
              <NewReviewAppointment />
            </PageWrapper>
          }
        />
        <Route
          path={`/appointments/:categoryID/:pharmacyLocation/:serviceID/:availabilityID/:bookingID/${steeperPath.PAYMENT_DETAIL}`}
          element={<PaymentDetail />}
        />
      </Route>

      <Route
        path={`/appointments/:bookingID/edit`}
        element={
          <UpdateReservationLayout>
            <UpdateAppointment />
          </UpdateReservationLayout>
        }
      />
      <Route
        path={`/appointments/:bookingID/cancel`}
        element={
          <UpdateReservationLayout>
            <UpdateAppointment />
          </UpdateReservationLayout>
        }
      />
      <Route
        path={`/appointments/:bookingID/screening`}
        element={<ScreeningsPage />}
      />
      <Route
        path={`/appointments/:bookingID/screening/success`}
        element={<SubmitSuccessStatus />}
      />
      <Route
        path={`/appointments/:bookingID/detail`}
        element={<DetailAppointment />}
      />
      <Route
        path={`/pharmacies/:pharmacyID/aged-care-clinics/new`}
        element={
          <UpdateReservationLayout>
            <AgedCarePage />
          </UpdateReservationLayout>
        }
      />
      <Route
        path={`/pharmacies/:pharmacyID/aged-care-clinics/:clinicID`}
        element={
          <UpdateReservationLayout>
            <ClinicDetail />
          </UpdateReservationLayout>
        }
      />
      <Route
        path={`/pharmacies/:pharmacyID/aged-care-clinics/:clinicID/success`}
        element={
          <UpdateReservationLayout>
            <Success />
          </UpdateReservationLayout>
        }
      />

      <Route path="/pricing" element={<Pricing />} />

      {/* Route for specific pharmacy, this build for connecting the appointment url on the admin panel service page */}
      <Route
        element={<CreateReservationLayout steps={chooseTimeAndReviewStep} />}
      >
        <Route
          path={`/pharmacies/:pharmacyID/appointment/:serviceID/${steeperPath.SELECT_SERVICE}`}
          element={<PharmacySelectServicePage />}
        />

        <Route
          path={`/pharmacies/:pharmacyID/appointment/:serviceID/${steeperPath.SELECT_TIME}`}
          element={<PharmacySelectTimePage />}
        />

        <Route
          path={`/pharmacies/:pharmacyID/appointment/:serviceID/:availabilityID/${steeperPath.REVIEW_APPOINMENT}`}
          element={<PharmacyReviewAppointmentPage />}
        />
        <Route
          path={`/pharmacies/:pharmacyID/appointment/:serviceID/:availabilityID/:bookingID/${steeperPath.PAYMENT_DETAIL}`}
          element={<PaymentDetail />}
        />
      </Route>
      <Route
        path="/payments/:bookingID/callback"
        element={
          <CallbackWrapper>
            <PaymentCallback />
          </CallbackWrapper>
        }
      />

      {/* privacy */}
      {/* <Route path={`/privacy`} element={<PrivacyPage />} /> */}

      <Route path={`/privacy-statement`} element={<PrivacyStatement />} />
      <Route path={`/terms-conditions`} element={<TermsConditions />} />

      {/* appointment history */}
      {/* <Route
        path={`/histories/:patientID`}
        element={<AppointmentHistories />}
      /> */}
      <Route
        path={`/appointments/:bookingID`}
        element={<AppointmentHistoryDetail />}
      />

      <Route path="*" element={<NotFound />} />
      {/* <Route path="/choose-time" element={<ChooseTime />} /> */}
    </RouterDomRoutes>
  );
};

export default Routes;
