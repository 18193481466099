import { Moment } from "moment";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { dateLib } from "../lib/_calendar";
import {
  DatePickerProps,
  PickersCalendarHeaderProps,
} from "@mui/x-date-pickers";
import { SlideDirection } from "@mui/x-date-pickers/DateCalendar/PickersSlideTransition";
import { useApp } from "./AppContext";
import { IWeekSchedule } from "../interfaces/IPharmacySchedule";
import { Hours } from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";

export type TPickersCalendarHeaderProps = PickersCalendarHeaderProps<Moment>;

interface ICalendarContextProps {
  // repopulate calendar header props mui
  currentMonth: TPickersCalendarHeaderProps["currentMonth"];
  onMonthChange: TPickersCalendarHeaderProps["onMonthChange"];
  minDate: TPickersCalendarHeaderProps["minDate"];
  maxDate: TPickersCalendarHeaderProps["maxDate"];

  // custom
  setMaxDate: Dispatch<SetStateAction<TPickersCalendarHeaderProps["maxDate"]>>;
  onWeekChange: TPickersCalendarHeaderProps["onMonthChange"];
  days: IDay[];
  disablePast?: boolean;
  setDisablePast: Dispatch<
    SetStateAction<TPickersCalendarHeaderProps["disablePast"]>
  >;
  setCurrentMonth: Dispatch<
    SetStateAction<TPickersCalendarHeaderProps["currentMonth"]>
  >;
  setMinDate: Dispatch<SetStateAction<Moment>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  selectedDate: TPickersCalendarHeaderProps["currentMonth"];
  setSelectedDate: Dispatch<
    SetStateAction<TPickersCalendarHeaderProps["currentMonth"]>
  >;
}

interface ICalendarProviderProps {
  children: ReactNode;
}

export interface IDay {
  isToday: boolean;
  date: Moment;
  disable: boolean;
  schedule: Hours;
}

const CalendarContext = createContext<ICalendarContextProps | undefined>(
  undefined
);

export const CalendarProvider = ({ children }: ICalendarProviderProps) => {
  const { schedule, selectedServiceSchedule, selectedAvailability } = useApp();
  const availabilityTime = dateLib(selectedAvailability?.timeslot).clone();

  const initialDateTime = dateLib()
    .set("hour", 0)
    .set("minute", 0)
    .set("millisecond", 0);

  const [currentMonth, setCurrentMonth] = useState(
    availabilityTime || initialDateTime
  );
  const [selectedDate, setSelectedDate] = useState(
    availabilityTime || initialDateTime
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [disablePast, setDisablePast] = useState<boolean | undefined>(true);
  const [maxDate, setMaxDate] = useState(
    initialDateTime.clone().add(1, "year")
  );
  const [minDate, setMinDate] = useState(
    initialDateTime.clone().startOf("month")
  );

  const [days, setDays] = useState<IDay[]>([]);

  const onMonthChange = useCallback(
    (date: Moment, direction: SlideDirection) => {
      setCurrentMonth(date);
      console.log(date, "date");
      setSelectedDate(date);
    },
    [currentMonth]
  );

  const onWeekChange = useCallback(
    (date: Moment, direction: SlideDirection) => {
      setCurrentMonth(date);
      console.log(date.format("YYYY-MM-DD"), "date");
      console.log(selectedDate, "selectedDate");
      console.log(schedule, "schedule");

      const todaySchedule = getScheduleByDay(
        date.format("dddd").toLowerCase() as keyof IWeekSchedule
      );

      if (todaySchedule?.isClosed) {
        return
      }

      // const blockList = todaySchedule.
      setSelectedDate(date);
    },
    [currentMonth]
  );

  const getScheduleByDay = (day: keyof IWeekSchedule) => {
    if (!schedule) {
      console.info("Schedule not initiated yet");
      return null;
    }

    return schedule[day];
  };

  // init days
  useEffect(() => {
    if (currentMonth && schedule) {
      console.log(schedule, "schedule");

      const isBeforeMinDate = currentMonth.clone().isBefore(minDate, "date");
      // why 4 day? to make the current date is on the middle
      const startDay = isBeforeMinDate
        ? minDate.clone().subtract(4, "day")
        : currentMonth.clone().subtract(4, "day");
      const endDay = startDay.clone().add(1, "week");
      const days: IDay[] = [];

      while (startDay.isBefore(endDay, "day")) {
        const date = startDay.add(1, "day").clone();
        const todaySchedule = schedule[
          date.format("dddd").toLowerCase() as keyof IWeekSchedule
        ] as Hours;

        const isAfterLastAvailableDate = date
          .clone()
          .isAfter(selectedServiceSchedule?.lastAvailableDate, "date");

        const day: IDay = {
          date,
          schedule: todaySchedule || {
            isClosed: true,
          },
          isToday: date.isSame(initialDateTime.clone(), "date"),
          disable:
            disablePast && date.clone().isBefore(initialDateTime, "date")
              ? disablePast
              : isAfterLastAvailableDate,
        };
        days.push(day);
      }
      setDays(days);
      setLoading(false);
    }
  }, [currentMonth, schedule]);

  return (
    <CalendarContext.Provider
      value={{
        currentMonth,
        onMonthChange,
        minDate,
        maxDate,
        setMaxDate,
        onWeekChange,
        days,
        disablePast,
        setDisablePast,
        setCurrentMonth,
        setMinDate,
        loading,
        setLoading,
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export function useCalendar() {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error("useCalendar must be used within CalendarProvider");
  }

  return context;
}
