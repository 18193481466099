import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Dragatron from "@/assets/img/dragatron.png";
import PageWrapper from "../PageWrapper";
import DefaultClinicImage from "@/assets/img/clinic-thumbnail.jpg";
import { useEffect, useState } from "react";
import { IPharmacy } from "@/interfaces/IPharamacy";
import { BOOKING_STATUS, IBooking } from "@/interfaces/IBooking";
import { useNavigate, useParams } from "react-router-dom";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import { useModal } from "@/context/DialogContext";
import {
  AccessTimeOutlined,
  CancelOutlined,
  EditOutlined,
  LocationOnOutlined,
  MailOutlined,
  MedicalServicesOutlined,
  PeopleOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { IService } from "@/interfaces/IService";
import ScreeningAccordion from "./ScreeningAccordion";
import { dateLib } from "@/lib/_calendar";
import { getPatientLengthFromABooking, isWithin24Hours } from "@/lib/utils";
import { useUpdateBooking } from "@/hooks/useUpdateBooking";
import { useSnackbar } from "@/context/SnackbarContext";
import ModalConfirm from "../Reservations/UpdateAppointment/ModalConfirm";
import ThankyouImage from "@/assets/img/thank-you-image.png";
import CancelledImage from "@/assets/gif/cancelled.gif";

type AppointmentHistoryDetailProps = {};

const Topbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      })}
    >
      <Box
        sx={{
          height: "auto",
        }}
      >
        <a href="https://www.dragatron.com.au" target="_blank" rel="noreferrer">
          <img
            src={Dragatron}
            alt="Dragatron"
            className=" object-cover aspect-auto"
          />
        </a>
      </Box>
      {!isMobile && <Typography variant="h6">Appointment Detail</Typography>}
    </Box>
  );
};

const AppointmentHistoryDetail = (props: AppointmentHistoryDetailProps) => {
  const [pharmacy, setPharmacy] = useState<IPharmacy | null>(null);
  const [booking, setBooking] = useState<IBooking | null>(null);
  const [service, setService] = useState<IService | null>(null);
  const [notes, setNotes] = useState("");

  const { bookingID } = useParams();
  const { getPharmacy, loading, error, getBooking, getService } =
    useReservationEndpoint();
  const { updateBooking, loadingUpdateBooking } = useUpdateBooking();
  const { openModal, closeModal } = useModal();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "md"));

  useEffect(() => {
    if (bookingID) {
      getBooking(bookingID)
        .then((response) => {
          if (response?.data) {
            const bookingResult = response.data as IBooking;
            setBooking(bookingResult);
            setNotes(bookingResult.notes || "");
          }
        })
        .catch((reason) =>
          console.error("Error getting booking detail ", reason)
        );
    }
  }, [bookingID]);

  useEffect(() => {
    if (booking) {
      getPharmacy(booking.pharmacyID)
        .then((response) => {
          if (response?.data) {
            setPharmacy(response.data as IPharmacy);
          }
        })
        .catch((reason) =>
          console.error("Error getting pharmacy details ", reason)
        );

      getService(booking.serviceId)
        .then((response) => {
          if (response?.data) {
            setService(response.data as IService);
          }
        })
        .catch((reason) =>
          console.error("Error getting service details ", reason)
        );
    }
  }, [booking]);

  useEffect(() => {
    if (loading) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
      return;
    }

    closeModal();
  }, [loading]);

  const onSaveNotes = async () => {
    if (!booking) {
      console.info("No booking initiated!");
      return;
    }
    try {
      const { errors } = await updateBooking({
        variables: {
          input: {
            bookingID: booking.bookingID,
            notes,
          },
        },
      });

      if (Array.isArray(errors) && errors.length > 0) {
        for (const error of errors) {
          console.error(error.message);
        }

        throw new Error("Error updating notes");
      }

      openSnackbar({
        message: "Notes saved!",
        action: <></>,
        severity: "success",
      });
    } catch (error) {
      const err = error as Error;
      console.error("Update notes exception ", err);
      openSnackbar({
        message: err.message,
        action: <></>,
        severity: "error",
      });
    }
  };

  const onCancelAppointment = async () => {
    if (!booking) {
      console.info("No booking initiated!");
      return;
    }
    try {
      openModal({
        content: <ModalConfirm onOk={cancelAppointment} />,
        title: "Are you sure?",
        maxWidth: "sm",
        fullWidth: true,
        backdropClose: false,
      });
    } catch (error) {
      const err = error as Error;
      console.error("Cancel appointment exception ", err);
      openSnackbar({
        message: err.message,
        action: <></>,
        severity: "error",
      });
    }
  };

  const cancelAppointment = async () => {
    if (!booking) {
      console.info("No booking initiated!");
      return;
    }

    try {
      const { errors, data } = await updateBooking({
        variables: {
          input: {
            bookingID: booking.bookingID,
            status: BOOKING_STATUS.CANCELED_BY_PATIENT,
          },
        },
      });

      if (Array.isArray(errors) && errors.length > 0) {
        for (const error of errors) {
          console.error(error.message);
        }

        throw new Error("Error cancelling appointment");
      }

      openSnackbar({
        message: "Appointment Cancelled",
        action: <></>,
        severity: "success",
      });

      setBooking((data?.updateBookings as IBooking) || null);
    } catch (error) {
      const err = error as Error;
      console.error("Cancel appointment exception ", err);
      openSnackbar({
        message: err.message,
        action: <></>,
        severity: "error",
      });
    }
  };

  const isLoadingPharmacy = loading && !pharmacy;
  const isLoadingBooking = loading && !booking;
  const isLoadingService = loading && !service;

  const isBookingCancelled =
    booking?.status === BOOKING_STATUS.CANCELED_BY_PATIENT ||
    booking?.status === BOOKING_STATUS.CANCELED ||
    booking?.status === BOOKING_STATUS.CANCELED_BY_PHARMACY;

  const isCancelAvailable = booking
    ? isWithin24Hours(booking?.timeslot)
    : false;

  const notesDidNotHaveValue = String(notes).trim().length <= 0;

  if (!loading && !error && !booking) {
    return (
      <PageWrapper title="Appointment Detail">
        <Box
          sx={(theme) => ({
            background: alpha(theme.palette.primary.main, 0.2),
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          })}
        >
          <Box
            sx={(theme) => ({
              position: "sticky",
              top: 0,
              zIndex: theme.zIndex.appBar,
            })}
          >
            <Topbar />
          </Box>
          <Box
            position="relative"
            display="flex"
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Box
              sx={(theme) => ({
                width: "50%",
                p: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                [theme.breakpoints.between("xs", "sm")]: {
                  width: "100%",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  width: "100%",
                },
                [theme.breakpoints.between("md", "lg")]: {
                  width: "70%",
                },
              })}
            >
              <Card
                sx={(theme) => ({
                  display: "flex",
                  minHeight: "20rem",
                  alignItems: "center",
                  justifyContent: "center",
                })}
              >
                <Typography>Appointment not found!</Typography>
              </Card>
            </Box>
          </Box>
        </Box>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title="Appointment Detail">
      <Box
        sx={(theme) => ({
          background: alpha(theme.palette.primary.main, 0.2),
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Topbar />
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent={"center"}
          alignItems={"start"}
        >
          <Box
            sx={(theme) => ({
              width: "50%",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                width: "100%",
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "70%",
              },
            })}
          >
            <Card
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                [theme.breakpoints.between("xs", "sm")]: {
                  flexDirection: "column",
                },
              })}
            >
              <CardMedia
                component="img"
                sx={(theme) => ({
                  width: 250,
                  [theme.breakpoints.between("xs", "sm")]: {
                    width: "100%",
                  },
                })}
                image={DefaultClinicImage}
                alt="Clinic"
              />

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Box
                    display="flex"
                    width="100%"
                    p="1rem"
                    flexDirection="column"
                    gap="1rem"
                  >
                    <Box
                      sx={{
                        maxWidth: "350px",
                      }}
                    >
                      {isLoadingPharmacy ? (
                        <Typography variant="h6">
                          <Skeleton variant="text" />
                        </Typography>
                      ) : (
                        <Tooltip title={pharmacy?.pharmacyName}>
                          <Typography
                            variant="h6"
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {pharmacy?.pharmacyName}
                          </Typography>
                        </Tooltip>
                      )}
                    </Box>
                    <Box display="flex" gap="1rem">
                      <MedicalServicesOutlined
                        color="primary"
                        sx={{ width: "22px" }}
                      />
                      <Box
                        sx={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      >
                        {isLoadingService ? (
                          <Typography>
                            <Skeleton variant="text" />
                          </Typography>
                        ) : (
                          <Typography gutterBottom>
                            {service?.serviceName}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" gap="1rem">
                      <AccessTimeOutlined
                        color="primary"
                        sx={{ width: "22px" }}
                      />
                      <Box
                        sx={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      >
                        {isLoadingBooking ? (
                          <Typography>
                            <Skeleton variant="text" />
                          </Typography>
                        ) : (
                          <Typography>
                            {dateLib(booking?.timeslot).format(
                              "dddd, MMMM D YYYY, h:mm a"
                            )}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" gap="1rem">
                      <PeopleOutlined color="primary" sx={{ width: "22px" }} />
                      <Box
                        sx={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      >
                        {isLoadingBooking ? (
                          <>
                            <Typography>
                              <Skeleton variant="text" />
                            </Typography>
                            <Typography variant="body2">
                              <Skeleton variant="text" />
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography gutterBottom>
                              {booking?.patient.firstName}&nbsp;
                              {booking?.patient.lastName}
                            </Typography>
                            <Typography variant="body2">
                              Party of&nbsp;
                              {getPatientLengthFromABooking(booking)}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Box>
            </Card>

            <Card sx={{ display: "flex" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      src={isBookingCancelled ? CancelledImage : ThankyouImage}
                      alt="Thank you"
                      sx={(theme) => ({
                        width: 400,
                        mb: "1rem",
                      })}
                    />
                    <Typography
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {isBookingCancelled
                        ? "Your appointment has been canceled"
                        : "Thanks for your appointment"}
                    </Typography>
                  </Box>

                  <Typography
                    gutterBottom
                    sx={(theme) => ({
                      [theme.breakpoints.between("xs", "sm")]: {
                        textAlign: "justify",
                      },
                    })}
                    variant={isMobile ? "body2" : "body1"}
                  >
                    {isBookingCancelled
                      ? "We regret to inform you that your upcoming appointment has been canceled. If you would like to reschedule or have any questions, please feel free to reach out to us. Our team is here to assist you and ensure you receive the care you need."
                      : "We look forward to seeing you at your appointment. Please arrive a few minutes early to ensure a smooth check-in process. If you have any questions or need to reschedule, feel free to reach out to us. We’re here to provide you with the best care possible."}
                  </Typography>

                  {!isBookingCancelled ? (
                    <Box>
                      <Box
                        sx={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Last-minute changes
                        </Typography>
                      </Box>
                      <Typography
                        sx={(theme) => ({
                          [theme.breakpoints.between("xs", "sm")]: {
                            textAlign: "justify",
                          },
                        })}
                        variant={isMobile ? "body2" : "body1"}
                      >
                        To cancel your appointment, please do so at least 24
                        hours in advance. For cancellations within 24 hours of
                        your scheduled time, contact the clinic directly
                        at&nbsp;
                        {isLoadingPharmacy ? (
                          <Skeleton width={100} />
                        ) : (
                          <a
                            href={`tel:+${pharmacy?.pharmacyPhoneNumber.replaceAll(
                              "+",
                              ""
                            )}`}
                          >
                            {pharmacy?.pharmacyPhoneNumber.replaceAll("+", "")}
                          </a>
                        )}
                        .
                      </Typography>
                    </Box>
                  ) : (
                    <Typography>
                      For any urgent matters, you can contact the clinic
                      directly at&nbsp;
                      <a
                        href={`tel:+${pharmacy?.pharmacyPhoneNumber.replaceAll(
                          "+",
                          ""
                        )}`}
                      >
                        {pharmacy?.pharmacyPhoneNumber.replaceAll("+", "")}
                      </a>
                    </Typography>
                  )}
                </CardContent>
              </Box>
            </Card>

            <Card sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CardContent
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    [theme.breakpoints.between("xs", "sm")]: {
                      pl: 0,
                    },
                  })}
                >
                  <ScreeningAccordion
                    title={
                      "If you’d like, tell us more about yourself and your  preferences so we can provide a better experience."
                    }
                    AccordionDetail={
                      <Box display="flex" flexDirection="column" gap="1rem">
                        <TextField
                          id="outlined-multiline-static"
                          label="Notes"
                          multiline
                          fullWidth
                          rows={4}
                          placeholder="Is there anything we should know?"
                          value={notes}
                          onChange={(event) => setNotes(event.target.value)}
                          disabled={isBookingCancelled}
                        />
                        <Box
                          sx={(theme) => ({
                            display: "flex",
                            justifyContent: "flex-end",
                            [theme.breakpoints.between("xs", "sm")]: {
                              justifyContent: "center",
                              width: "100%",
                            },
                          })}
                        >
                          <Button
                            size={isMobile ? "medium" : "small"}
                            variant="outlined"
                            color="primary"
                            disabled={
                              loadingUpdateBooking ||
                              isLoadingBooking ||
                              isBookingCancelled ||
                              notesDidNotHaveValue
                            }
                            sx={(theme) => ({
                              [theme.breakpoints.between("xs", "sm")]: {
                                width: "100%",
                              },
                            })}
                            onClick={onSaveNotes}
                          >
                            {loadingUpdateBooking || isLoadingBooking ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </Box>
                      </Box>
                    }
                  />
                </CardContent>
              </Box>
            </Card>

            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "center",
                width: "100%",
                [theme.breakpoints.between("xs", "sm")]: {
                  justifyContent: "unset",
                  display: "block",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "row",
                  [theme.breakpoints.between("xs", "sm")]: {
                    flexDirection: "column",
                    "&>button": {
                      width: "100%",
                    },
                  },
                })}
              >
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<EditOutlined />}
                  disabled={isBookingCancelled || !bookingID}
                  onClick={() => {
                    navigate(`/appointments/${bookingID}/edit`);
                  }}
                >
                  Update Appointment
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  startIcon={<CancelOutlined />}
                  disabled={isBookingCancelled || isCancelAvailable}
                  onClick={onCancelAppointment}
                >
                  Cancel Appointment
                </Button>
              </Box>
            </Box>

            <Card
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                [theme.breakpoints.between("xs", "sm")]: {
                  flexDirection: "column",
                },
              })}
            >
              <CardMedia
                component="iframe"
                frameBorder="0"
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${
                  process.env.REACT_APP_PLACE_API_KEY
                }&q=${encodeURIComponent(pharmacy?.pharmacyAddress || "")}`}
                // sx={(theme) => ({
                //   width: 250,
                //   [theme.breakpoints.between("xs", "sm")]: {
                //     width: "100%",
                //   },
                // })}
                // image={DefaultClinicImage}
                // alt="Clinic"
              />

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Box
                    display="flex"
                    width="100%"
                    p="1rem"
                    flexDirection="column"
                    gap="1rem"
                  >
                    <Box
                      sx={{
                        maxWidth: "350px",
                      }}
                    >
                      {isLoadingPharmacy ? (
                        <>
                          <Typography variant="h6">
                            <Skeleton variant="text" />
                          </Typography>
                          <Typography variant="body2">
                            <Skeleton variant="text" />
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Tooltip title={pharmacy?.pharmacyName}>
                            <Typography
                              variant="h6"
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {pharmacy?.pharmacyName}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                    <Box display="flex" gap="1rem">
                      <LocationOnOutlined
                        color="primary"
                        sx={{ width: "22px" }}
                      />
                      <Box
                        sx={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      >
                        {isLoadingPharmacy ? (
                          <Typography>
                            <Skeleton variant="text" />
                          </Typography>
                        ) : (
                          <a
                            href={`https://maps.google.com/maps/?q=${pharmacy?.pharmacyAddress}`}
                            className="no-underline"
                          >
                            <Typography>{pharmacy?.pharmacyAddress}</Typography>
                          </a>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" gap="1rem">
                      <MailOutlined color="primary" sx={{ width: "22px" }} />
                      <Box
                        sx={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      >
                        {isLoadingPharmacy ? (
                          <Typography>
                            <Skeleton variant="text" />
                          </Typography>
                        ) : (
                          <a
                            href={`mailto:${pharmacy?.pharmacyEmailAddress}`}
                            className=" no-underline"
                          >
                            <Typography>
                              {pharmacy?.pharmacyEmailAddress}
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </Box>
                    <Box display="flex" gap="1rem">
                      <PhoneOutlined color="primary" sx={{ width: "22px" }} />
                      <Box
                        sx={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      >
                        {isLoadingPharmacy ? (
                          <Typography>
                            <Skeleton variant="text" />
                          </Typography>
                        ) : (
                          <a
                            href={`tel:+${pharmacy?.pharmacyPhoneNumber.replaceAll(
                              "+",
                              ""
                            )}`}
                            className=" no-underline"
                          >
                            <Typography>
                              {pharmacy?.pharmacyPhoneNumber.replaceAll(
                                "+",
                                ""
                              )}
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default AppointmentHistoryDetail;
