import { alpha, Box, Button, Card, CardMedia, Typography } from "@mui/material";
import Dragatron from "@/assets/img/dragatron.png";
import SuccessAnimation from "@/assets/gif/screening-success.gif";
import PageWrapper from "../PageWrapper";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const Topbar = () => {
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      })}
    ></Box>
  );
};

const SubmitSuccessStatus = (props: Props) => {
  const navigate = useNavigate();
  const { bookingID } = useParams();
  return (
    <PageWrapper title="Screening Success">
      <Box
        sx={(theme) => ({
          background: alpha(theme.palette.primary.main, 0.2),
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Topbar />
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent={"center"}
          alignItems={"start"}
        >
          <Box
            sx={(theme) => ({
              width: "40%",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              "& .MuiCard-root": {
                p: "1rem",
              },
              [theme.breakpoints.between("xs", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "70%",
              },
            })}
          >
            <Card
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <Box
                sx={(theme) => ({
                  p: "1rem 5rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "center",
                  [theme.breakpoints.between("xs", "sm")]: {
                    p: "1rem",
                  },
                })}
              >
                <Box
                  sx={{
                    height: "auto",
                  }}
                >
                  <a
                    href="https://www.dragatron.com.au"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Dragatron}
                      alt="Dragatron"
                      className=" object-cover aspect-auto"
                    />
                  </a>
                </Box>

                <CardMedia
                  component="img"
                  src={SuccessAnimation}
                  sx={{
                    width: "20rem",
                  }}
                />

                <Typography
                  gutterBottom
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  Your Screening Question has been submited
                </Typography>

                <Typography gutterBottom textAlign="justify">
                  Thank you very much for taking the time to fill out this
                  questionnaire. Your input is very valuable and will help us in
                  the next steps. We appreciate your participation!
                </Typography>

                <Button
                  onClick={() =>
                    navigate(`/appointments/${bookingID}`, {
                      replace: true,
                    })
                  }
                  variant="contained"
                  fullWidth
                >
                  Return to Appointment Detail
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default SubmitSuccessStatus;
