import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";

type GooglePlacesInputProps = {
  onPlaceSelected: (
    places: google.maps.places.PlaceResult,
    ref: React.RefObject<HTMLInputElement>,
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>
  ) => void;
  label?: string;
} & TextFieldProps;

const GooglePlacesInput: React.FC<GooglePlacesInputProps> = ({
  onPlaceSelected,
  label = "Address",
  ...rest
}) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_PLACE_API_KEY,
    onPlaceSelected,
    options: {
      types: ["address"],
      componentRestrictions: { country: "au" }, // Adjust this as needed
    },
    inputAutocompleteValue: "new-password", // Helps to avoid Chrome's autocomplete suggestions
  });

  return (
    <TextField
      fullWidth
      color="primary"
      variant="outlined"
      inputRef={ref}
      autoComplete="new-password"
      label={label}
      size="small"
      {...rest}
    />
  );
};

export default GooglePlacesInput;
