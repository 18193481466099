import { Box, Typography, alpha, Card } from "@mui/material";
import PageWrapper from "../PageWrapper";
import Dragatron from "@/assets/img/dragatron.png";
import ValidationForm, {
  IScreeningValidationFormProps,
} from "./ValidationForm";
import { IBooking } from "@/interfaces/IBooking";

interface IPatientValidatorProps
  extends Pick<IScreeningValidationFormProps, "onValidate"> {
  booking?: IBooking;
}

const Topbar = () => {
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      })}
    ></Box>
  );
};

const PatientValidator = ({ booking, onValidate }: IPatientValidatorProps) => {
  return (
    <PageWrapper title="Patient Validator">
      <Box
        sx={(theme) => ({
          background: alpha(theme.palette.primary.main, 0.2),
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          gap: "1rem",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Topbar />
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent={"center"}
          alignItems={"start"}
        >
          <Box
            sx={(theme) => ({
              width: "50%",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              "& .MuiCard-root": {
                p: "1rem",
              },
              [theme.breakpoints.between("xs", "sm")]: {
                width: "100%",
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "70%",
              },
            })}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "1rem",
                  gap: "1rem",
                  [theme.breakpoints.between("xs", "sm")]: {
                    gap: ".5rem",
                  },
                })}
              >
                <a
                  href="https://www.dragatron.com.au"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Dragatron}
                    alt="Dragatron"
                    className=" object-cover aspect-auto"
                  />
                </a>
                <Typography variant="h6">Verify Patient Identity</Typography>
              </Box>
              <Box
                sx={(theme) => ({
                  width: "100%",
                  p: "0 1rem 1rem 1rem",
                  [theme.breakpoints.between("xs", "sm")]: {
                    p: "unset",
                  },
                })}
              >
                <ValidationForm onValidate={onValidate} booking={booking} />
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default PatientValidator;
