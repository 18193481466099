import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { ApolloProvider } from "@apollo/client";
import { AppProvider } from "./context/AppContext";
import apolloClient from "./apollo-client";
import { ModalProvider } from "./context/DialogContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import Routes from "./Routes";
import "./index.css";
import { StepperProvider } from "./context/StepperProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppProvider>
            <SnackbarProvider>
              <ModalProvider>
                <StepperProvider>
                  <Routes />
                </StepperProvider>
              </ModalProvider>
            </SnackbarProvider>
          </AppProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
