import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#00A0AA",
      100: "#00CAD6",
      200: "#5df2ff",
      400: "#007880",
      500: "#005258",
      600: "#003033",
      700: "#001416",
    },
    secondary: {
      main: "#FF6A93",
      100: "#ffe5ea",
      200: "#ffadbf",
      400: "#eb006b",
      500: "#AA004B",
      600: "#6d002e",
      700: "#360013",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: "#00A0AA",
          ":hover": {
            opacity: ".9",
            background: "#00A0AA",
          },
        },
      },
    },
  },
});

export default theme;
