import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  chooseTimeAndReviewStep,
  steps as defaultSteps,
} from "../constant/stepper";
import { useNavigate } from "react-router";
import { App } from "../lib/app";
import { useLocation } from "react-router-dom";
import { useApp } from "./AppContext";
import { getSuburbsFromPlacesResult } from "../lib/utils";
const states = new App();
interface StepperContextProps {
  activeStep: Steps;
  nextStep: () => void;
  prevStep: () => void;
  resetStepper: () => void;
  setSteps?: Dispatch<SetStateAction<Steps[]>>;
  steps?: Steps[];
}

const StepperContext = createContext<StepperContextProps | undefined>(
  undefined
);

export const useStepper = () => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useStepper must be used within a StepperProvider");
  }
  return context;
};

interface StepperProviderProps {
  children: React.ReactNode;
}

export interface Steps {
  index: number;
  name:
    | "SELECT_CLINIC"
    | "SELECT_TIME"
    | "REVIEW_APPOINMENT"
    | "SELECT_SERVICE"
    | "PAYMENT_DETAIL";
  label: string;
  path: string;
  isVisible: boolean;
}

export const StepperProvider: React.FC<StepperProviderProps> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const { selectedService } = useApp();

  const [activeStep, setActiveStep] = useState<Steps>(states.getSteps());
  const [steps, setSteps] = useState<Steps[]>([]);
  const navigate = useNavigate();

  // listener allow payment in service level
  useEffect(() => {
    if (selectedService && pathname.includes("/pharmacies")) {
      if (typeof setSteps === "function") {
        const stepsWithPayment = chooseTimeAndReviewStep.map((step) => {
          if (step.name === "PAYMENT_DETAIL") {
            return {
              ...step,
              isVisible: selectedService.allowPayment,
            };
          }
          return step;
        });

        setSteps(stepsWithPayment);
      }
    }

    if (selectedService && !pathname.includes("/pharmacies")) {
      if (typeof setSteps === "function") {
        const stepsWithPayment = defaultSteps.map((step) => {
          if (step.name === "PAYMENT_DETAIL") {
            return {
              ...step,
              isVisible: selectedService.allowPayment,
            };
          }
          return step;
        });

        setSteps(stepsWithPayment);
      }
    }
  }, [selectedService, pathname]);

  const nextStep = () => {
    setActiveStep((prevStep) => {
      const nextStepIndex = prevStep.index + 1;
      const indexInExistingStep = steps.findIndex(
        (step) => step.index === nextStepIndex
      );

      const step = steps[indexInExistingStep];
      states.saveSteps(step);

      // navigate(
      //   `/new/reservations/${
      //     selectedCategory?.categoryID
      //   }/${getSuburbsFromPlacesResult(selectedLocation)}/${
      //     selectedService?.serviceId
      //   }/${step.path}`
      // );
      return step;
    });
  };

  const prevStep = () => {
    if (activeStep.index === 0) {
      navigate("/");
      return;
    }

    setActiveStep((prevStep) => {
      const prevStepIndex = Math.max(0, prevStep.index - 1);
      const indexInExistingStep = steps.findIndex(
        (step) => step.index === prevStepIndex
      );

      const step = steps[indexInExistingStep];
      states.saveSteps(step);
      // navigate(
      //   `/new/reservations/${
      //     selectedCategory?.categoryID
      //   }/${getSuburbsFromPlacesResult(selectedLocation)}/${
      //     selectedService?.serviceId
      //   }/${step.path}`
      // );

      return step;
    });
  };

  const resetStepper = () => {
    setActiveStep(steps[0]);
    states.saveSteps(steps[0]);
  };

  const contextValue: StepperContextProps = {
    activeStep,
    nextStep,
    prevStep,
    resetStepper,
    setSteps,
    steps,
  };

  useEffect(() => {
    const stepsPath = pathname.split("/")[pathname.split("/").length - 1];
    const shouldBeTheActiveStep = steps.find((step) => step.path === stepsPath);
    setActiveStep(shouldBeTheActiveStep || states.getSteps());
  }, [pathname, steps]);

  return (
    <StepperContext.Provider value={contextValue}>
      {children}
    </StepperContext.Provider>
  );
};
