import { Box, Button, Card, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "@/pages/PageWrapper";
import { CheckCircleOutline } from "@mui/icons-material";

const Success = () => {
  const navigate = useNavigate();
  const { pharmacyID } = useParams();

  return (
    <PageWrapper title="Success">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "60vh",
          gap: "2rem",
        }}
      >
        <Card
          sx={{
            p: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1.5rem",
            maxWidth: "500px",
            width: "100%",
          }}
        >
          <CheckCircleOutline
            color="success"
            sx={{ width: "64px", height: "64px" }}
          />
          <Typography variant="h5" textAlign="center">
            Patients Successfully Uploaded
          </Typography>
          <Typography color="text.secondary" textAlign="center">
            Your patients have been successfully added to the clinic.
          </Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={() =>
              navigate(`/pharmacies/${pharmacyID}/aged-care-clinics`)
            }
          >
            Back to Clinic List
          </Button>
        </Card>
      </Box>
    </PageWrapper>
  );
};

export default Success;
