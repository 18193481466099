import Calendar from "@/components/Calendar/Calendar";
import { BackNavigation } from "@/components/new/BackNavigation";
import { steeperPath } from "@/constant/stepper";
import { useApp } from "@/context/AppContext";
import { useSnackbar } from "@/context/SnackbarContext";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { IPharmacy } from "@/interfaces/IPharamacy";
import { IService } from "@/interfaces/IService";
import { dateLib } from "@/lib/_calendar";
import PageWrapper from "@/pages/PageWrapper";
import Availabilities from "@/pages/Reservations/ChooseTime/Availabilities";
import Days from "@/pages/Reservations/ChooseTime/Days";
import {
  AccessTimeOutlined,
  InfoOutlined,
  LocalPharmacyOutlined,
} from "@mui/icons-material";
import {
  Card,
  Typography,
  Tooltip,
  Fade,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const PharmacySelectTimePage = () => {
  const {
    selectedAvailability,
    selectedPharmacy,
    setSelectedService,
    selectedService,
    setSelectedPharmacy,
    setSelectedAvailability,
  } = useApp();

  const params = useParams();
  const serviceID = params.serviceID;
  const pharmacyID = params.pharmacyID;
  const availabilityID = selectedAvailability?.availabilityID;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const { openSnackbar } = useSnackbar();
  // const { setLoading: setLoadingApp } = useCalendar();

  const { getService, error, getPharmacy } = useReservationEndpoint();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (serviceID && !selectedService) {
      getService(serviceID)
        .then((response) => {
          setSelectedService((response?.data as IService) || null);
        })
        .catch((reason) =>
          console.error("Error getting service data ", reason)
        );
    }
  }, [serviceID, selectedService]);

  useEffect(() => {
    if (!selectedPharmacy && selectedService) {
      getPharmacy(selectedService.pharmacyID)
        .then((response) => {
          setSelectedPharmacy((response?.data as IPharmacy) || null);
        })
        .catch((reason) =>
          console.error("Error getting pharmacy detail ", reason)
        );
    }
  }, [selectedPharmacy, selectedService]);

  useEffect(() => {
    if (selectedPharmacy && selectedService) {
      setLoading(false);
    }
  }, [selectedPharmacy, selectedService]);

  if (error && !loading) {
    return (
      <PageWrapper title="Select Time">
        <Card
          sx={{
            width: "100%",
            minHeight: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>
            Sorry, we are having a problem processing your request.
          </Typography>
        </Card>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title="Select Time">
      <Box>
        <Card
          sx={(theme) => ({
            p: "2rem",
            mb: selectedAvailability && "10rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <BackNavigation
            title={selectedPharmacy?.pharmacyName || ""}
            description={`Here are available slots for ${selectedService?.serviceName} Service`}
            onClick={() => {
              setSelectedAvailability(null);
              navigate(
                `/pharmacies/${pharmacyID}/appointment/${selectedService?.serviceId}/${steeperPath.SELECT_SERVICE}`
              );
            }}
          />

          <Box
            sx={(theme) => ({
              my: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                my: "1rem",
              },
            })}
          >
            <Typography
              variant={isMobile ? "body1" : "h6"}
              color="primary"
              gutterBottom
            >
              Select Date
            </Typography>
            <Calendar
              selectProps={{
                size: "small",
              }}
              formControlProps={{
                sx: {
                  m: 0,
                },
              }}
              renderViewDay={() => <Days />}
            />
          </Box>
          <Box>
            <Box
              sx={(theme) => ({
                my: "2rem",
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.between("xs", "sm")]: {
                  my: "1rem",
                },
              })}
            >
              <Typography variant={isMobile ? "body1" : "h6"} color="primary">
                Select Time
              </Typography>
              <Tooltip title="The time showed is based on the clinics timezone">
                <InfoOutlined
                  color="primary"
                  sx={{
                    width: 14,
                    height: 14,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Box>
            <div className="w-full min-h-52">
              <Availabilities />
            </div>
          </Box>
        </Card>

        <Fade
          in={selectedAvailability !== null}
          style={{
            background: theme.palette.common.white,
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py=".5rem"
          >
            <Box
              sx={(theme) => ({
                width: "30%",
                display: "flex",
                flexDirection: "column",
                px: "1rem",
                gap: "1rem",
                [theme.breakpoints.between("xs", "sm")]: {
                  gap: ".5rem",
                  width: "100%",
                },
                [theme.breakpoints.between("sm", "lg")]: {
                  width: "50%",
                  px: ".5rem",
                },
              })}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <Typography>You've selected</Typography>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    [theme.breakpoints.between("xs", "sm")]: {
                      gap: ".5rem",
                    },
                  })}
                >
                  <LocalPharmacyOutlined color="primary" />
                  <Typography>{selectedPharmacy?.pharmacyName}</Typography>
                </Box>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    [theme.breakpoints.between("xs", "sm")]: {
                      gap: ".5rem",
                    },
                  })}
                >
                  <AccessTimeOutlined color="primary" />
                  <Typography>
                    {dateLib(selectedAvailability?.timeslot)
                      .clone()
                      .format("dddd, MMMM DD YYYY - hh:mm A")}
                  </Typography>
                </Box>
              </Box>
              <Button
                disabled={!selectedAvailability}
                variant="contained"
                onClick={() => {
                  if (!selectedAvailability) {
                    return;
                  }

                  if (!selectedAvailability.isAvailable) {
                    return;
                  }

                  if (
                    dateLib(selectedAvailability.timeslot)
                      .clone()
                      .isBefore(dateLib().clone())
                  ) {
                    openSnackbar({
                      message:
                        "You've missed this time. Please choose another time!",
                      severity: "warning",
                    });
                    return;
                  }

                  navigate(
                    `/pharmacies/${pharmacyID}/appointment/${serviceID}/${availabilityID}/${steeperPath.REVIEW_APPOINMENT}`
                  );

                  // nextStep();
                }}
                fullWidth
                size="large"
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Fade>
      </Box>
    </PageWrapper>
  );
};

export default PharmacySelectTimePage;
