import { Card, Typography, Container, Button, Box, alpha } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModalLoading from "../../../components/Loading/Loading";
import { useApp } from "../../../context/AppContext";
import { useGetBooking } from "../../../hooks/useGetBooking";
import { IBooking } from "../../../interfaces/IBooking";
import { IPharmacy } from "../../../interfaces/IPharamacy";
import PageWrapper from "../../PageWrapper";
import {
  AccessTimeOutlined,
  AutorenewOutlined,
  LocalPharmacyOutlined,
} from "@mui/icons-material";
import { dateLib } from "@/lib/_calendar";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";

const DetailAppointment = () => {
  const navigation = useNavigate();
  const { bookingID } = useParams();
  const [booking, setbooking] = useState<IBooking | null>(null);
  const [pharmacy, setPharmacy] = useState<IPharmacy | null>(null);
  const {
    setSelectedLocation,
    setSelectedPharmacy,
    setSelectedService,
    setSelectedAvailability,
  } = useApp();
  const { getPharmacy, error, loading } = useReservationEndpoint();
  const { fetchBooking, loadingGetBooking, errorGetBooking } = useGetBooking();

  useEffect(() => {
    if (bookingID) {
      fetchBooking({
        variables: {
          bookingID: bookingID,
        },
        onCompleted(data) {
          const booking = data.getBookings as IBooking;
          setbooking(booking);
        },
      });
    }
  }, [bookingID]);

  useEffect(() => {
    if (booking) {
      getPharmacy(booking.pharmacyID)
        .then((response) => {
          if (response?.data) {
            setPharmacy(response?.data as IPharmacy);
          }
        })
        .catch((reason) => console.error("Error getting pharmacy ", reason));
    }
  }, [booking]);

  if (loading || loadingGetBooking) {
    return (
      <>
        <ModalLoading open={true} text="Please Wait ...." />
      </>
    );
  }

  if (error || errorGetBooking) {
    return (
      <div className="xl:h-screen lg:h-screen md:h-screen xl:w-full lg:w-full md:w-full h-auto w-full flex justify-center items-center px-2 pb-4 pt-6">
        <header className="xl:w-full lg:w-full md:w-full lg:px-10 xl:px-10 md:px-10 lg:py-24 xl:py-24 fixed top-0 left-0 right-0 bg-white w-full py-20"></header>
        <Card className="xl:w-full lg:w-full md:w-full xl:h-auto lg:h-auto md:h-auto lg:max-w-2xl xl:max-w-2xl lg:py-16 xl:py-16 lg:px-20 xl:px-20 z-10 flex flex-col items-center w-full h-full rounded-2xl shadow-xl p-4">
          <Typography>
            Sorry, we are having a problem processing your request. confirm your
            booking status at&nbsp;
            <a href="mailto:admin@dragatron.com.au">here</a>
          </Typography>
        </Card>
      </div>
    );
  }

  if (!error && !errorGetBooking && !booking) {
    return (
      <div className="xl:h-screen lg:h-screen md:h-screen xl:w-full lg:w-full md:w-full h-auto w-full flex justify-center items-center px-2 pb-4 pt-6">
        <header className="xl:w-full lg:w-full md:w-full lg:px-10 xl:px-10 md:px-10 lg:py-24 xl:py-24 fixed top-0 left-0 right-0 bg-white w-full py-20"></header>
        <Card className="xl:w-full lg:w-full md:w-full xl:h-auto lg:h-auto md:h-auto lg:max-w-2xl xl:max-w-2xl lg:py-16 xl:py-16 lg:px-20 xl:px-20 z-10 flex flex-col items-center w-full h-full rounded-2xl shadow-xl p-4">
          <Typography className="text-center" gutterBottom>
            Sorry, Reservation not found. Feel free to contact us if you believe
            this is a mistake at&nbsp;
            <a href="mailto:admin@dragatron.com.au">here</a>
          </Typography>
          <Button
            startIcon={<AutorenewOutlined />}
            onClick={() => window.location.reload()}
          >
            refresh
          </Button>
        </Card>
      </div>
    );
  }

  return (
    <PageWrapper title="Reservation Detail">
      <Box
        sx={(theme) => ({
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          px: ".5rem",
          py: "1rem",
          bgcolor: alpha(theme.palette.primary.main, 0.2),
        })}
      >
        <Box
          component="header"
          sx={(theme) => ({
            width: "100%",
            height: "10rem",
            background: theme.palette.common.white,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
          })}
          // className="xl:w-full lg:w-full md:w-full lg:px-10 xl:px-10 md:px-10 lg:py-24 xl:py-24 fixed top-0 left-0 right-0 bg-white w-full py-20"
        ></Box>
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: theme.zIndex.fab,
          })}
        >
          <Card
            sx={(theme) => ({
              width: "50%",
              p: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                width: "100%",
              },
              [theme.breakpoints.between("sm", "lg")]: {
                width: "70%",
              },
            })}
          >
            <Container
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href="https://www.dragatron.com.au"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/dragatron.png"
                  alt="Dragatron"
                  className="object-cover"
                />
              </a>
            </Container>
            <Container
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/images/doctor.png"
                alt="Dragatron"
                className="object-cover xl:w-auto lg:w-auto md:w-auto xl:h-auto md:h-auto lg:h-auto h-44"
              />
            </Container>
            <Typography
              variant="h4"
              className="xl:text-center lg:text-center md:text-center xl:font-semibold lg:font-semibold md:font-semibold xl:whitespace-nowrap lg:whitespace-nowrap md:whitespace-nowrap lg:mb-8 xl:mb-8 xl:text-4xl text-center font-semibold text-2xl"
            >
              Your Booking is Confirmed
            </Typography>
            <div className="lg:py-8 md:py-8 xl:py-8 lg:px-8 xl:px-8 md:px-8 flex flex-col gap-2 py-4 px-3 my-4 bg-primary/10 rounded-md">
              <div className="flex gap-4">
                <LocalPharmacyOutlined color="primary" />
                <div>
                  <Typography className="xl:font-semibold lg:font-semibold md:font-semibold font-medium text-base mb-1">
                    {pharmacy?.pharmacyName}
                  </Typography>

                  <Typography className=" font-normal text-grey">
                    <a
                      href={`http://maps.google.com/maps?q=${pharmacy?.pharmacyAddress}`}
                      target="_blank"
                      className="font-normal text-grey"
                      rel="noreferrer"
                    >
                      {pharmacy?.pharmacyAddress}
                    </a>
                  </Typography>
                  <Typography className=" font-normal text-grey">
                    <a
                      href={`tel:${pharmacy?.pharmacyPhoneNumber}`}
                      target="_blank"
                      className="font-normal text-grey"
                      rel="noreferrer"
                    >
                      {pharmacy?.pharmacyPhoneNumber}
                    </a>
                  </Typography>
                </div>
              </div>
              <div className="flex gap-4 items-center w-full">
                <AccessTimeOutlined color="primary" />

                <Typography className=" xl:font-semibold lg:font-semibold md:font-semibold font-medium text-base">
                  {dateLib(booking?.timeslot)
                    .clone()
                    .format("dddd, MMMM Do YYYY, h:mm a")}
                </Typography>
              </div>
            </div>
            <Container className="font-normal text-grey/70 w-full flex flex-col lg:gap-4 xl:gap-4 lg:my-4 xl:my-4 my-2 gap-2">
              <Typography className="text-center">
                Thank you for your booking. You should receive a confirmation
                email By now. Didn't get the email?&nbsp;
                <a href="mailto:admin@dragatron.com.au">contact</a>&nbsp;us.
              </Typography>
              <Typography className="text-center">
                A reminder email will be sent 24 hours before your appointment.
              </Typography>
            </Container>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  navigation("/");
                  setSelectedLocation(null);
                  setSelectedPharmacy(null);
                  setSelectedService(null);
                  setSelectedAvailability(null);
                }}
              >
                Return to Homepage
              </Button>
            </Box>
          </Card>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default DetailAppointment;
