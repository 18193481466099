import React, { FC } from "react";
import { Modal, Box, Typography } from "@mui/material";
// import LoadingGif from "./loading.gif";
import LoadingGif from "@/assets/gif/Loader.gif";

interface IModalLoadingProps {
  open: boolean;
  text?: string;
}

const ModalLoading: FC<IModalLoadingProps> = (props) => {
  const { open, text } = props;

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "24rem",
    bgcolor: "#fff",
    border: "none",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 4,
    borderRadius: "5px",
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="modal-loading"
    >
      <Box sx={style}>
        <img data-testid="loader-gif" src={LoadingGif} alt={"loading"} style={{ width: "12rem" }} />

        <Typography
          id="modal-modal-description"
          data-testid="loader-text"
          sx={{
            mt: 2,
            textAlign: "center",
            mb: 4,
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ModalLoading;
