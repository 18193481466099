import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { CloseIcon, SearchIcon } from "../Icons/Icons";
import useDebounce from "../../hooks/useDebounce";
import { nanoid } from "nanoid";
import { useApp } from "../../context/AppContext";

export type TResult = google.maps.places.PlaceResult & {
  resource?: "cache" | "network";
};
type TAutocompletePrediction = google.maps.places.AutocompletePrediction;
type Props = {};

const PlacesAutocompleteService = (props: Props) => {
  const { setLoading, setSelectedLocation, selectedLocation } = useApp();
  const [selectedPlace, setSelectedPlace] = useState<TResult | null>(
    selectedLocation
  );
  const [keyword, setKeyword] = useState(
    selectedPlace?.formatted_address || ""
  );
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    autocompleteSessionToken,
    refreshSessionToken,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_PLACE_API_KEY,
    sessionToken: true,
  });

  const handleGetDetail = (prediction: TAutocompletePrediction) => {
    if (!placesService) {
      console.info("Place service not initialized yet");
      return;
    }

    placesService.getDetails(
      {
        placeId: prediction.place_id,
        sessionToken: autocompleteSessionToken,
      },
      (result, status) => {
        if (status === "OK") {
          setSelectedPlace({ ...result, resource: "network" });
          setSelectedLocation({ ...result, resource: "network" });
          refreshSessionToken();
          setOpenAutocomplete(false);
          setKeyword(result!.formatted_address!);
          setIsSelected(true);
        } else {
          console.error("Error fetching place details:", status);
          setIsSelected(false);
          setSelectedLocation({ ...result, resource: "cache" });
        }
      }
    );
  };

  const debounceKeywordSearch = useDebounce<string>(keyword, 700);

  useEffect(() => {
    if (debounceKeywordSearch !== "") {
      if (!selectedPlace?.resource) {
        getPlacePredictions({
          input: debounceKeywordSearch,
          types: ["(cities)"],
          componentRestrictions: { country: "au" },
          sessionToken: autocompleteSessionToken,
        });
      }
    } else {
      setIsSelected(false);
    }
  }, [debounceKeywordSearch]);

  useEffect(() => {
    if (selectedLocation) {
      setSelectedPlace({ ...selectedLocation, resource: "cache" });
    }
  }, [selectedLocation]);

  useEffect(() => {
    setLoading(isPlacePredictionsLoading);
  }, [isPlacePredictionsLoading]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsSelected(false);
        setOpenAutocomplete(false);
      }}
    >
      <Box
        position={"relative"}
        sx={{
          width: "100%",
          flex: 1,
        }}
      >
        <TextField
          autoComplete="off"
          name="locations"
          placeholder="Search locations"
          label="Locations"
          fullWidth
          value={keyword}
          onFocus={() => setOpenAutocomplete(true)}
          onChange={(evt) => {
            setKeyword(evt.target.value);
            setIsSelected(false);
          }}
          InputProps={{
            endAdornment: isPlacePredictionsLoading ? (
              <InputAdornment position="end">
                <IconButton>
                  <CircularProgress size={24} />
                </IconButton>
              </InputAdornment>
            ) : keyword.trim().length > 0 ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setKeyword("");
                    setSelectedPlace(null);
                    setIsSelected(false);
                    setSelectedLocation(null);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Fade in={openAutocomplete}>
          <Box
            component={"ul"}
            sx={(theme) => ({
              position: "absolute",
              left: 0,
              right: 0,
              padding: 0,
              margin: 0,
              my: ".25rem",
              background: theme.palette.common.white,
              boxShadow: theme.shadows[18],
              maxHeight: "20rem",
              overflowY: "scroll",
              borderRadius: 1,
              width: "100%",
              py: ".5rem",
              "& li": {
                listStyle: "none",
              },
            })}
            key={nanoid()}
          >
            {placePredictions.length === 0 ? (
              <Box
                component={"li"}
                sx={{
                  py: "1rem",
                  px: ".5rem",
                  textAlign: "center",
                  width: "100%",
                }}
                key={nanoid()}
              >
                {isPlacePredictionsLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography>No result</Typography>
                )}
              </Box>
            ) : (
              placePredictions.map((item) => (
                <Box
                  component={"li"}
                  onClick={() => handleGetDetail(item)}
                  sx={(theme) => ({
                    py: "1rem",
                    px: ".5rem",
                    cursor: "pointer",
                    "&:hover": {
                      background: alpha(theme.palette.primary.main, 0.2),
                    },
                  })}
                  key={nanoid()}
                >
                  <Typography>{item.description}</Typography>
                </Box>
              ))
            )}
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
};

export default PlacesAutocompleteService;
