import { ArrowIcon } from "@/components/Icons/Icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Card,
  Divider,
  styled,
  Typography,
  Link,
} from "@mui/material";
import PageWrapper from "../PageWrapper";
import { useNavigate } from "react-router-dom";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { dateLib } from "@/lib/_calendar";

type Props = {};

const List = styled("ul")({
  padding: 0,
  margin: 0,
  listStyle: "none",
});

const ListItem = styled("li")({
  display: "table-row",
  width: "100%",
  "& span": {
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    display: "table-cell",
    "@media print": {
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
      fontSize: ".75rem",
      "&:last-of-type": {
        paddingLeft: ".5rem",
      },
    },
    "&:last-of-type": {
      paddingLeft: ".5rem",
    },
  },
});

const ListItemLabel = styled("span")({
  "@media print": {
    textWrap: "nowrap",
  },
});

const ListItemValue = styled("span")({
  fontWeight: "bold",
});

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="lg:flex xl:flex  xl:gap-4 lg:gap-4 flex gap-4">
      <span role="button" tabIndex={-1} onClick={() => navigate(-1)}>
        <div className="xl:w-7 lg:w-7 md:w-7 w-8 xl:h-7 lg:h-7 md:h-7 h-8 border-primary border-solid border-[.07rem] rounded-md flex items-center justify-center shadow-sm shadow-grey-400/40 hover:shadow-md hover:shadow-primary/20 cursor-pointer">
          <ArrowIcon className="fill-primary rotate-90" />
        </div>
      </span>
      <Typography variant="h6">Privacy at Dragatron</Typography>
    </div>
  );
};

const PrivacyHeader = () => {
  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.between("xs", "sm")]: {
          flexDirection: "column",
        },
      })}
    >
      <BackButton />
    </Card>
  );
};

const Topbar = () => {
  return (
    <Box
      component={"header"}
      sx={(theme) => ({
        bgcolor: theme.palette.common.white,
        width: "100%",
        minHeight: "7rem",
        display: "flex",
        height: "auto",
        alignItems: "center",
        justifyContent: "space-between",
        px: "2rem",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      })}
    ></Box>
  );
};

const PrivacyStatement = (props: Props) => {
  return (
    <PageWrapper title="Privacy">
      <Box
        sx={(theme) => ({
          background: alpha(theme.palette.primary.main, 0.2),
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        })}
      >
        <Box
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: theme.zIndex.appBar,
          })}
        >
          <Topbar />
        </Box>
        <Box
          position="relative"
          display="flex"
          justifyContent={"center"}
          alignItems={"start"}
        >
          <Box
            sx={(theme) => ({
              width: "50%",
              p: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              "& .MuiCard-root": {
                p: "1rem",
              },
              [theme.breakpoints.between("xs", "sm")]: {
                width: "100%",
              },
              [theme.breakpoints.between("sm", "md")]: {
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                width: "70%",
              },
            })}
          >
            <PrivacyHeader />

            {/* <Card
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <List>
                <ListItem>
                  <ListItemLabel>Effective Date</ListItemLabel>
                  <Typography component="span">:</Typography>
                  <ListItemValue>N/A</ListItemValue>
                </ListItem>
                <ListItem>
                  <ListItemLabel>ABN</ListItemLabel>
                  <Typography component="span">:</Typography>
                  <ListItemValue>27 637 798 871</ListItemValue>
                </ListItem>
                <ListItem>
                  <ListItemLabel>Contact Email</ListItemLabel>
                  <Typography component="span">:</Typography>
                  <ListItemValue>
                    <a href="mailto:support@dragatron.com.au">
                      support@dragatron.com.au
                    </a>
                  </ListItemValue>
                </ListItem>
                <ListItem>
                  <ListItemLabel>Contact Email</ListItemLabel>
                  <Typography component="span">:</Typography>
                  <ListItemValue>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://maps.google.com/maps/?q=36/7 Narabang Way, Belrose 2085"
                    >
                      36/7 Narabang Way, Belrose 2085
                    </a>
                  </ListItemValue>
                </ListItem>
                <ListItem>
                  <ListItemLabel>Contact Phone</ListItemLabel>
                  <Typography component="span">:</Typography>
                  <ListItemValue>
                    <a target="_blank" rel="noreferrer" href="tel:+61406213088">
                      61406213088
                    </a>
                  </ListItemValue>
                </ListItem>
              </List>
              <Typography paragraph textAlign="justify">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui
                cupiditate voluptates, error nemo, molestiae enim iure
                perspiciatis velit natus ipsum suscipit quas laudantium aliquam
                illum numquam eveniet fugit. Odio, rerum.
              </Typography>
              <Typography paragraph textAlign="justify">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Exercitationem quidem doloremque, nihil consequatur asperiores
                eligendi harum illo, quis voluptatum obcaecati aliquam optio
                dolorum possimus rerum. Esse incidunt quibusdam possimus error.
              </Typography>
              <Typography paragraph textAlign="justify">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Recusandae enim quas architecto deserunt ipsum doloribus,
                debitis eum quo, repudiandae rerum consectetur sapiente, qui
                quisquam exercitationem distinctio. Id repellat adipisci
                quisquam.
              </Typography>
            </Card> */}

            <Card>
              <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
                <Typography variant="h4" gutterBottom align="center">
                  Privacy Policy for Dragatron PTY LTD
                </Typography>

                <Typography variant="subtitle1" align="center" gutterBottom>
                  Effective Date: 2024/11/25
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="body2" gutterBottom>
                  <strong>ABN:</strong> 27 637 798 871
                  <br />
                  <strong>Contact Email:</strong> <Link href="mailto:support@dragatron.com.au">support@dragatron.com.au</Link>
                  <br />
                  <strong>Physical Address:</strong> <Link target="_blank" href="https://maps.google.com/maps/?q=Dragatron">36/7 Narabang Way, Belrose 2085</Link> 
                  <br />
                  <strong>Contact Phone:</strong> <Link href="tel:+61406213088">+61406213088</Link>
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h5" gutterBottom>
                  Introduction
                </Typography>
                <Typography variant="body1" paragraph>
                  Dragatron PTY LTD (Dragatron, we, or us) is committed to
                  protecting the privacy of all personal and sensitive
                  information collected through our platforms, services, and
                  partnerships. This Privacy Policy explains how we collect,
                  use, disclose, and safeguard personal information in
                  accordance with the
                  <em>Privacy Act 1988 (Cth)</em> (Privacy Act) and the
                  Australian Privacy Principles (APPs).
                </Typography>
                <Typography variant="body1" paragraph>
                  By using Dragatron’s services, you consent to the practices
                  described in this Privacy Policy.
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h5" gutterBottom>
                  Scope
                </Typography>
                <Typography variant="body1" paragraph>
                  This Privacy Policy applies to all services offered by
                  Dragatron through:
                </Typography>
                <ul>
                  <li>Our websites, including [insert website name].</li>
                  <li>Mobile applications such as DragatronScripts.</li>
                  <li>
                    Partner systems, including integrations with healthcare
                    providers, pharmacies, and payment processors.
                  </li>
                  <li>
                    Any other related platforms, tools, or services provided by
                    Dragatron.
                  </li>
                </ul>

                <Divider sx={{ my: 2 }} />

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography variant="h6">1. Services Offered</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" paragraph>
                      Dragatron provides a suite of healthcare-related and other
                      services, including but not limited to:
                    </Typography>
                    <ul>
                      <li>
                        Booking Systems: Facilitating appointment scheduling for
                        healthcare providers, pharmacies, and related services.
                      </li>
                      <li>
                        Customer Applications: Including DragatronScripts for
                        prescription management, appointment tracking, and
                        vaccination schedules.
                      </li>
                      <li>
                        Third-party Integrations: Seamless connectivity with
                        systems like Medicare, eRx, and more.
                      </li>
                      <li>
                        Digital Forms: Simplifying pre-appointment documentation
                        and patient registration.
                      </li>
                      <li>
                        Prescription Management: Online and mobile management of
                        e-prescriptions and their delivery.
                      </li>
                      <li>
                        Analytics and Research Services: Offering data insights
                        for internal quality assurance and operational
                        improvements.
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                {/* Repeat similar structure for other sections */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography variant="h6">
                      2. Information We Collect
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" paragraph>
                      The types of information Dragatron collects depend on the
                      services you use. These include:
                    </Typography>
                    <Typography variant="body2">
                      <strong>Personal Information:</strong>
                    </Typography>
                    <ul>
                      <li>
                        Name, date of birth, and contact information (email,
                        phone number, address).
                      </li>
                      <li>Gender, marital status, and occupation.</li>
                      <li>
                        Health insurance details, including membership numbers.
                      </li>
                      <li>
                        Medicare, Pension, Health Care Card, or Veteran Affairs
                        numbers.
                      </li>
                    </ul>
                    <Typography variant="body2">
                      <strong>Sensitive Information:</strong>
                    </Typography>
                    <ul>
                      <li>
                        Health information as defined under the Privacy Act,
                        including medical history, diagnoses, prescriptions, and
                        vaccination records.
                      </li>
                      <li>
                        Appointment details and reasons for consultations.
                      </li>
                      <li>
                        Information on health-related conditions and treatments.
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                {/* Add more sections as needed */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Typography variant="h6">
                      10. Complaints and Disputes
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" paragraph>
                      If you have concerns about how Dragatron handles your
                      personal information:
                    </Typography>
                    <ol>
                      <li>
                        <Typography>
                          Contact our Privacy Officer at&nbsp;
                          <Link href="mailto:support@dragatron.com.au">
                            support@dragatron.com.au
                          </Link>
                          &nbsp;or&nbsp;
                          <Link href="tel:+61406213088">+61406213088</Link>.
                        </Typography>
                      </li>
                      <li>
                        If unresolved, escalate to the&nbsp;
                        <Link href="https://www.oaic.gov.au" target="_blank">
                          Office of the Australian Information Commissioner
                          (OAIC)
                        </Link>
                        .
                      </li>
                    </ol>
                    <Typography variant="body1" paragraph>
                      We will respond to complaints within&nbsp;
                      <strong>30 days</strong>.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Divider sx={{ my: 4 }} />
                <Typography variant="body2" align="center">
                  This Privacy Policy ensures transparency and compliance while
                  safeguarding your personal information. Thank you for trusting
                  Dragatron PTY LTD.
                </Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default PrivacyStatement;
