import { Box } from "@mui/material";
import CalendarHeader from "./CalendarHeaders/CalendarHeader";
import CalendarDay, { TCalendarDayProps } from "./CalendarDays/CalendarDay";
import { DatePickerProps } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useCalendar } from "../../context/CalendarContext";
import { Suspense, useEffect } from "react";
import { TSelectMonthProps } from "./CalendarHeaders/SelectMonth";
import { IDayNavigationProps } from "./CalendarDays/DayNavigation";

export type TCalendarProps = Pick<
  DatePickerProps<Moment>,
  "defaultValue" | "minDate" | "maxDate"
> &
  TCalendarDayProps &
  TSelectMonthProps &
  IDayNavigationProps & {
    loading?: boolean;
  };

const Calendar = (props: TCalendarProps) => {
  const { setCurrentMonth, setMinDate, setMaxDate, setLoading, setSelectedDate } = useCalendar();
  const {
    renderViewDay,
    defaultValue,
    minDate,
    maxDate,
    loading,
    ...calendarHeaderProps
  } = props;

  useEffect(() => {
    if (defaultValue !== null && defaultValue !== undefined) {
      setCurrentMonth(defaultValue);
      setSelectedDate(defaultValue)
    }

    if (minDate !== undefined) {
      setMinDate(minDate);
      setCurrentMonth(minDate);
    }

    if (maxDate !== undefined) {
      setMaxDate(maxDate);
    }

    if (typeof loading === "boolean") {
      setLoading(loading);
    }
  }, []);

  return (
    <Suspense fallback={<>Loading...</>}>
      <Box display="flex" flexDirection="column" gap="1rem">
        <CalendarHeader {...calendarHeaderProps} />
        <CalendarDay renderViewDay={renderViewDay} />
      </Box>
    </Suspense>
  );
};

export default Calendar;
