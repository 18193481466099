import { BackNavigation } from "@/components/new/BackNavigation";
import PageWrapper from "@/pages/PageWrapper";
import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AccountCircleOutlined,
  Diversity3Outlined,
  LocalPharmacyOutlined,
} from "@mui/icons-material";
import useApi from "@/hooks/useApi";
import { useModal } from "@/context/DialogContext";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import { useSnackbar } from "@/context/SnackbarContext";
import { useForm } from "react-hook-form";
import ClinicForm from "./ClinicForm";
import {
  IClinicAgedCareForm,
  IPatientClinicFields,
} from "@/interfaces/IAgedCare";
import { uuid } from "@/lib/utils";
import AgedCarePatientsTable from "./AgedCarePatientsTable";
import {
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import ModalSuccessAgedCare from "./ModalSuccessAgedCare";

interface IBulkUploadResponse {
  message: string;
  summary: {
    totalProcessed: number;
    successful: number;
    failed: number;
  };
  successfulPatients: Array<{
    index: number;
    clinicPatientID: string;
    firstName: string;
    lastName: string;
  }>;
  failedPatients: Array<any>;
}

function validatePatientData(data: IPatientClinicFields[]): {
  isValid: boolean;
  errors: any[];
} {
  const errors: any[] = [];

  data.forEach((patient, index) => {
    const patientErrors: { [key: string]: string } = {};

    if (!patient.firstName || patient.firstName.trim() === "") {
      patientErrors.firstName = "First name is required.";
    }

    if (!patient.dateOfBirth) {
      patientErrors.dateOfBirth = "Date of birth is required.";
    }

    if (!patient.lastName || patient.lastName.trim() === "") {
      patientErrors.lastName = "Last name is required.";
    }

    if (!patient.medicareIRN || patient.medicareIRN.trim() === "") {
      patientErrors.medicareIRN = "Medicare IRN is required.";
    }

    if (!patient.medicareNumber || patient.medicareNumber.trim() === "") {
      patientErrors.medicareNumber = "Medicare number is required.";
    }

    if (Object.keys(patientErrors).length > 0) {
      errors.push({
        index,
        clinicPatientID: patient.clinicPatientID,
        errors: patientErrors,
      });
    }
  });

  return {
    isValid: errors.length === 0,
    errors,
  };
}

const ClinicDetail = () => {
  const navigate = useNavigate();
  const { pharmacyID, clinicID } = useParams<{
    pharmacyID: string;
    clinicID: string;
  }>();
  const [loading, setLoading] = useState(false);
  const { openModal, closeModal } = useModal();
  const api = useApi();
  const { openSnackbar } = useSnackbar();
  const [patients, setPatients] = useState<IPatientClinicFields[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const clinicMethods = useForm<IClinicAgedCareForm>({
    defaultValues: {
      clinicName: "",
      address: "",
      email: "",
      phone: "",
      postcode: "",
      suburb: "",
      notes: "",
      clinicID: "",
    },
  });

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleSaveClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => {
    setPatients(patients.filter((row) => row.clinicPatientID !== id));
  };

  const handleEditClick = (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCancelClick = (id: GridRowId) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = patients.find((row) => row.clinicPatientID === id);
    if (editedRow!.isNew) {
      setPatients(patients.filter((row) => row.clinicPatientID !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel<IPatientClinicFields>) => {
    const updatedRow = { ...newRow, isNew: false };
    setPatients(
      patients.map((row) =>
        row.clinicPatientID === newRow.clinicPatientID ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSubmit = async () => {
    const isClinicValid = await clinicMethods.trigger();

    if (!isClinicValid) {
      console.info("Clinic is not passed validation ");
      return;
    }

    if (typeof pharmacyID === "string" && pharmacyID.trim().length === 0) {
      console.info("Empty pharmacy ID detected");
      return;
    }

    if (!pharmacyID) {
      console.info("pharmacy ID not initialized yet");
      return;
    }

    const patientValidationResult = validatePatientData(patients);

    if (!patientValidationResult.isValid) {
      console.info(
        "did not pass patientValidationResult ",
        patientValidationResult.errors
      );

      openSnackbar({
        severity: "error",
        message: "Patients field are required",
      });
      return;
    }

    setLoading(true);
    openModal({
      title: null,
      backdropClose: false,
      content: <ModalLoadingContent />,
    });

    try {
      const clinicData = clinicMethods.getValues();
      const formattedPatients = patients.map((patient) => ({
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateOfBirth: moment(patient.dateOfBirth).format("YYYY-MM-DD"),
        medicareNumber: patient.medicareNumber,
        medicareIRN: patient.medicareIRN,
        postcode: clinicData.postcode,
        status: "SCHEDULED",
        service: "FLU_VACCINATION",
        clinicID: clinicData.clinicID,
        pharmacyID: pharmacyID,
      }));

      const request = await api.post<IBulkUploadResponse>(
        `${process.env.REACT_APP_AGED_CARE_API}/bulkUploadPatients`,
        formattedPatients
      );

      if (request.status !== 200) {
        throw new Error(`Failed to submit: ${request.statusText}`);
      }

      closeModal();
      navigate(
        `/pharmacies/${pharmacyID}/aged-care-clinics/${clinicID}/success`
      );
    } catch (error) {
      console.error(
        "An error occurred processing aged care submissions",
        error
      );
      openSnackbar({
        severity: "error",
        message: "Failed to submit aged care data",
      });
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchClinicDetail = async () => {
      if (!pharmacyID || !clinicID) {
        console.error("Missing pharmacyID or clinicID");
        return;
      }

      setLoading(true);
      try {
        const response = await api.post(
          `${process.env.REACT_APP_AGED_CARE_API}/getClinicDetails`,
          {
            pharmacyID,
            clinicID,
          }
        );

        if (response.status === 200) {
          const clinicData = response.data;
          clinicMethods.reset({
            clinicName: clinicData.clinicName,
            address: clinicData.location || "",
            email: clinicData.email || "",
            phone: clinicData.phone || "",
            postcode: clinicData.postcode || "",
            suburb: clinicData.suburb || "",
            notes: clinicData.notes || "",
            clinicID: clinicData.clinicID,
          });
        } else {
          throw new Error("Failed to fetch clinic details");
        }
      } catch (error) {
        console.error("Error fetching clinic details:", error);
        openSnackbar({
          severity: "error",
          message: "Failed to load clinic details",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchClinicDetail();
  }, [pharmacyID, clinicID]);

  useEffect(() => {
    if (loading) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
    } else {
      closeModal();
    }
  }, [loading]);

  return (
    <PageWrapper title="Aged Care">
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "130%",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          [theme.breakpoints.between("xs", "sm")]: {
            width: "100%",
            px: "1rem",
            left: "unset",
            transform: "none",
          },
          [theme.breakpoints.between("sm", "md")]: {
            width: "100%",
            left: "unset",
            transform: "none",
          },
          [theme.breakpoints.between("md", "lg")]: {
            width: "90%",
            left: "unset",
            transform: "none",
          },
        })}
      >
        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <BackNavigation
            title="Aged Care Detail"
            description="View and edit clinic and patient details"
            onClick={() => navigate(-1)}
          />
        </Card>

        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Box mb="1rem" display="flex" alignItems="center">
            <Box display="flex" gap="1.5rem" flex={1}>
              <LocalPharmacyOutlined color="primary" />
              <Typography className="text-grey-200 font-medium">
                Clinic Details
              </Typography>
            </Box>
          </Box>
          <ClinicForm methods={clinicMethods} />
        </Card>

        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Box mb="1rem" display="flex" alignItems="center">
            <Box display="flex" gap="1.5rem" flex={1}>
              <AccountCircleOutlined color="primary" />
              <Typography className="text-grey-200 font-medium">
                Patient Details
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Button
                onClick={() => {
                  const id = uuid();
                  setPatients([
                    ...patients,
                    {
                      firstName: "",
                      dateOfBirth: null,
                      lastName: "",
                      medicareIRN: "",
                      medicareNumber: "",
                      clinicPatientID: id,
                      isNew: true,
                    },
                  ]);
                  setRowModesModel((oldModel) => ({
                    ...oldModel,
                    [id]: {
                      mode: GridRowModes.Edit,
                      fieldToFocus: "firstName",
                    },
                  }));
                }}
                variant="contained"
                startIcon={<Diversity3Outlined />}
              >
                Add Patient
              </Button>
            </Box>
          </Box>

          <AgedCarePatientsTable
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            patients={patients}
            setPatients={setPatients}
            handleCancelClick={handleCancelClick}
            handleSaveClick={handleSaveClick}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              const err = error as Error;
              openSnackbar({
                severity: "error",
                message: err.message,
              });
            }}
          />
        </Card>

        <Card
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: "2rem",
            [theme.breakpoints.between("xs", "sm")]: {
              p: "1rem",
            },
          })}
        >
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Card>
      </Box>
    </PageWrapper>
  );
};

export default ClinicDetail;
