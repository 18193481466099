import { IVaccinationScreeningInput } from "@/interfaces/IVaccinations";

export const screeningQuestions: Array<
  [keyof IVaccinationScreeningInput, string]
> = [
  ["isUnwellToday", "Are you unwell today?"],
  [
    "hasImmunityLoweringDisease",
    "Do you have a disease that lowers immunity (e.g., leukaemia, cancer, HIV)?",
  ],
  [
    "receivingImmunityLoweringTreatment",
    "Are you currently receiving treatment that lowers immunity (e.g., oral steroid medicines like cortisone and prednisone, DMARDs, other biologics/monoclonal antibodies, radiotherapy, chemotherapy)?",
  ],
  [
    "isInfantOfMotherWithImmunosuppressiveTherapy",
    "Is the person to be vaccinated an infant of a mother who received highly immunosuppressive therapy during pregnancy?",
  ],
  [
    "hadSevereReactionToVaccine",
    "Have you had a severe reaction following any vaccine?",
  ],
  ["hasSevereAllergies", "Do you have any severe allergies (to anything)?"],
  [
    "receivedVaccineInPastMonth",
    "Have you received any vaccine in the past month?",
  ],
  [
    "receivedImmunoglobulinOrBloodProduct",
    "Have you had an injection of immunoglobulin or received any blood products or a whole-blood transfusion within the past year?",
  ],
  ["isPregnant", "Are you pregnant?"],
  [
    "hasGuillainBarreSyndrome",
    "Do you have a history of Guillain-Barré syndrome?",
  ],
  ["wasPretermInfant", "Were you a preterm infant?"],
  ["hasSevereOrChronicIllness", "Do you have a severe or chronic illness?"],
  ["hasBleedingDisorder", "Do you have a bleeding disorder?"],
  [
    "isAboriginalOrTorresStraitIslander",
    "Do you identify as an Aboriginal or Torres Strait Islander person?",
  ],
  ["doesNotHaveFunctioningSpleen", "Do you not have a functioning spleen?"],
  [
    "planningPregnancyOrParenthood",
    "Are you planning a pregnancy or anticipating parenthood?",
  ],
  [
    "isParentGrandparentOrCarerOfInfant",
    "Are you a parent, grandparent, or carer of an infant ≤6 months of age?",
  ],
  [
    "livesWithPersonWithImmunityLoweringCondition",
    "Do you live with someone who has a disease that lowers immunity or someone who is receiving treatment that lowers immunity?",
  ],
  ["planningTravel", "Are you planning travel?"],
  [
    "hasOccupationOrLifestyleRequiringVaccination",
    "Do you have an occupation or lifestyle factor(s) for which vaccination may be needed?",
  ],
];
