import {
  Box,
  BoxProps,
  CircularProgress,
  FormControl,
  FormControlProps,
  InputAdornment,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  Skeleton,
  Typography,
} from "@mui/material";
import moment, { Moment } from "moment";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useCalendar } from "../../../context/CalendarContext";
import { dateLib } from "../../../lib/_calendar";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import { nanoid } from "nanoid";

interface ISlotsProps {
  formControlProps?: FormControlProps<"div">;
  selectProps?: SelectProps<string>;
  menuItemProps?: Pick<MenuItemProps, "sx">;
  containerSelectMonthSlotProps?: BoxProps;
}

export interface TSelectMonthProps extends ISlotsProps {}

const SelectMonth = (props: TSelectMonthProps) => {
  const {
    formControlProps,
    selectProps,
    menuItemProps,
    containerSelectMonthSlotProps,
  } = props;

  const { currentMonth, onMonthChange, disablePast, loading } = useCalendar();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  // unnecessary re render for the month, if the year is not changed
  const months = useMemo(() => {
    const firstMonthInAyear = currentMonth
      .clone()
      .startOf("year")
      .subtract(1, "month");
    const lastMonthInAyear = currentMonth.clone().endOf("year");
    const months: Array<{
      date: Moment;
      disable: boolean;
    }> = [];

    const disablePastIsOn = disablePast !== undefined && disablePast === true;
    while (firstMonthInAyear.isBefore(lastMonthInAyear, "month")) {
      const date = firstMonthInAyear.add(1, "month").clone();
      months.push({
        date,
        disable: disablePastIsOn && date.isBefore(dateLib().clone(), "month"),
      });
    }

    return months;
  }, [currentMonth.year()]);

  // selction control
  const handleChange = (event: SelectChangeEvent, _child: ReactNode) => {
    const [monthName, year] = event.target.value.split(" ");
    const month = moment.months().findIndex((month) => monthName === month);

    const selectedMonth = currentMonth
      .clone()
      .set("month", month)
      .set("year", Number(year));

    const direction = selectedMonth.isBefore(currentMonth, "month")
      ? "left"
      : "right";

    onMonthChange(selectedMonth, direction);
    setSelectedMonth(selectedMonth);
  };

  // update view on month change from day navigation
  useEffect(() => {
    setSelectedMonth(currentMonth);
  }, [currentMonth.month()]);

  if (loading) {
    return (
      <Typography variant="h3">
        <Skeleton
          sx={{
            minWidth: 200,
          }}
        />
      </Typography>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      {...containerSelectMonthSlotProps}
    >
      <FormControl sx={{ m: 1, minWidth: 120 }} {...formControlProps}>
        <Select
          {...selectProps}
          value={selectedMonth.format("MMMM YYYY")}
          onChange={handleChange}
          displayEmpty
          fullWidth
          inputProps={{ "aria-label": "Select Month" }}
        >
          {loading ? (
            <CircularProgress key={nanoid()} size={24} />
          ) : (
            months.map((month) => (
              <MenuItem
                {...menuItemProps}
                disabled={month.disable}
                value={month.date.format("MMMM YYYY")}
                id={month.date.unix().toString()}
                key={month.date.format("MMMM YYYY")}
              >
                {month.date.format("MMMM YYYY")}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectMonth;
