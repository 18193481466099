import { DeleteForeverOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

interface ISignaturePadProps {
  onClear?: SignatureCanvas["clear"];
  onEndSigned?: SignatureCanvas.ReactSignatureCanvasProps["onEnd"];
  isSigned?: boolean;
  hideClear?: boolean;
}

const SignaturePad = forwardRef<SignatureCanvas, ISignaturePadProps>(
  ({ onClear, onEndSigned, isSigned, hideClear }, ref) => {
    const sigCanvas = useRef<SignatureCanvas | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

    // State to control the visibility of the placeholder message
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    const clearSignature = () => {
      if (sigCanvas.current) {
        sigCanvas.current.clear();
      }
      if (typeof onClear === "function") {
        onClear();
      }
      setShowPlaceholder(true); // Show placeholder again after clearing
    };

    // Hide placeholder when the user starts signing
    const handleEndSigned = (event: MouseEvent) => {
      setShowPlaceholder(false);
      if (typeof onEndSigned === "function") {
        onEndSigned(event);
      }
    };

    useEffect(() => {
      setShowPlaceholder(!!isSigned);
    }, [isSigned]);

    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <SignatureCanvas
          ref={ref ? ref : sigCanvas}
          onEnd={handleEndSigned}
          penColor="black"
          canvasProps={{
            width: isMobile ? 320 : 500,
            height: 200,
            className: "border-solid border-[1px] drop-shadow-sm rounded-md",
          }}
        />

        {/* Placeholder overlay */}
        {showPlaceholder && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              pointerEvents: "none", // Prevents overlay from interfering with signing
              borderRadius: "4px",
            }}
          >
            <Typography variant="subtitle1" color="textSecondary">
              Please sign here
            </Typography>
          </Box>
        )}

        {/* Clear button */}
        {!hideClear && (
          <IconButton
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              zIndex: 100,
            }}
            title="Clear Signature"
            color="error"
            onClick={clearSignature}
            disabled={!!isSigned}
          >
            <DeleteForeverOutlined />
          </IconButton>
        )}
      </Box>
    );
  }
);

export default SignaturePad;
