import { DialogProps } from "@mui/material";
import React, { createContext, useState, ReactNode } from "react";
import GlobalModal from "../components/GlobalModal";

interface ModalContextProps
  extends Omit<DialogProps, "open" | "title" | "content"> {
  isOpen: boolean;
  backdropClose: boolean;
  title: string | ReactNode;
  openModal: (props: IOpenModalProps) => void;
  closeModal: () => void;
  content: ReactNode | null;
}

interface IOpenModalProps
  extends Omit<DialogProps, "open" | "title" | "content"> {
  title: string | ReactNode;
  backdropClose?: boolean;
  content: ReactNode | null;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<IOpenModalProps["content"]>(null);
  const [title, setTitle] = useState<ReactNode | string>("");
  const [dialogProps, setDialogProps] = useState<DialogProps | {}>({});
  const [backdropClose, setBackdropClose] = useState(true);

  const openModal = ({
    content,
    title,
    backdropClose,
    ...rest
  }: IOpenModalProps) => {
    setContent(content);
    setTitle(title);
    setDialogProps(rest);
    setBackdropClose(backdropClose ?? true);

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setContent(null);
    setTitle("");
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        content,
        openModal,
        closeModal,
        backdropClose,
        ...dialogProps,
        title,
      }}
    >
      {children}
      <GlobalModal />
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
