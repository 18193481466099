import { screeningQuestions } from "@/constant/screenings";
import ScreeningQusetion from "./ScreeningQusetion";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IVaccinationScreeningInput } from "@/interfaces/IVaccinations";
import { uuid } from "@/lib/utils";
import { IBooking } from "@/interfaces/IBooking";
import { IPatientAppointment } from "@/interfaces/IPatientAppointment";
import { useModal } from "@/context/DialogContext";
import { useRef, useState } from "react";
import ModalConfirmationSubmitScreening from "./ModalConfirmationSubmitScreening";
import SignatureCanvas from "react-signature-canvas";
import { dateLib } from "@/lib/_calendar";
import { IService } from "@/interfaces/IService";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { useNavigate, useParams } from "react-router-dom";

type TScreeningFormProps = {
  defaultValues?: TScreeningForm;
  booking?: IBooking;
  patient?: IPatientAppointment;
  service?: IService;
};

export type TScreeningForm = Partial<IVaccinationScreeningInput> & {
  customerSignature?: string;
};

export interface ICustomerCheckinsPayload extends TScreeningForm {
  id: string;
  pharmacyID: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  phoneNumber: string;
  email: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  date: string;
  time: string;
  service: string;
  status: string;
  bookingID: string;
}

const ScreeningForm = ({
  defaultValues,
  booking,
  patient,
  service,
}: TScreeningFormProps) => {
  const sigCanvasRef = useRef<SignatureCanvas | null>(null);
  const { bookingID } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const shouldHideConsent = useMediaQuery(
    theme.breakpoints.between("xs", "lg")
  );

  const [isConfirmed, setIsConfirmed] = useState(false);

  const methods = useForm<TScreeningForm>({
    defaultValues: defaultValues
      ? defaultValues
      : {
          isUnwellToday: undefined,
          hasImmunityLoweringDisease: undefined,
          receivingImmunityLoweringTreatment: undefined,
          isInfantOfMotherWithImmunosuppressiveTherapy: undefined,
          hadSevereReactionToVaccine: undefined,
          hasSevereAllergies: undefined,
          receivedVaccineInPastMonth: undefined,
          receivedImmunoglobulinOrBloodProduct: undefined,
          isPregnant: undefined,
          hasGuillainBarreSyndrome: undefined,
          wasPretermInfant: undefined,
          hasSevereOrChronicIllness: undefined,
          hasBleedingDisorder: undefined,
          isAboriginalOrTorresStraitIslander: undefined,
          doesNotHaveFunctioningSpleen: undefined,
          planningPregnancyOrParenthood: undefined,
          isParentGrandparentOrCarerOfInfant: undefined,
          livesWithPersonWithImmunityLoweringCondition: undefined,
          planningTravel: undefined,
          hasOccupationOrLifestyleRequiringVaccination: undefined,
          customerSignature: "",
        },
  });

  const { handleSubmit, control, setValue, watch } = methods;
  const { openModal, closeModal } = useModal();
  const { updateBooking } = useReservationEndpoint();

  const updateAppointment = async (data: ICustomerCheckinsPayload) => {
    try {
      const endpoint =
        `${process.env.REACT_APP_CUSTOMER_CHECKIN_URL}/createCheckin`;

      const request = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          operation: "CREATE",
          ...data,
        }),
      });

      const response = await request.json();

      if (request.status !== 200) {
        throw new Error(response);
      }

      const requestUpdateBooking = await updateBooking({
        bookingID: booking?.bookingID || bookingID || "",
        IsAssessmentCompleted: true,
      });
      console.log(requestUpdateBooking, "requestUpdateBooking");

      closeModal();
      navigate("success");
    } catch (error) {
      console.error("Error submiting screening answer");
    }
  };

  const onSubmitForm = async (data: TScreeningForm) => {
    try {
      const payload: ICustomerCheckinsPayload = {
        id: uuid(),
        pharmacyID: booking?.pharmacyID || "",
        firstName: booking?.patient.firstName || "",
        lastName: booking?.patient.lastName || "",
        dateOfBirth: booking?.patient.dateOfBirth || "",
        gender: booking?.patient.gender || "",
        address: booking?.patient.addressLineOne || "",
        suburb: booking?.patient.locality || "",
        state: booking?.patient.state || "",
        postcode: booking?.patient.postCode || "",
        date: dateLib(booking?.timeslot).format("YYYY-MM-DD") || "",
        time: dateLib().format("HH:MM:ss"),
        service: service?.serviceName || "",
        status: "CheckedIn",
        bookingID: booking?.bookingID || "",
        phoneNumber: booking?.patient.phoneNumber || "",
        email: booking?.patient.emailAddress || "",
        ...data,
      };
      console.log(payload);

      openModal({
        content: (
          <ModalConfirmationSubmitScreening
            onOk={async (customerSignature) =>
              updateAppointment({
                ...payload,
                customerSignature: customerSignature,
              })
            }
            signaturePadRef={sigCanvasRef}
          />
        ),
        title: null,
        maxWidth: "xs",
        fullWidth: true,
        backdropClose: false,
      });
    } catch (error) {
      console.error("Error submiting screenings answer ", error);
    }
  };

  // Watch all form values
  const formValues = watch();

  // Check if any of the values are undefined
  const isAnyValueUndefined = Object.values(formValues).some(
    (value) => value === undefined
  );

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
        {screeningQuestions?.map((question, index) => {
          return (
            <Controller
              control={control}
              key={question[0]}
              name={question[0]}
              render={({ field }) => {
                const { ref, ...restField } = field;

                return (
                  <ScreeningQusetion
                    index={index}
                    {...restField}
                    question={question}
                  />
                );
              }}
            />
          );
        })}
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          my={"1rem"}
        >
          {!shouldHideConsent && (
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                [theme.breakpoints.between("xs", "sm")]: {
                  gap: "1rem",
                },
              })}
            >
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={isConfirmed}
                      value={isConfirmed}
                      onChange={(_event, checked) => setIsConfirmed(checked)}
                    />
                  }
                  label="By submitting this form, I confirm that my answers are accurate to the best of my knowledge and I consent to receiving the vaccination"
                />
              </Box>
              {/* <Typography gutterBottom>
              By submitting this form, I confirm that my answers are accurate to
              the best of my knowledge and I consent to receiving the
              vaccination
            </Typography>
            <SignaturePad
              onEndSigned={() => setValue("customerSignature", getSign())}
              onClear={handleClearSignature}
              ref={sigCanvasRef}
              isSigned={isNotSigned}
            /> */}
            </Box>
          )}
        </Box>
        <Box display="flex" width="100%" justifyContent="center">
          <Button
            disabled={
              shouldHideConsent
                ? !shouldHideConsent || isAnyValueUndefined
                : !isConfirmed || isAnyValueUndefined
            }
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default ScreeningForm;
