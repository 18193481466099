import {
  Controller,
  UseFormSetValue,
  Control,
  FieldErrors,
} from "react-hook-form";
import {
  Box,
  Container,
  ContainerProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  getSuburbsFromPlacesResult,
  getStateFromPlacesResult,
  getPostalCodeFromPlacesResult,
} from "../../../lib/utils";
import moment, { Moment } from "moment";
import DatePickerMUI from "../../../components/DatePickerMUI/DatePickerMUI";
import GooglePlacesInput from "../../../components/GooglePlacesInput/GooglePlacesInput";

interface PatientDetailFormProps extends ContainerProps {
  control: Control<IAppointmentForm, any>;
  setValue: UseFormSetValue<IAppointmentForm>;
  errors: FieldErrors<IAppointmentForm>;
}

export interface IPatientFields {
  patientID: string;
  fullName: string;
  dateOfBirth: Moment | null;
  gender: string;
  addressLineOne: string;
  emailAddress: string;
  phoneNumber: string;
  suburb: string;
  state: string;
  postCode: string;
}

export interface IAppointmentForm extends IPatientFields {
  notes?: string;
}

const PatientDetailForm = ({
  control,
  setValue,
  errors,
  ...containerProps
}: PatientDetailFormProps) => {
  const { sx, ...restProps } = containerProps;

  return (
    <Container
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        px: 0,
        ...(typeof sx === "function" ? sx(theme) : { sx }),
      })} // Ensure return type matches SystemStyleObject<Theme>
      {...restProps}
    >
      <div className="w-full flex flex-col gap-2">
        <Controller
          name="fullName"
          control={control}
          rules={{
            required: "Fullname is required",
            validate: (value) =>
              value.trim().length !== 0 || "Empty value are not allowed",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Full Name"
              fullWidth
              id="name"
              autoComplete="on"
              InputLabelProps={{
                htmlFor: "name",
              }}
              placeholder="eg: John Doe"
              error={!!errors.fullName}
              helperText={errors.fullName?.message}
            />
          )}
        />
      </div>
      <Box
        sx={(theme) => ({
          width: "100%",
          display: "flex",
          gap: "1rem",
          [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: "column",
          },
        })}
        className=" w-full flex gap-4"
      >
        <div className="w-full flex flex-col gap-2">
          <Controller
            name="dateOfBirth"
            rules={{
              required: "Date of birth is required",
            }}
            control={control}
            render={({ field, fieldState }) => {
              const { value, onChange, ...rest } = field;
              return (
                <Box
                  position="relative"
                  sx={{
                    width: "100%",
                  }}
                >
                  <DatePickerMUI
                    {...rest}
                    label="Date Of Birth"
                    value={moment(value)}
                    format="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        error: !!fieldState.error,
                        helperText: fieldState.error?.message,
                      },
                      field: {
                        sx: {
                          width: "100%",
                        },
                      },
                    }}
                    disableFuture
                    onChange={(date, _context) => {
                      if (date) {
                        // onChange(date.toISOString()?.split("T")[0] || "");
                        onChange({
                          value,
                          name: "dateOfBirth",
                        });
                        setValue("dateOfBirth", date);
                      }
                    }}
                  />
                </Box>
              );
            }}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <Controller
            name="gender"
            control={control}
            rules={{
              required: "Gender is required",
              validate: (value) =>
                value.trim().length !== 0 || "Empty value are not allowed",
            }}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error}>
                <InputLabel id="gender">Gender</InputLabel>
                <Select {...field} label="Gender" labelId="gender">
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                  <MenuItem value="Prefer not to say">
                    Prefer not to say
                  </MenuItem>
                </Select>
                {fieldState.error && (
                  <FormHelperText>{fieldState.error.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </div>
      </Box>
      <div className="w-full flex flex-col gap-4">
        <Controller
          name="addressLineOne"
          rules={{
            required: "Address is required",
            validate: (value) =>
              value.trim().length !== 0 || "Empty value are not allowed",
          }}
          control={control}
          render={({ field }) => {
            const { ref, onChange, ...rest } = field;
            return (
              <GooglePlacesInput
                {...rest}
                autoComplete="new-password"
                label="Address"
                size="medium"
                helperText={errors.addressLineOne?.message}
                onChange={(e) => {
                  const { inputType } = e.nativeEvent as InputEvent;

                  if (
                    inputType === "insertText" ||
                    inputType === "deleteContentBackward"
                  ) {
                    onChange(e.target.value);
                  }
                }}
                error={!!errors.addressLineOne}
                onPlaceSelected={(place) => {
                  if (place && place.formatted_address) {
                    const suburbs = getSuburbsFromPlacesResult(place);
                    const state = getStateFromPlacesResult(place);
                    const postCode = getPostalCodeFromPlacesResult(place);
                    setValue("addressLineOne", place.formatted_address || "");
                    setValue("suburb", suburbs || "");
                    setValue("state", state || "");
                    setValue("postCode", postCode || "");

                    onChange(place.formatted_address);
                  }
                }}
              />
            );
          }}
        />
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",

            [theme.breakpoints.between("xs", "sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Controller
            name="state"
            rules={{
              required: "State is required",
              validate: (value) =>
                value.trim().length !== 0 || "Empty value are not allowed",
            }}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label="State"
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
          <Controller
            name="suburb"
            rules={{
              required: "Suburb is required",
              validate: (value) =>
                value.trim().length !== 0 || "Empty value are not allowed",
            }}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label="Suburb"
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
          <Controller
            name="postCode"
            rules={{
              required: "Post code is required",
              validate: (value) =>
                value.trim().length !== 0 || "Empty value are not allowed",
            }}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label="Post Code"
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Box>
      </div>
      <div className="xl:w-full  lg:w-full  md:w-full xl:flex lg:flex md:flex xl:gap-4 lg:gap-4 md:gap-4 xl:flex-row lg:flex-row md:flex-col w-full flex gap-4 flex-col">
        <div className="w-full flex flex-col gap-2">
          <Controller
            name="emailAddress"
            control={control}
            rules={{
              required: "Email address is required",
              validate: (value) =>
                value.trim().length !== 0 || "Empty value are not allowed",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                helperText={errors.emailAddress?.message}
                error={!!errors.emailAddress}
                label="Email"
                autoComplete="on"
                type="email"
                placeholder="eg: john.doe@mail.com.au"
              />
            )}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          <Controller
            name="phoneNumber"
            rules={{
              required: "Phone number is required",
              validate: (value) =>
                value.trim().length !== 0 || "Empty value are not allowed",
            }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                name="phoneNumber"
                helperText={errors.phoneNumber?.message}
                error={!!errors.phoneNumber}
                id="phoneNumber"
                InputLabelProps={{
                  htmlFor: "phoneNumber",
                }}
                label="Contact Number"
                fullWidth
                autoComplete="off"
                placeholder="eg: +6123456789"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="outlined-multiline-static"
              label="Notes"
              multiline
              fullWidth
              rows={4}
              placeholder="Is there anything we should know?"
            />
          )}
        />
      </div>
    </Container>
  );
};

export default PatientDetailForm;
