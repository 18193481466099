import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

export enum GRAPHQL_DESTINATION {
  DEFAULT = "default",
  AIR_API = "air-api",
  // add another if needed
}
// Replace 'your-api-key' with your actual API key
const apiKey = process.env.REACT_APP_HEALTHCARE_API_KEY || "da2-5thbnddsgbd5rgtbl77ioj5wsi";
const API_URL = process.env.REACT_APP_HEALTHCARE_API_URL || "https://b4pvcaopyve5hphnzv4krtse6e.appsync-api.ap-southeast-2.amazonaws.com/graphql";
// HTTP link to connect to your GraphQL endpoint
const httpLinkHealthcareReservation = new HttpLink({
  uri: API_URL, // Replace with your GraphQL API endpoint
  headers: {
    "x-api-key": apiKey,
  },
});

const httpLinkAirApi = new HttpLink({
  uri: process.env.REACT_APP_AIR_API_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_AIR_API_KEY || "",
  },
});

const link = ApolloLink.split(
  (operation) =>
    operation.getContext().clientName === GRAPHQL_DESTINATION.AIR_API,
  httpLinkAirApi, // if the test function returns true, the request will be forwarded to this link
  httpLinkHealthcareReservation // if the test function returns false, the request will be forwarded to this link
  // add more split if needed
);

// Create the Apollo Client with the httpLinkAirApi and httpLinkHealthcareReservation
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   uri: "https://your-graphql-api-endpoint.com/graphql", // Replace with your GraphQL API endpoint
//   cache: new InMemoryCache(),
// });

export default apolloClient;
