import { Typography } from "@mui/material";
import { ArrowIcon } from "../Icons/Icons";
import { useStepper } from "../../context/StepperProvider";

type BackButtonProps = {
  title: string;
  description: string;
  onClick?: () => void;
};

export const BackNavigation = (props: BackButtonProps) => {
  const { prevStep } = useStepper();
  return (
    <div className="lg:flex xl:flex  xl:gap-4 lg:gap-4 flex gap-4">
      <span
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (typeof props.onClick === "function") {
            props.onClick();
            return;
          }

          prevStep();
        }}
      >
        <div className="xl:w-7 lg:w-7 md:w-7 w-8 xl:h-7 lg:h-7 md:h-7 h-8 border-primary border-solid border-[.07rem] rounded-md flex items-center justify-center shadow-sm shadow-grey-400/40 hover:shadow-md hover:shadow-primary/20 cursor-pointer">
          <ArrowIcon className="fill-primary rotate-90" />
        </div>
      </span>
      <span className="lg:flex xl:flex lg:flex-col xl:flex-col lg:justify-start xl:justify-start lg:items-start xl:items-start lg:w-full xl:w-full flex flex-col gap-2 ">
        <Typography
          variant="body1"
          fontWeight="bold"
        >
          {props.title}
        </Typography>
        <Typography variant="body2" className=" text-grey-400">
          {props.description}
        </Typography>
      </span>
    </div>
  );
};
