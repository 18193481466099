import { CreateBookingMutationVariables } from "@/codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import ModalLoadingContent from "@/components/ModalLoadingContent/ModalLoadingContent";
import { BackNavigation } from "@/components/new/BackNavigation";
import { steeperPath } from "@/constant/stepper";
import { useApp } from "@/context/AppContext";
import { useModal } from "@/context/DialogContext";
import useApi from "@/hooks/useApi";
import { BOOKING_STATUS, IBooking } from "@/interfaces/IBooking";
import { ICreatePatientPayload } from "@/interfaces/IPatient";
import { extractFullname, uuid } from "@/lib/utils";
import PageWrapper from "@/pages/PageWrapper";
import AppoinmentDetail from "@/pages/Reservations/ReviewAppointment/AppointmentDetail";
import ModalReservationCreated from "@/pages/Reservations/ReviewAppointment/ModalReservationCreated";
import ModalReservationFailed from "@/pages/Reservations/ReviewAppointment/ModalReservationFailed";
import PatientDetailForm, {
  IAppointmentForm,
} from "@/pages/Reservations/ReviewAppointment/PatientDetailForm";
import { Card, Typography, Button, CircularProgress, Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";

type Props = {};

const PharmacyReviewAppointmentPage = (props: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const serviceID = params.serviceID;
  const pharmacyID = params.pharmacyID;
  const {
    selectedAvailability,
    selectedPharmacy,
    selectedService,
    prevReservation,
  } = useApp();
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const api = useApi();

  const methods = useForm<IAppointmentForm>({
    defaultValues: {
      fullName: "",
      dateOfBirth: "",
      gender: "",
      addressLineOne: "",
      emailAddress: "",
      phoneNumber: "",
      suburb: "",
      postCode: "",
      state: "",
    },
  });

  const {
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (loading) {
      openModal({
        title: null,
        backdropClose: false,
        content: <ModalLoadingContent />,
      });
    }
  }, [loading]);

  React.useEffect(() => {
    if (prevReservation) {
      const booking = prevReservation as IBooking;

      if (booking) {
        setValue(
          "fullName",
          `${booking.patient.firstName} ${booking.patient.lastName}`
        );
        setValue("dateOfBirth", moment(booking.patient.dateOfBirth));
        setValue("gender", booking.patient.gender);
        setValue("addressLineOne", booking.patient.addressLineOne);
        setValue("emailAddress", booking.patient.emailAddress);
        setValue("phoneNumber", booking.patient.phoneNumber);
      }
    }
  }, [prevReservation]);

  const handleSubmit = async (data: IAppointmentForm) => {
    setLoading(true);
    try {
      // create patient
      const { fullName, suburb, dateOfBirth, notes, ...restPatientData } = data;
      const fullNames = extractFullname(fullName);
      const id = uuid();
      const patientPayload: ICreatePatientPayload = {
        lastName: fullNames?.lastName || "",
        middleName: fullNames?.middleName || "",
        firstName: fullNames?.firstName,
        // patientID: id,
        pharmacyID: selectedPharmacy?.pharmacyID,
        createdDate: moment().format("YYYY-MM-DD"),
        dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
        // dateOfBirth: moment(dateOfBirth).toISOString(),
        ...restPatientData,
      };

      // // console.log(patientPayload, "patientPayload");

      // const savedPatient = await savePatient({
      //   variables: {
      //     input: patientPayload,
      //   },
      // });

      // const { data: savePatientResponse } = savedPatient;
      // const patient = savePatientResponse?.createPatient;

      // if (!patient) {
      //   const error = new Error("Error saving patient");
      //   error.name = "ErrorSavingPatientException";
      //   throw error;
      // }

      // dummy create booking
      // const bookingPayload: CreateBookingMutationVariables["input"] = {
      //   availabilityID: selectedAvailability!.availabilityID,
      //   bookingID: uuid(),
      //   durationInSeconds: selectedAvailability!.durationInSeconds,
      //   patientID: patientPayload.patientID,
      //   pharmacyID: selectedPharmacy!.pharmacyID!,
      //   serviceId: selectedService!.serviceId!,
      //   timeslot: selectedAvailability!.timeslot!,
      //   status: BOOKING_STATUS.CREATED,
      //   BookingMonth:
      //     moment.months()[moment(selectedAvailability!.timeslot!).month()],
      //   createdDate: moment().toISOString(),
      //   patient: {
      //     patientID: patientPayload.patientID,
      //     pharmacyID: selectedPharmacy!.pharmacyID!,
      //     phoneNumber: patientPayload.phoneNumber,
      //     firstName: patientPayload.firstName,
      //     lastName: patientPayload.lastName,
      //     dateOfBirth: patientPayload.dateOfBirth,
      //     addressLineOne: patientPayload.addressLineOne,
      //     emailAddress: patientPayload.emailAddress,
      //     gender: patientPayload.gender,
      //   },
      // };

      // create booking
      const bookingID = uuid();
      const bookingPayload: CreateBookingMutationVariables["input"] = {
        availabilityID: selectedAvailability!.availabilityID,
        bookingID: bookingID,
        durationInSeconds: selectedAvailability!.durationInSeconds,
        patientID: patientPayload.patientID,
        pharmacyID: selectedPharmacy!.pharmacyID!,
        serviceId: selectedService!.serviceId!,
        timeslot: selectedAvailability!.timeslot!,
        status: BOOKING_STATUS.CONFIRMED,
        BookingMonth:
          moment.months()[moment(selectedAvailability!.timeslot!).month()],
        createdDate: moment().toISOString(),
        notes,
        patient: {
          patientID: patientPayload.patientID,
          pharmacyID: selectedPharmacy!.pharmacyID!,
          phoneNumber: patientPayload.phoneNumber,
          firstName: patientPayload.firstName,
          lastName: patientPayload.lastName,
          dateOfBirth: patientPayload.dateOfBirth,
          addressLineOne: patientPayload.addressLineOne,
          emailAddress: patientPayload.emailAddress,
          gender: patientPayload.gender,
          state: patientPayload.state,
          locality: suburb,
          postCode: patientPayload.postCode,
          createdDate: moment().tz("Australia/Sydney").toISOString(),
        },
        IsAssessmentCompleted: false,
      };

      if (selectedPharmacy?.allowPayment || selectedService?.allowPayment) {
        bookingPayload.payment = {
          channel: "STRIPE",
          clientSecret: null,
          status: BOOKING_STATUS.CREATED,
          token: bookingID,
        };
      }

      // console.info(bookingPayload, 'bookingPayload')

      const response = await api.post("/createAppointment", {
        ...bookingPayload,
        source: "WEB",
      });

      if (response.status !== 200) {
        throw new Error(response.data);
      }

      // console.log(bookingPayload, "bookingPayload");
      // console.log(selectedPharmacy, "selectedPharmacy");

      // const savedBooking = await saveBooking({
      //   variables: {
      //     input: bookingPayload,
      //   },
      // });

      // const { data: saveBookingResponse } = savedBooking;
      // const booking = saveBookingResponse?.createBookings;

      // if (!booking) {
      //   const error = new Error("Error saving booking");
      //   error.name = "ErrorSavingBookingException";
      //   throw error;
      // }

      // setPrevReservation(booking);

      if (selectedPharmacy?.allowPayment || selectedService?.allowPayment) {
        //   const request = await api.post("/payment", {
        //     type: "CREATE_RESERVATION_INTENT",
        //     bookingID: booking.bookingID,
        //   });

        //   if (request.status === 200) {
        //     await updateBooking({
        //       variables: {
        //         input: {
        //           bookingID: booking.bookingID,
        //           payment: {
        //             channel: booking.payment?.channel,
        //             clientSecret: request.data.client_secret,
        //             status: booking.payment?.status,
        //             token: booking.bookingID,
        //           },
        //         },
        //       },
        //     });

        // close loading modal first
        closeModal();
        navigate(
          `/pharmacies/${pharmacyID}/appointment/${serviceID}/${bookingPayload.availabilityID}/${bookingPayload.bookingID}/${steeperPath.PAYMENT_DETAIL}`
        );
        // }
      } else {
        //   // const dummyBookingId = "9ad80bf9-9c29-4004-a319-28a5fe0f775d";
        //   // const dummyBookingId = "6f4ba905-b752-4f75-9185-bd3b39c7dfa";
        //   await api
        //     .post("/EmailService", {
        //       bookingID: booking.bookingID,
        //       // bookingID: dummyBookingId,
        //     })
        //     .then((response) => console.info(response.data));

        // close loading modal first
        closeModal();

        openModal({
          title: null,
          fullWidth: true,
          backdropClose: false,
          content: (
            <ModalReservationCreated bookingId={bookingPayload.bookingID} />
          ),
        });
      }
    } catch (error) {
      console.error("error creating reservation ", error);
      // close loading modal first
      closeModal();

      openModal({
        title: null,
        fullWidth: true,
        backdropClose: false,
        content: <ModalReservationFailed error={error as Error} />,
      });
    }
  };

  return (
    <PageWrapper title="Review Appointment">
      <FormProvider {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={methods.handleSubmit(handleSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Card
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                p: "1rem",
              },
            })}
          >
            <BackNavigation
              title="Review Your Details"
              description="Please make sure your details belong to the individual visiting clinic"
              onClick={() => {
                navigate(
                  `/pharmacies/${pharmacyID}/appointment/${serviceID}/${steeperPath.SELECT_TIME}`
                );
              }}
            />

            <PatientDetailForm
              errors={errors}
              setValue={methods.setValue}
              control={methods.control}
            />
          </Card>
          <Card
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                p: "1rem",
              },
            })}
          >
            <AppoinmentDetail />
          </Card>

          <Card
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: "2rem",
              [theme.breakpoints.between("xs", "sm")]: {
                p: "1rem",
              },
            })}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Typography className="text-grey-200">Declaration</Typography>
              <div className="flex flex-col gap-4 text-justify">
                <Typography>
                  By Clicking Continue, you agree to the&nbsp;
                  <Link to="/privacy-statement" className="text-primary">
                    Privacy Statement
                  </Link>
                  &nbsp; and&nbsp;
                  <Link to="/terms-conditions" className="text-primary">
                    Terms and Use
                  </Link>
                  &nbsp; of the Health Appointment System.
                </Typography>
                <Button
                  fullWidth
                  type="submit"
                  size="large"
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Typography>Continue</Typography>
                  )}
                </Button>
              </div>
            </Box>
          </Card>
        </Box>
      </FormProvider>
    </PageWrapper>
  );
};

export default PharmacyReviewAppointmentPage;
