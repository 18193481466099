import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookExecOptions,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { MUTATION_UPDATE_AVAILABILITY } from "../codegens/HEALTCARE-RESERVATION/mutations";
import {
  Exact,
  GetAvailabilityQuery,
  GetAvailabilityQueryVariables,
  UpdatePharmacyAvailabilityInput,
  UpdatePharmacyAvailabilityMutation,
  UpdatePharmacyAvailabilityMutationVariables,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { GET_AVAILABILITY } from "@/codegens/HEALTCARE-RESERVATION/queries";

export const useAvailabilities = () => {
  const [
    updatePharmacyAvailabilityMutation,
    {
      error: errorUpdateAvailability,
      loading: loadingUpdateAvailability,
      data: responseUpdateAvailability,
    },
  ] = useMutation<
    UpdatePharmacyAvailabilityMutation,
    UpdatePharmacyAvailabilityMutationVariables
  >(MUTATION_UPDATE_AVAILABILITY);

  const [
    getAvailabilityQuery,
    { error: errorGetAvailability, loading: loadingGetAvailability },
  ] = useLazyQuery<GetAvailabilityQuery, GetAvailabilityQueryVariables>(
    GET_AVAILABILITY
  );

  const updateAvailability = (
    options: MutationFunctionOptions<
      UpdatePharmacyAvailabilityMutation,
      Exact<{
        input: UpdatePharmacyAvailabilityInput;
      }>,
      DefaultContext,
      ApolloCache<any>
    >
  ) => {
    return updatePharmacyAvailabilityMutation({
      ...options,
    });
  };

  const getAvailability = (
    options?: Partial<
      LazyQueryHookExecOptions<
        GetAvailabilityQuery,
        Exact<{
          availabilityID: string;
        }>
      >
    >
  ) => {
    return getAvailabilityQuery({
      ...options,
    });
  };

  return {
    updateAvailability,
    errorUpdateAvailability,
    loadingUpdateAvailability,
    responseUpdateAvailability,
    getAvailability,
    loadingGetAvailability,
    errorGetAvailability,
  };
};
