import { CloseIcon, SearchIcon } from "@/components/Icons/Icons";
import { useApp } from "@/context/AppContext";
import {
  Card,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Fade,
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PharmacyServices from "./PharmacyServices";
import { steeperPath } from "@/constant/stepper";
import { ReportOutlined, SearchOffOutlined } from "@mui/icons-material";
import PageWrapper from "@/pages/PageWrapper";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";
import { IService } from "@/interfaces/IService";
import { IPharmacy } from "@/interfaces/IPharamacy";
import useDebounce from "@/hooks/useDebounce";

const Searchservice = ({
  onSearch,
}: {
  onSearch?: (value: string) => void;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { loading } = useApp();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const keyword = useDebounce(searchValue, 700);

  useEffect(() => {
    if (typeof onSearch === "function") {
      onSearch(keyword);
    }
  }, [keyword]);

  return (
    <OutlinedInput
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      placeholder="Type the service name"
      name="search"
      size={isMobile ? "small" : "medium"}
      endAdornment={
        loading ? (
          <InputAdornment position="end">
            <CircularProgress size={24} />
          </InputAdornment>
        ) : searchValue.length > 0 ? (
          <InputAdornment position="end">
            <CloseIcon
              className="stroke-grey hover:stroke-red-400 cursor-pointer"
              onClick={() => setSearchValue("")}
            />
          </InputAdornment>
        ) : (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }
      className=" mt-4 rounded-2xl w-full shadow-md shadow-gray-200/50"
    />
  );
};

const PharmacySelectServicePage = () => {
  const {
    selectedService,
    selectedPharmacy,
    setSelectedPharmacy,
    setSelectedService,
  } = useApp();
  const params = useParams();
  const { pathname } = useLocation();
  const pharmacyID = params.pharmacyID;
  const serviceID = params.serviceID;
  const { getService, loading, error, getPharmacy } = useReservationEndpoint();
  const navigate = useNavigate();
  const theme = useTheme();
  const [keywordSearch, setKeywordSearch] = useState("");

  useEffect(() => {
    if (serviceID && serviceID !== "all") {
      getService(serviceID)
        .then((response) => {
          setSelectedService((response?.data as IService) || null);
        })
        .catch((reason) => console.error("Error getting service ", reason));
    }
  }, [serviceID]);

  useEffect(() => {
    if (serviceID && serviceID !== "all") {
      getService(serviceID)
        .then((response) => {
          setSelectedService((response?.data as IService) || null);
        })
        .catch((reason) => console.error("Error getting service ", reason));
    }
  }, [serviceID]);

  useEffect(() => {
    if (selectedService || pharmacyID) {
      const id = selectedService?.pharmacyID || pharmacyID;
      if (id) {
        getPharmacy(id)
          .then((response) => {
            setSelectedPharmacy((response?.data as IPharmacy) || null);
          })
          .catch((reason) =>
            console.error("Error getting pharmacy data ", reason)
          );
      }
    }
  }, [selectedService, pharmacyID]);

  if (loading) {
    return (
      <PageWrapper title="Select Service">
        <Card
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            p: "2rem",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
          }}
        >
          <CircularProgress />
          <Typography>Please wait...</Typography>
        </Card>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper title="Select Service">
        <Card
          sx={{
            width: "100%",
            display: "flex",
            p: "2rem",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
          }}
        >
          <Typography>
            Sorry, we are having a problem loading pharmacy data.
          </Typography>
          <Button
            onClick={() => {
              const bodyString = `Error: ${JSON.stringify(
                error
              )} path: ${pathname}`;
              const encodedBodyString = encodeURIComponent(bodyString).replace(
                /%0A/g,
                "%0D%0A"
              );
              window.open(
                `mailto:admin@dragatron.com.au?subject=Healthcare Reservation Issue&body=${encodedBodyString}`
              );
            }}
            startIcon={<ReportOutlined />}
            color="error"
          >
            Report
          </Button>
        </Card>
      </PageWrapper>
    );
  }

  if (!loading && !error && !selectedPharmacy) {
    return (
      <PageWrapper title="Select Service">
        <Card
          sx={{
            width: "100%",
            display: "flex",
            p: "2rem",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "30vh",
            gap: "1rem",
          }}
        >
          <Typography>Sorry, Pharmacy not found</Typography>
          <Typography variant="h5">|</Typography>
          <SearchOffOutlined color="primary" />
        </Card>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title="Select Service">
      <Card
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          p: "2rem",
          mb: selectedService && "9rem",
          [theme.breakpoints.between("xs", "sm")]: {
            p: "1rem",
            mb: selectedService && "6rem",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            Select Service
          </Typography>
          <Typography variant="body2" className=" text-grey-400">
            List of the available service for {selectedPharmacy?.pharmacyName}
          </Typography>
        </Box>

        <Searchservice onSearch={(value) => setKeywordSearch(value)} />

        <PharmacyServices searchBy={keywordSearch} />

        <Fade
          in={selectedService !== null}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: theme.palette.common.white,
            width: "100%",
          }}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            py="1rem"
          >
            <Box
              sx={(theme) => ({
                width: "30%",
                flexDirection: "column",
                display: "flex",
                [theme.breakpoints.between("xs", "sm")]: {
                  width: "100%",
                  px: ".5rem",
                },
                [theme.breakpoints.between("sm", "md")]: {
                  width: "50%",
                  px: ".5rem",
                },
              })}
            >
              <Typography className="text-center" gutterBottom>
                <span>You've selected</span>&nbsp;
                <span className="font-semibold">
                  {selectedService?.serviceName}
                </span>
              </Typography>
              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={() => {
                  if (!selectedService || !pharmacyID) {
                    return;
                  }
                  navigate(
                    `/pharmacies/${pharmacyID}/appointment/${selectedService.serviceId}/${steeperPath.SELECT_TIME}`
                  );
                }}
              >
                Next: Select Date & Time
              </Button>
            </Box>
          </Box>
        </Fade>
      </Card>
    </PageWrapper>
  );
};

export default PharmacySelectServicePage;
