import MUITableLicensed from "@/components/MUITableLicensed/MUITableLicensed";
import { Patient } from "@/interfaces/IBooking";
import { IPatientAppointment } from "@/interfaces/IPatientAppointment";
import { GridColDef } from "@mui/x-data-grid-pro";
import React, { Fragment } from "react";
import { IAppointmentForm } from "./PatientDetailForm";
import { Typography } from "@mui/material";
import moment from "moment";

type TPatientsTableProps = {
  patients: IAppointmentForm[];
};

// const ActionButtonCell = (
//   params: GridRenderCellParams<IProvider, any, any, GridTreeNodeWithRender>
// ) => {
//   const { openModal } = useModal();
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         gap: ".5rem",
//       }}
//     >
//       <Link to={`${params.row.pharmacistID}/edit`}>
//         <IconButton
//           title="Edit User"
//           color="primary"
//           size="small"
//           sx={{
//             p: ".5rem",
//           }}
//         >
//           <Edit />
//         </IconButton>
//       </Link>
//       <IconButton
//         title="Delete User"
//         color="error"
//         size="small"
//         onClick={() =>
//           openModal({
//             title: "Delete User?",
//             backdropClose: false,
//             content: (
//               <DeleteUserConfirmation
//                 callbackDelete={function (): void {
//                   params.api.updateRows([
//                     { pharmacistID: params.id, _action: "delete" },
//                   ]);
//                 }}
//                 callbackDisable={function (provider: IProvider): void {
//                   logger.log(params);
//                   logger.log(provider);
//                   params.api.updateRows([
//                     { pharmacistID: params.id, isActive: provider.isActive },
//                   ]);
//                 }}
//                 {...params}
//               />
//             ),
//           })
//         }
//         sx={{
//           p: ".5rem",
//         }}
//       >
//         <Delete />
//       </IconButton>
//     </Box>
//   );
// }

const columns: GridColDef<IAppointmentForm>[] = [
  {
    field: "fullName",
    headerName: "Member Name",
    type: "string",
    flex: 1.25,
  },
  {
    field: "dateOfBirth",
    headerName: "Date Of Birth",
    type: "string",
    flex: 1,
    renderCell(params) {
      console.log(params, "params");
      const dob = moment.isMoment(params.row.dateOfBirth)
        ? params.row.dateOfBirth?.format("DD/MM/YYYY")
        : params.row.dateOfBirth;
      return <Typography display="contents">{dob}</Typography>;
    },
  },
  {
    field: "gender",
    headerName: "Gender",
    type: "string",
    flex: 1,
  },

  {
    field: "action",
    headerName: "Action",
    type: "string",
    headerAlign: "center",
    align: "center",
    flex: 0.75,
    // renderCell(params) {
    //   return <ActionButtonCell {...params} />;
    // },
  },
];

const PatientsTable = ({ patients }: TPatientsTableProps) => {
  return (
    <div>
      <MUITableLicensed
        getRowId={(row) => row.patientID}
        columns={columns}
        rows={patients}
      />
    </div>
  );
};

export default PatientsTable;
