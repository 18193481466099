import { ArrowIcon } from "@/components/Icons/Icons";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

type ScreeningHeaderProps = {};

const BackButton = () => {
  return (
    <div className="lg:flex xl:flex  xl:gap-4 lg:gap-4 flex gap-4">
      <span role="button" tabIndex={-1}>
        <div className="xl:w-7 lg:w-7 md:w-7 w-8 xl:h-7 lg:h-7 md:h-7 h-8 border-primary border-solid border-[.07rem] rounded-md flex items-center justify-center shadow-sm shadow-grey-400/40 hover:shadow-md hover:shadow-primary/20 cursor-pointer">
          <ArrowIcon className="fill-primary rotate-90" />
        </div>
      </span>
      <span className="lg:flex xl:flex lg:flex-col xl:flex-col lg:justify-start xl:justify-start lg:items-start xl:items-start lg:w-full xl:w-full flex flex-col gap-2 ">
        <span className=" lg:text-lg xl:text-lg lg:font-semibold xl:font-semibold  md:font-semibold md:text-lg ">
          Screening Questions
        </span>
        <Typography className="xl:text-sm lg:text-sm md:text-sm text-base text-grey-400 font-medium">
          Tell us more about yourself and your preferences so we can provide a
          better experience.
        </Typography>
      </span>
    </div>
  );
};

const ScreeningHeader = (props: ScreeningHeaderProps) => {
  return (
    <Card
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.between("xs", "sm")]: {
          flexDirection: "column",
        },
      })}
    >
      <BackButton />
    </Card>
  );
};

export default ScreeningHeader;
