import { Box, Button, Typography, alpha } from "@mui/material";
import SuccessGif from "../../../assets/gif/success-animation.gif";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../context/DialogContext";
import { useApp } from "../../../context/AppContext";

type Props = {
  bookingId?: string;
  status?: "Updated" | "Cancelled";
};

const ModalReservationUpdated = ({ bookingId, status }: Props) => {
  const { closeModal } = useModal();
  const { resetGlobalState } = useApp();
  const navigate = useNavigate();
  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={250} height={250} src={SuccessGif} alt="" />
      <Typography variant="h5">Thank You!</Typography>
      <Typography
        sx={(theme) => ({
          color: alpha(theme.palette.common.black, 0.5),
          textAlign: "center",
        })}
        variant="h6"
      >
        Your reservation has been successfully {status}. You should receive an
        email confirmation shortly. If you don’t see it in your inbox, please
        check your spam folder
      </Typography>

      <Box
        sx={{
          width: "50%",
        }}
      >
        {/* <Link
          to={`/reservations/${bookingId}/detail`}
          onClick={() => {
            closeModal();
            resetGlobalState();
          }}
        > */}
        <Button
          onClick={() => {
            closeModal();
            resetGlobalState();
            navigate("/", { replace: true });
          }}
          fullWidth
          size="large"
          variant="outlined"
        >
          OK
        </Button>
        {/* </Link> */}
      </Box>
    </Box>
  );
};

export default ModalReservationUpdated;
