import React, { useEffect, useRef, useState } from "react";
import PageWrapper from "../PageWrapper";
import { cn } from "../../lib/utils";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function Pricing() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    const handler = (e: Event) => {
      if (containerRef.current) {
        // if (y > window.scrollY) {
        //   console.log("scrolling up");
        // } else if (y < window.scrollY) {
        //   console.log("scrolling down");
        // }
        // setY(containerRef.current.scrollTop);
        // console.log(containerRef.current.scrollTop);
        // console.log(containerRef.current.offsetParent);
        // console.log(containerRef.current.offsetTop);

        e.target?.addEventListener("scroll", (e) => {
          console.log(e);
        });
      }
    };

    window.addEventListener("scroll", handler);
    return () => {
      window.removeEventListener("scroll", handler);
    };
  }, []);
  return (
    <PageWrapper title="Pricing">
      <header
        className={cn(
          "lg:w-full xl:w-full lg:px-10 xl:px-10 lg:py-12 xl:py-12 lg:top-0 xl:top-0 xl:left-0 lg:left-0 lg:bg-white lg:sticky xl:sticky md:sticky md:top-0  top-0 sticky xl:bg-white md:bg-white bg-white w-full py-8 items-center justify-center flex"
        )}
      >
        <a href="https://www.dragatron.com.au" target="_blank" rel="noreferrer">
          <img
            src="./images/dragatron.png"
            alt="Dragatron"
            className=" object-cover"
          />
        </a>
      </header>
      <div
        className="w-screen min-h-screen h-full bg-primary overflow-y-scroll lg:pt-16 xl:pt-8 md:pt-8 pt-8"
        ref={containerRef}
      >
        <div className="m-auto h-full w-full pricing-container">
          <stripe-pricing-table
            pricing-table-id="prctbl_1OqxVNKPEYXJKKKAqg6NE7H1"
            publishable-key={process.env.REACT_APP_STRIPE_OLD_ACCOUNT_PUBLISHABLE_KEY}
          ></stripe-pricing-table>
        </div>
      </div>
    </PageWrapper>
  );
}

export default Pricing;
