import { IVaccinationScreeningInput } from "@/interfaces/IVaccinations";
import { Box, Typography } from "@mui/material";
import AnswerRadioButton from "./AnswerRadioButton";
import { ControllerRenderProps } from "react-hook-form";
import { TScreeningForm } from "./ScreeningForm";
import CheckIcon from "@mui/icons-material/Check";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

interface IScreeningQusetionProps
  extends Omit<
    ControllerRenderProps<TScreeningForm, keyof IVaccinationScreeningInput>,
    "ref"
  > {
  question: [keyof IVaccinationScreeningInput, string];
  index: number;
}

const ScreeningQusetion = (props: IScreeningQusetionProps) => {
  const { question, value, index, ...restField } = props;
  const [key, questionValue] = question;

  return (
    <Box
      id={`question-${key}`}
      sx={(theme) => ({
        mb: 1,
        py: 1,
        borderBottom: "1px solid #e0e0e0",
        display: "flex",
        alignItems: "center",
        width: "100%",
        [theme.breakpoints.between("xs", "sm")]: {
          py: "unset",
        },
      })}
    >
      {/* Conditionally render the checklist icon */}
      {typeof value === "boolean" ? (
        <CheckCircleOutlineOutlined color="success" sx={{ width: 18, mr: 2 }} />
      ) : (
        <Typography paragraph mr={2}>
          {index + 1}.
        </Typography>
      )}

      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
          width: "100%",
          [theme.breakpoints.between("xs", "sm")]: {
            flexDirection: "column",
            alignItems: "start",
            gap: "1rem",
          },
        })}
      >
        <Typography
          variant="body2"
          sx={(theme) => ({
            flex: 4,
            textAlign: "justify",
            [theme.breakpoints.between("xs", "sm")]: {
              flex: 1,
            },
          })}
        >
          {questionValue}
        </Typography>
        <Box
          sx={(theme) => ({
            flex: 1.5,
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.between("xs", "sm")]: {
              flex: 1,
              justifyContent: "center",
              width: "100%",
            },
          })}
        >
          <AnswerRadioButton {...restField} value={value} />
        </Box>
      </Box>
    </Box>
  );
};

export default ScreeningQusetion;
