import { Box, Button, Card, Typography } from "@mui/material";
import DragatronLogoWhite from "@/assets/img/dragatron-white.png";
import SelectServiceCategory from "./SelectServiceCategory";
import { useNavigate } from "react-router-dom";
import PlacesAutocompleteService from "@/components/PlacesAutocompleteService/PlacesAutocompleteService";
import { getSuburbsFromPlacesResult } from "@/lib/utils";
import PageWrapper from "@/pages/PageWrapper";
import { useApp } from "@/context/AppContext";

const Home = () => {
  const { selectedCategory, selectedLocation } = useApp();
  const navigate = useNavigate();
  return (
    <PageWrapper title="Welcome">
      <Box
        component="main"
        className="home-background"
        sx={{
          p: "2rem",
          overflow: "hidden",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          component="header"
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <a
            href="https://www.dragatron.com.au"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={DragatronLogoWhite}
              alt="Dragatron"
              loading="lazy"
              style={{
                aspectRatio: "16/9",
              }}
            />
          </a>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            variant="h3"
            sx={(theme) => ({
              color: theme.palette.common.white,
              textAlign: "center",
            })}
            gutterBottom
          >
            Our&nbsp;
            <Box
              component="span"
              sx={(theme) => ({ color: theme.palette.primary.main })}
            >
              expertise
            </Box>
            &nbsp;at your service
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.common.white,
              textAlign: "center",
            })}
            gutterBottom
          >
            We are always fully focused on helping your family and you
          </Typography>
          <Card
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              width: "50%",
              gap: "1rem",
              py: "2rem",
              px: "1.5rem",
              overflow: "visible",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                width: "100%",
              },
              [theme.breakpoints.between("md", "lg")]: {
                flexDirection: "column",
                width: "70%",
                p: "2rem",
              },
            })}
          >
            <Box
              sx={{
                flex: 1,
                width: "100%",
              }}
            >
              <SelectServiceCategory />
            </Box>
            <Box
              sx={{
                flex: 1,
                width: "100%",
              }}
            >
              <PlacesAutocompleteService />
            </Box>
            <Box
              sx={{
                flex: 0.5,
                width: "100%",
                height: "100%",
                button: {
                  height: "100%",
                },
              }}
            >
              <Button
                disabled={!selectedCategory || !selectedLocation}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={() =>
                  navigate(
                    `/appointments/${
                      selectedCategory?.categoryID
                    }/${getSuburbsFromPlacesResult(selectedLocation)}`
                  )
                }
              >
                Next
              </Button>
            </Box>
          </Card>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Home;
