import moment, { MomentInput, Moment } from "moment-timezone";

export const getTimeZone = () => {
  return process.env.REACT_APP_DEFAULT_TIMEZONE_PHARMACY || "Australia/Sydney";
};

export const dateLib = (input?: MomentInput, strict?: boolean): Moment => {
  // If input is a string, consider parsing it explicitly
  if (typeof input === "string") {
    // Attempt to parse known date formats explicitly
    const parsedDate = moment(input, strict ? moment.ISO_8601 : undefined, strict);

    // Check if the parsed date is valid before applying timezone
    if (parsedDate.isValid()) {
      return parsedDate.tz(getTimeZone());
    }
    
    // If the date is not valid, log a warning or handle it appropriately
    console.warn("Invalid date format, falling back to original moment parsing");
    return moment.tz(input, getTimeZone());
  }

  // For other types (e.g., Date objects or undefined), pass directly to moment
  return moment(input, strict).tz(getTimeZone());
};
