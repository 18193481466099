import { Box, Typography } from "@mui/material";
import LoadingGif from "@/assets/gif/Loader.gif";

const ModalLoadingContent = () => {
  return (
    <Box
      display="flex"
      p="2rem"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <img className=" aspect-square" width={100} src={LoadingGif} alt="Loader" />
      <Typography>Please wait...</Typography>
    </Box>
  );
};

export default ModalLoadingContent;
