import { LazyQueryHookExecOptions, useLazyQuery } from "@apollo/client";
import {
  Exact,
  InputMaybe,
  ListServiceCategoryQuery,
  ListServiceCategoryQueryVariables,
  GetServiceCategoryQuery,
  TableServiceCategoryFilterInput,
  GetServiceCategoryQueryVariables,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import {
  GET_SERVICE_CATEGORY,
  LIST_SERVICE_CATEGORY,
} from "../codegens/HEALTCARE-RESERVATION/queries";

export const useServiceCategories = () => {
  const [listServiceCategoryQuery, { loading, error }] = useLazyQuery<
    ListServiceCategoryQuery,
    ListServiceCategoryQueryVariables
  >(LIST_SERVICE_CATEGORY);

  const [
    getServiceCategoryQuery,
    { loading: loadingGetServiceCategory, error: errorGetServiceCategory },
  ] = useLazyQuery<GetServiceCategoryQuery, GetServiceCategoryQueryVariables>(
    GET_SERVICE_CATEGORY
  );

  const getServiceCategories = (
    options?: Partial<
      LazyQueryHookExecOptions<
        ListServiceCategoryQuery,
        Exact<{
          limit?: InputMaybe<number> | undefined;
          nextToken?: InputMaybe<string> | undefined;
          filter?: InputMaybe<TableServiceCategoryFilterInput> | undefined;
        }>
      >
    >
  ) => {
    return listServiceCategoryQuery({
      fetchPolicy: "network-only",
      ...options,
    });
  };

  const getServiceCategory = (
    options?: Partial<
      LazyQueryHookExecOptions<
        GetServiceCategoryQuery,
        Exact<{
          categoryID: string;
        }>
      >
    >
  ) => {
    return getServiceCategoryQuery({
      fetchPolicy: "network-only",
      ...options,
    });
  };

  return {
    loadingServiceCategories: loading,
    errorServiceCategories: error,
    getServiceCategories,
    getServiceCategory,
    errorGetServiceCategory,
    loadingGetServiceCategory,
  };
};
