import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { TResult } from "../components/PlacesAutocompleteService/PlacesAutocompleteService";
import { useLocation } from "react-router-dom";
import { IPharmacy } from "../interfaces/IPharamacy";
import { App } from "../lib/app";
import { IService } from "../interfaces/IService";
import {
  CreateBookingMutationVariables,
  ServiceCategory,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { IWeekSchedule } from "../interfaces/IPharmacySchedule";
import { weekdays } from "moment";
import { IServiceSchedule } from "@/interfaces/IServiceSchedule";
import { IAvailability } from "@/interfaces/IAvailability";
import { useReservationEndpoint } from "@/hooks/useReservationEndpoint";

const states = new App();

export interface IPrevReservation
  extends NonNullable<CreateBookingMutationVariables["input"]> {}

interface IAppContextProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  selectedLocation: TResult | null;
  selectedCategory: ServiceCategory | null;
  selectedService: IService | null;
  setSelectedService: (value: IService | null) => void;
  setSelectedLocation: (value: TResult | null) => void;
  setSelectedCategory: (value: ServiceCategory | null) => void;
  setSelectedPharmacy: (value: IPharmacy | null) => void;
  selectedPharmacy: IPharmacy | null;
  getSuburs: () => string | null;
  selectedAvailability: IAvailability | null;
  setSelectedAvailability: (value: IAvailability | null) => void;
  setPrevReservation: (reservation: IPrevReservation | null) => void;
  prevReservation: IPrevReservation | null;
  resetGlobalState: () => void;
  schedule: IWeekSchedule | null;
  setSchedule: Dispatch<SetStateAction<IWeekSchedule | null>>;
  selectedServiceSchedule: IServiceSchedule | null;
}

const AppContext = createContext<IAppContextProps | null>(null);

interface IAppProviderProps {
  children: ReactNode;
}

export const AppProvider = ({ children }: IAppProviderProps) => {
  const [selectedCategory, setSelectedCategoryLocal] =
    useState<ServiceCategory | null>(null);
  const [selectedService, setSelectedServiceLocal] = useState<IService | null>(
    null
  );
  const [schedule, setSchedule] = useState<IWeekSchedule | null>(null);
  const [selectedServiceSchedule, setSelectedServiceSchedule] =
    useState<IServiceSchedule | null>(null);
  const [selectedLocation, setSelectedLocationLocal] = useState<TResult | null>(
    null
  );
  const [prevReservation, setLocalPrevReservation] =
    useState<IPrevReservation | null>(null);
  const [selectedPharmacy, setSelectedPharmacyLocal] =
    useState<IPharmacy | null>(null);

  const [selectedAvailability, setSelectedLocalAvailability] =
    useState<IAvailability | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { getServiceSchedule } = useReservationEndpoint();

  useMemo(() => {
    if (selectedService) {
      getServiceSchedule(selectedService.serviceScheduleID).then((response) => {
        if (response?.data) {
          setSelectedServiceSchedule(response.data as IServiceSchedule);
          const filteredScheduleFiels = Object.entries(response.data).filter(
            ([key, _value]) =>
              weekdays()
                .map((day) => day.toLowerCase())
                .includes(key)
          );

          const schedule: IWeekSchedule = Object.fromEntries(
            filteredScheduleFiels
          );

          setSchedule(schedule);
        }
      });
    }
  }, [selectedService]);

  const getSuburs = () => {
    if (!selectedLocation) {
      const suburbsFromUrl = params.get("clinic");
      return suburbsFromUrl;
    }

    const addressComponents = selectedLocation.address_components;
    const suburbComponent = addressComponents?.find(
      (component) =>
        component.types.includes("sublocality") ||
        component.types.includes("locality")
    );

    return suburbComponent?.long_name || null;
  };

  const setSelectedCategory = (value: ServiceCategory | null) => {
    setSelectedCategoryLocal(value);
    // states.saveSelectedCategory(value);
  };

  const setSelectedService = (value: IService | null) => {
    setSelectedServiceLocal(value);
    // states.saveSelectedService(value);
  };

  const setSelectedLocation = (value: TResult | null) => {
    setSelectedLocationLocal(value);
    // states.saveSelectedLocation(value);
  };

  const setSelectedPharmacy = (value: IPharmacy | null) => {
    setSelectedPharmacyLocal(value);
    // states.saveSelectedPharmacy(value);
  };

  const setSelectedAvailability = (value: IAvailability | null) => {
    setSelectedLocalAvailability(value);
    // states.saveSelectedAvailability(value);
  };

  const setPrevReservation = (value: IPrevReservation | null) => {
    setLocalPrevReservation(value);
    // states.savePrevReservationStates(value);
  };

  const resetGlobalState = () => {
    states.resetGlobalState();
  };

  return (
    <AppContext.Provider
      value={{
        getSuburs,
        loading,
        setLoading,
        selectedLocation,
        setSelectedLocation,
        selectedCategory,
        setSelectedCategory,
        selectedPharmacy,
        setSelectedPharmacy,
        setSelectedAvailability,
        selectedAvailability,
        setPrevReservation,
        prevReservation,
        resetGlobalState,
        schedule,
        selectedService,
        setSelectedService,
        selectedServiceSchedule,
        setSchedule,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }

  return context;
}
