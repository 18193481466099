import { gql } from "@apollo/client";

// SERVICES

export const LIST_SERVICE = gql`
  query ListService(
    $limit: Int
    $nextToken: String
    $filter: TableServiceFilterInput
  ) {
    listServices(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        createdDate
        durationInSeconds
        isActive
        pharmacyID
        pharmacyName
        serviceId
        serviceName
        serviceScheduleID
        suburb
        description
        price {
          currency
          ammount
          includeGst
        }
        rooms {
          isAvailable
          roomID
          roomName
        }
        allowPayment
      }
    }
  }
`;

export const QUERY_SERVICE_BY_CATEGORY_ID = gql`
  query ServiceByCategoryId($categoryID: ID!, $after: String, $first: Int) {
    queryServicesByCategoryIDIndex(
      categoryID: $categoryID
      after: $after
      first: $first
    ) {
      items {
        categoryID
        createdDate
        description
        durationInSeconds
        isActive
        pharmacyID
        pharmacyName
        serviceId
        serviceName
        serviceScheduleID
        suburb
        updatedDate
        rooms {
          isAvailable
          roomID
          roomName
        }
        price {
          currency
          ammount
          includeGst
        }
        allowPayment
      }
      nextToken
    }
  }
`;

export const GET_SERVICE = gql`
  query GetService($serviceId: ID!) {
    getService(serviceId: $serviceId) {
      createdDate
      durationInSeconds
      isActive
      pharmacyID
      pharmacyName
      serviceId
      serviceName
      serviceScheduleID
      suburb
      updatedDate
      price {
        currency
        ammount
        includeGst
      }
      rooms {
        isAvailable
        roomID
        roomName
      }
      allowPayment
    }
  }
`;

export const QUERY_SERVICE_BY_PHARMACY_ID = gql`
  query ServiceByPharmacyID($pharmacyID: ID!, $first: Int, $after: String) {
    queryServicesByPharmacyIDIndex(
      pharmacyID: $pharmacyID
      first: $first
      after: $after
    ) {
      nextToken
      items {
        createdDate
        durationInSeconds
        isActive
        pharmacyID
        pharmacyName
        serviceId
        serviceScheduleID
        serviceName
        suburb
        updatedDate
        price {
          currency
          ammount
          includeGst
        }
        rooms {
          isAvailable
          roomID
          roomName
        }
        allowPayment
      }
    }
  }
`;

// PHARMACY SCHEDULE

export const GET_PHARMACY_SCHEDULE = gql`
  query GetPharmacySchedule($id: ID!) {
    getPharmacySchedule(id: $id) {
      createdDate
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      id
      isActive
      pharmacyID
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

export const LIST_PHARMACY_SCHEDULE = gql`
  query ListPharmacySchedule(
    $limit: Int
    $nextToken: String
    $filter: TablePharmacyScheduleFilterInput
  ) {
    listPharmacySchedules(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        createdDate
        monday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        friday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        id
        isActive
        pharmacyID
        saturday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        sunday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        thursday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        tuesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        wednesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
      }
    }
  }
`;

export const QUERY_PHARMACY_SCHEDULE_BY_PHARMACY_ID = gql`
  query PharmacyScheduleByPharmacyID(
    $after: String
    $first: Int
    $pharmacyID: ID!
  ) {
    queryPharmacySchedulesByPharmacyIDIndex(
      pharmacyID: $pharmacyID
      after: $after
      first: $first
    ) {
      nextToken
      items {
        createdDate
        monday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        friday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        id
        isActive
        pharmacyID
        saturday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        sunday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        thursday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        tuesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
        wednesday {
          ID
          closingHour
          isClosed
          openingHour
          pharmacyID
        }
      }
    }
  }
`;

// HOURS

export const GET_HOUR = gql`
  query GetHour($ID: ID!) {
    getHours(ID: $ID) {
      ID
      closingHour
      isClosed
      openingHour
      pharmacyID
    }
  }
`;

export const LIST_HOUR = gql`
  query ListHour(
    $limit: Int
    $nextToken: String
    $filter: TableHoursFilterInput
  ) {
    listHours(limit: $limit, nextToken: $nextToken, filter: $filter) {
      nextToken
      items {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

export const QUERY_HOUR_BY_PHARMACY_ID = gql`
  query HourByPharmacyID($pharmacyID: ID!, $after: String, $first: Int) {
    queryHoursByPharmacyIDIndex(
      pharmacyID: $pharmacyID
      after: $after
      first: $first
    ) {
      nextToken
      items {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

// SERVICE SCHEDULE

export const GET_SERVICE_SCHEDULE = gql`
  query GetServiceSchedule($serviceScheduleID: ID!) {
    getServiceSchedule(serviceScheduleID: $serviceScheduleID) {
      createdDate
      lastAvailableDate
      friday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      isActive
      monday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      pharmacyID
      saturday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      serviceScheduleID
      sunday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      thursday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      tuesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
      wednesday {
        ID
        closingHour
        isClosed
        openingHour
        pharmacyID
      }
    }
  }
`;

// AVAILABILITY
export const LIST_AVAILABILITY = gql`
  query ListAvailability(
    $limit: Int
    $nextToken: String
    $filter: TablePharmacyAvailabilityFilterInput
  ) {
    listPharmacyAvailabilities(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      nextToken
      items {
        availabilityID
        durationInSeconds
        expire_at
        isAvailable
        maxSlotsAvailable
        maxSlotsInStore
        pharmacyID
        serviceId
        serviceName
        timeslot
        rooms {
          isAvailable
          roomID
          roomName
        }
      }
    }
  }
`;

export const GET_AVAILABILITY = gql`
  query GetAvailability($availabilityID: ID!) {
    getPharmacyAvailability(availabilityID: $availabilityID) {
      availabilityID
      durationInSeconds
      expire_at
      isAvailable
      maxSlotsAvailable
      maxSlotsInStore
      pharmacyID
      serviceId
      serviceName
      timeslot
      rooms {
        isAvailable
        roomID
        roomName
      }
    }
  }
`;

// BOOKING

export const GET_BOOKING = gql`
  query GetBooking($bookingID: ID!) {
    getBookings(bookingID: $bookingID) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        token
        clientSecret
        status
      }
      notes
      IsAssessmentCompleted
    }
  }
`;

// SERVICE CATEGORY
export const LIST_SERVICE_CATEGORY = gql`
  query ListServiceCategory(
    $limit: Int
    $nextToken: String
    $filter: TableServiceCategoryFilterInput
  ) {
    listServiceCategories(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    ) {
      items {
        categoryDescription
        categoryID
        categoryName
      }
      nextToken
    }
  }
`;

export const GET_SERVICE_CATEGORY = gql`
  query GetServiceCategory($categoryID: ID!) {
    getServiceCategory(categoryID: $categoryID) {
      categoryDescription
      categoryID
      categoryName
    }
  }
`;
