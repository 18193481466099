import { Box, Button, Typography, alpha } from "@mui/material";
import SuccessGif from "../../../assets/gif/success-animation.gif";
import { Link } from "react-router-dom";
import { useModal } from "../../../context/DialogContext";
import { useApp } from "../../../context/AppContext";
import { BOOKING_STATUS, IBooking } from "../../../interfaces/IBooking";
import { IClinicAgedCareForm } from "@/interfaces/IAgedCare";

type Props = {
  pharmacyID: IClinicAgedCareForm["pharmacyID"];
  clinicID: IClinicAgedCareForm["clinicID"];
};

const ModalSuccessAgedCare = ({ pharmacyID, clinicID }: Props) => {
  const { closeModal } = useModal();

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={"1rem"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={250} height={250} src={SuccessGif} alt="" />
      <Typography variant="h5">Thank You!</Typography>
      <Typography
        sx={(theme) => ({
          color: alpha(theme.palette.common.black, 0.5),
          textAlign: "center",
        })}
        variant="h6"
      >
        Your aged-care service has been successfully created.
      </Typography>

      <Box
        sx={{
          width: "50%",
        }}
      >
        <Link
          to={`/`}
          // to={`/pharmacies/${pharmacyID}/aged-care-clinics/${clinicID}`}
          replace={true}
          onClick={() => closeModal()}
        >
          <Button fullWidth size="large" variant="outlined">
            Ok
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ModalSuccessAgedCare;
