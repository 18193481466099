import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import SuccessIcon from "@/assets/gif/success-animation.gif";
import ErrorIcon from "@/assets/gif/failed-animation.gif";
import { useApp } from "@/context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "@/hooks/useApi";
import { useUpdateBooking } from "@/hooks/useUpdateBooking";
type Props = {};

const InfoIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V10C1.5 11.3807 2.61929 12.5 4 12.5H10C11.3807 12.5 12.5 11.3807 12.5 10V4C12.5 2.61929 11.3807 1.5 10 1.5ZM4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H7.25C7.66421 6.25 8 6.58579 8 7V10.5C8 10.9142 7.66421 11.25 7.25 11.25C6.83579 11.25 6.5 10.9142 6.5 10.5V7.75H6C5.58579 7.75 5.25 7.41421 5.25 7Z"
      fill="white"
    />
    <path
      d="M5.75 4C5.75 3.31075 6.31075 2.75 7 2.75C7.68925 2.75 8.25 3.31075 8.25 4C8.25 4.68925 7.68925 5.25 7 5.25C6.31075 5.25 5.75 4.68925 5.75 4Z"
      fill="white"
    />
  </svg>
);

const STATUS_CONTENT_MAP = {
  succeeded: {
    text: "Payment succeeded",
    iconColor: "#30B130",
    icon: SuccessIcon,
    description:
      "Your payment has been processed successfully. Your reservation is now confirmed",
  },
  processing: {
    text: "Your payment is processing.",
    iconColor: "#6D6E78",
    icon: InfoIcon,
    description: "Your payment is still on the processing status.",
  },
  requires_payment_method: {
    text: "Payment Failed",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
    description: "Your payment was not successful, please try again.",
  },
  default: {
    text: "Something went wrong",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
    description: "Please try again later.",
  },
};

const PaymentCallback = (props: Props) => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(true);
  const { resetGlobalState } = useApp();
  const navigate = useNavigate();
  const params = useParams();
  const bookingID = params.bookingID;
  const { updateBooking } = useUpdateBooking();

  const [status, setStatus] =
    useState<keyof typeof STATUS_CONTENT_MAP>("default");
  const [intentId, setIntentId] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        if (!paymentIntent) {
          return;
        }

        setStatus(paymentIntent.status as keyof typeof STATUS_CONTENT_MAP);
        setIntentId(paymentIntent.id);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [stripe]);

  useEffect(() => {
    if (intentId && bookingID && status === "succeeded") {
      updateBooking({
        variables: {
          input: {
            bookingID,
            payment: {
              status: "PAID",
            },
          },
        },
      });
    }
  }, [intentId, bookingID, status]);

  if (error) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>
          An error occured{" "}
          <Box component="span" color="error">
            {error.message}
          </Box>
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress />
        <Typography>Please wait...</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          justifyContent: "center",
          alignItems: "center",
          p: "2rem",
        }}
      >
        <img
          width={200}
          src={STATUS_CONTENT_MAP[status].icon as string}
          alt="Success animated"
        />
        <Typography variant="h6" gutterBottom>
          {STATUS_CONTENT_MAP[status].text}
        </Typography>
        <Typography gutterBottom className="text-center">
          {STATUS_CONTENT_MAP[status].description}
        </Typography>
        <Box sx={{ mt: "1rem" }}>
          <Button
            variant="outlined"
            onClick={() => {
              resetGlobalState();
              navigate(`/appointments/${bookingID}/detail`);
            }}
          >
            Detail Reservation
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default PaymentCallback;
