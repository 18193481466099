import PageWrapper from "../PageWrapper";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useServices } from "@/hooks/useServices";
import { IService } from "@/interfaces/IService";
import { formatToABooking, formatToAService } from "@/lib/layers";
import {
  Bookings,
  OnCreateBookingSubscription,
  OnCreateBookingSubscriptionVariables,
  Service,
} from "@/codegens/HEALTCARE-RESERVATION/__generated__/graphql";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { useGetBooking } from "@/hooks/useGetBooking";
import { IBooking } from "@/interfaces/IBooking";
import useApi from "@/hooks/useApi";
import { useUpdateBooking } from "@/hooks/useUpdateBooking";
import { useSubscription } from "@apollo/client";
import { ONCREATE_BOOKING_SUBSCRIPTION } from "@/codegens/HEALTCARE-RESERVATION/subscriptions";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    "pk_test_TYooMQauvdEDq54NiTphI7jx"
);

const PaymentDetail = () => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");
  const params = useParams();
  const serviceID = params.serviceID;
  const bookingID = params.bookingID;
  const { getService, loadingGetService, errorGetService } = useServices();
  const [booking, setBooking] = useState<IBooking | null>(null);
  const [service, setService] = useState<IService | null>(null);
  const [loading, setLoading] = useState(true);
  const api = useApi(process.env.REACT_APP_API_ENDPOINT);
  const navigate = useNavigate();

  const { fetchBooking, loadingGetBooking, errorGetBooking } = useGetBooking();
  const { updateBooking, loadingUpdateBooking } = useUpdateBooking();

  useSubscription<
    OnCreateBookingSubscription,
    OnCreateBookingSubscriptionVariables
  >(ONCREATE_BOOKING_SUBSCRIPTION, {
    variables: {
      bookingID: bookingID!,
    },
    async onData({ data }) {
      const booking = formatToABooking(data.data?.onCreateBookings as Bookings);

      if (!booking) return;

      setBooking(booking);
      if (
        booking?.payment.clientSecret !== undefined &&
        booking.payment.clientSecret !== null &&
        booking.payment.clientSecret.length > 0
      ) {
        setClientSecret(booking.payment.clientSecret);
        setLoading(false);
      } else {
        api
          .post("/payment", {
            type: "CREATE_RESERVATION_INTENT",
            bookingID,
            options: {
              receipt_email: booking.patient.emailAddress,
            },
          })
          .then(async (response) => {
            if (response.status === 200) {
              const secret = response.data.client_secret;
              setClientSecret(secret);

              await updateBooking({
                variables: {
                  input: {
                    bookingID: booking.bookingID,
                    payment: {
                      channel: booking.payment?.channel,
                      clientSecret: secret,
                      status: booking.payment?.status,
                      token: booking.bookingID,
                    },
                  },
                },
              });
              setLoading(false);
            }
          })
          .finally(() => setLoading(false));
      }
    },
    onError(error) {
      setLoading(false);
      console.error("Subscription error ", error);
    },
  });

  useEffect(() => {
    if (bookingID) {
      setLoading(true);
      fetchBooking({
        variables: {
          bookingID,
        },
        onCompleted(data) {
          const booking = formatToABooking(data.getBookings as Bookings);

          if (!booking) return;

          setBooking(booking);
          if (
            booking?.payment.clientSecret !== undefined &&
            booking.payment.clientSecret !== null &&
            booking.payment.clientSecret.length > 0
          ) {
            setClientSecret(booking.payment.clientSecret);
          } else {
            api
              .post("/payment", {
                type: "CREATE_RESERVATION_INTENT",
                bookingID,
                options: {
                  receipt_email: booking.patient.emailAddress,
                },
              })
              .then(async (response) => {
                if (response.status === 200) {
                  const secret = response.data.client_secret;
                  setClientSecret(secret);

                  await updateBooking({
                    variables: {
                      input: {
                        bookingID: booking.bookingID,
                        payment: {
                          channel: booking.payment?.channel,
                          clientSecret: secret,
                          status: booking.payment?.status,
                          token: booking.bookingID,
                        },
                      },
                    },
                  });
                }
              });
          }

          setLoading(false);
        },
        onError(error) {
          console.error("Error getting booking detail ", error);
          setLoading(false);
        },
      });
    }
  }, [bookingID]);

  useEffect(() => {
    if (serviceID) {
      setLoading(true);
      getService({
        variables: {
          serviceId: serviceID,
        },
        onCompleted(data) {
          const service = formatToAService(data.getService as Service);
          setService(service);
          setLoading(false);
        },
        onError(error) {
          console.error("Error getting service ", error);
          setLoading(false);
        },
      });
    }
  }, [serviceID]);

  if (
    loading ||
    loadingGetService ||
    loadingGetBooking ||
    loadingUpdateBooking
  ) {
    return (
      <PageWrapper title="Payment">
        <Box
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            minHeight: "30vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography>Please wait...</Typography>
        </Box>
      </PageWrapper>
    );
  }

  if (errorGetService || errorGetBooking) {
    return (
      <PageWrapper title="Payment">
        <Box
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            minHeight: "30vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>
            Sorry, an error occurred. Feel free to report this problem.
          </Typography>
        </Box>
      </PageWrapper>
    );
  }

  if (
    (!loadingGetBooking && !errorGetBooking && !booking) ||
    (!loadingGetService && !errorGetService && !service)
  ) {
    return (
      <PageWrapper title="Payment">
        <Box
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            minHeight: "30vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card className="xl:w-full lg:w-full md:w-full xl:h-auto lg:h-auto md:h-auto lg:max-w-2xl xl:max-w-2xl lg:py-16 xl:py-16 lg:px-20 xl:px-20 z-10 flex flex-col items-center w-full h-full rounded-2xl shadow-xl p-4">
            <Typography className="text-center" gutterBottom>
              Sorry, Reservation not found.
            </Typography>
            <Typography className="text-center" variant="body2">
              Feel free to contact us if you believe this is a mistake at&nbsp;
              <a href="mailto:admin@dragatron.com.au">here</a>
            </Typography>
            <p
              role="button"
              className="italic underline cursor-pointer"
              onClick={() => navigate(-1)}
            >
              Back
            </p>
          </Card>
        </Box>
      </PageWrapper>
    );
  }

  if (!clientSecret) {
    return (
      <PageWrapper title="Payment">
        <Box
          width="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
            minHeight: "30vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography>Please wait...</Typography>
        </Box>
      </PageWrapper>
    );
  }

  if (booking && booking.payment.status === "PAID") {
    return <Navigate to={`/appointments/${booking.bookingID}/detail`} />;
  }

  return (
    <PageWrapper title="Payment">
      {/* <>Payment Detail</> */}
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: clientSecret,
        }}
      >
        <PaymentForm
          booking={booking}
          service={service}
          clientSecret={clientSecret}
        />
      </Elements>
    </PageWrapper>
  );
};

export default PaymentDetail;
