import { clsx, type ClassValue } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import { TResult } from "../components/PlacesAutocompleteService/PlacesAutocompleteService";
import { v4 } from "uuid";
import { IService } from "@/interfaces/IService";
import { IBooking } from "@/interfaces/IBooking";

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export function generateTime() {
  const times = [];
  const maxLength = 27;

  let openAt = moment().startOf("day").add(7, "hour");
  for (let index = 0; index < maxLength; index++) {
    const time = openAt.clone().add(30, "minutes");
    times.push(time);
    openAt = time;
  }

  return times;
}

export function getSuburbsFromPlacesResult(places: TResult | null) {
  if (!places) {
    console.info("Places is not initialez yet!");
    return "";
  }

  const addressComponents = places.address_components;
  const suburbComponent = addressComponents?.find(
    (component) =>
      component.types.includes("sublocality") ||
      component.types.includes("locality")
  );

  return suburbComponent?.long_name || null;
}

export function getStateFromPlacesResult(places: TResult) {
  const addressComponents = places.address_components;
  const suburbComponent = addressComponents?.find((component) =>
    component.types.includes("administrative_area_level_1")
  );

  return suburbComponent?.long_name || null;
}

export function getPostalCodeFromPlacesResult(places: TResult) {
  const addressComponents = places.address_components;
  const suburbComponent = addressComponents?.find((component) =>
    component.types.includes("postal_code")
  );

  return suburbComponent?.long_name || null;
}

export function uuid() {
  return v4();
}

export function extractFullname(fullname: string) {
  if (!fullname) {
    return null;
  }

  let [firstName, middleName, lastName] = fullname.split(" ");

  if (!lastName) {
    lastName = middleName;
    middleName = "";
  }

  return {
    firstName,
    middleName,
    lastName,
  };
}

export function formatToPrice(cent: number, currency: string) {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: 2,
  }).format(cent / 100);
}

export function revertCalculatedPrice(price: IService["price"]) {
  const gstRate = price.includeGst ? 0.1 : 0;
  const amountInDollars = price.amount / 100;

  // Calculate the original amount (excluding GST)
  const originalAmountInDollars = price.includeGst
    ? amountInDollars / (1 + gstRate)
    : amountInDollars;

  // Calculate the GST based on the original amount
  const gst = originalAmountInDollars * gstRate;

  return {
    originalAmount: Math.round(originalAmountInDollars * 100), // Convert back to cents
    gst: Math.round(gst * 100), // Convert back to cents
    currency: price.currency,
  };
}

export function getPatientLengthFromABooking(data: any) {
  if (Array.isArray(data?.patient)) {
    return data.length;
  }

  if (data?.patient) {
    return 1;
  }

  return 0;
}
export function isWithin24Hours(timeslot: IBooking["timeslot"]) {
  const now = moment();
  const timeslotMoment = moment(timeslot);

  // Calculate the difference in hours between now and the timeslot
  const hoursDifference = timeslotMoment.diff(now, "hours");

  // Check if the timeslot is within 24 hours
  return hoursDifference <= 24 && hoursDifference >= 0;
}
