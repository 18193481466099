import { gql } from "@apollo/client";

export const ONCREATE_BOOKING_SUBSCRIPTION = gql`
  subscription OnCreateBooking($bookingID: ID!) {
    onCreateBookings(bookingID: $bookingID) {
      BookingMonth
      InjectionBrandName
      VaccineBrandName
      availabilityID
      bookingDate
      bookingID
      createdDate
      durationInSeconds
      patientID
      pharmacyID
      serviceId
      status
      timeslot
      updatedDate
      patient {
        addressLineOne
        dateOfBirth
        emailAddress
        firstName
        gender
        lastName
        locality
        patientID
        pharmacyID
        phoneNumber
        postCode
        createdDate
        state
      }
      payment {
        channel
        token
        clientSecret
        status
      }
    }
  }
`;
