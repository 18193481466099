import {
  LazyQueryHookExecOptions,
  QueryResult,
  useLazyQuery,
} from "@apollo/client";
import { GET_BOOKING } from "../codegens/HEALTCARE-RESERVATION/queries";
import {
  Exact,
  GetBookingQuery,
  GetBookingQueryVariables,
} from "../codegens/HEALTCARE-RESERVATION/__generated__/graphql";

export const useGetBooking = () => {
  const [queryGetBooking, { loading, error, data }] = useLazyQuery<
    GetBookingQuery,
    GetBookingQueryVariables
  >(GET_BOOKING);

  const fetchBooking = (
    options: Partial<
      LazyQueryHookExecOptions<GetBookingQuery, Exact<{ bookingID: string }>>
    >
  ) => {
    return queryGetBooking({ fetchPolicy: "network-only", ...options });
  };

  return {
    fetchBooking,
    loadingGetBooking: loading,
    errorGetBooking: error,
    data
  }
};
