import { Box } from "@mui/material";

import {
  TPickersCalendarHeaderProps,
} from "../../../context/CalendarContext";
import SelectMonth, { TSelectMonthProps } from "./SelectMonth";
import DayNavigation, {
  IDayNavigationProps,
} from "../CalendarDays/DayNavigation";


interface ICalendarHeaderProps
  extends TSelectMonthProps,
    IDayNavigationProps,
    Omit<
      TPickersCalendarHeaderProps,
      | "currentMonth"
      | "onMonthChange"
      | "view"
      | "views"
      | "reduceAnimations"
      | "minDate"
      | "timezone"
      | "maxDate"
    > {}

const CalendarHeader = (props: ICalendarHeaderProps) => {
  const {
    renderDayNavigationView,
    selectProps,
    prevIcon,
    menuItemProps,
    nextIcon,
    prevIconProps,
    nextIconProps,
    containerDayNavigationSlotProps,
    containerSelectMonthSlotProps,
    formControlProps,
  } = props;

  return (
    <Box
      width="100%"
      minWidth="15rem"
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="space-between"
    >
      <SelectMonth
        formControlProps={formControlProps}
        selectProps={selectProps}
        menuItemProps={menuItemProps}
        containerSelectMonthSlotProps={containerSelectMonthSlotProps}
      />
      <DayNavigation
        renderDayNavigationView={renderDayNavigationView}
        prevIcon={prevIcon}
        nextIcon={nextIcon}
        containerDayNavigationSlotProps={containerDayNavigationSlotProps}
        prevIconProps={prevIconProps}
        nextIconProps={nextIconProps}
      />
    </Box>
  );
};

export default CalendarHeader;
