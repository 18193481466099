import { Typography } from "@mui/material";
import { useApp } from "../../../context/AppContext";
import moment from "moment";
import { CalendarMonthOutlined } from "@mui/icons-material";

const AppoinmentDetail = () => {
  const { selectedPharmacy, selectedService, selectedAvailability } = useApp();
  return (
    <div className="xl:w-full md:w-full lg:w-full xl:flex lg:flex md:flex xl:flex-col md:flex-col lg:flex-col xl:gap-4 lg:gap-4 md:gap-4 w-full flex flex-col gap-4 my-4">
      <div className="xl:flex lg:flex md:flex xl:gap-6 lg:gap-6 md:gap-6 xl:mb-2 md:mb-2 lg:mb-2 flex gap-6">
        <CalendarMonthOutlined color="primary" />
        <Typography className=" text-grey-200 font-medium">
          Appointment Details
        </Typography>
      </div>
      <div className="xl:flex lg:flex md:flex flex w-full">
        <div className="lg:w-20 w-20">What</div>
        <div className="w-full">{selectedService?.serviceName}</div>
      </div>
      <div className="xl:flex lg:flex md:flex flex w-full">
        <div className="lg:w-20 w-20">When</div>
        <div className="w-full">
          {moment(selectedAvailability?.timeslot)
            .tz(
              process.env.REACT_APP_DEFAULT_TIMEZONE_PHARMACY ||
                "Australia/Sydney"
            )
            .format("dddd, MMMM Do YYYY, h:mm a")}
        </div>
      </div>
      <div className="xl:flex lg:flex md:flex flex w-full">
        <div className="lg:w-20 w-20">Where </div>
        <div className="w-full">
          <Typography>{selectedPharmacy?.pharmacyName}</Typography>
          <Typography className=" text-grey-200">
            <a
              target="_blank"
              className=" text-primary"
              href={`http://maps.google.com/maps?q=${selectedPharmacy?.pharmacyName}`}
              rel="noreferrer"
            >
              {selectedPharmacy?.pharmacyAddress}
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AppoinmentDetail;
