import { ChangeEvent } from "react";
import { Radio, RadioGroup, FormControlLabel, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IVaccinationScreeningInput } from "@/interfaces/IVaccinations";
import { ControllerRenderProps, useFormContext } from "react-hook-form";
import { TScreeningForm } from "./ScreeningForm";

interface IAnswerRadioButtonProps
  extends Omit<
    ControllerRenderProps<TScreeningForm, keyof IVaccinationScreeningInput>,
    "ref"
  > {
  // fieldName: keyof IVaccinationScreeningInput;
}

const StyledRadioButton = styled(FormControlLabel)(({ theme }) => ({
  ".MuiRadio-root": {
    display: "none", // hide the actual radio button
  },
}));

const AnswerRadioButton = (props: IAnswerRadioButtonProps) => {
  const { value, onChange, name, ...restProps } = props;
  const { setValue } = useFormContext<TScreeningForm>();

  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value === "no") {
      setValue(name, false);
      return;
    }
    setValue(name, true);
  };

  return (
    <RadioGroup
      row
      {...restProps}
      onChange={handleChange}
      aria-label="yes-no"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <StyledRadioButton
        value="yes"
        control={<Radio />}
        label={
          <Chip
            sx={{
              px: ".75rem",
            }}
            variant="outlined"
            color={value === true ? "warning" : "default"}
            label={"Yes"}
          />
        }
      />
      <StyledRadioButton
        value="no"
        control={<Radio />}
        label={
          <Chip
            sx={{
              px: ".75rem",
            }}
            label={"No"}
            variant="outlined"
            color={value === false ? "success" : "default"}
          />
        }
      />
    </RadioGroup>
  );
};

export default AnswerRadioButton;
