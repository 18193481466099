import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useModal } from "../context/DialogContext";


const GlobalModal: React.FC = () => {
  const {
    isOpen,
    content,
    closeModal,
    title,
    backdropClose,
    openModal,
    sx,
    ...rest
  } = useModal();

  const handleClose = (
    _event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (
      (reason === "backdropClick" || reason === "escapeKeyDown") &&
      !backdropClose
    )
      return;

    closeModal();
  };

  const ModalTitle = () => {
    if (typeof title === "string") {
      return <DialogTitle>{title}</DialogTitle>;
    } else if (React.isValidElement(title)) {
      return title;
    } else {
      return null; // or some fallback
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} sx={{ ...sx }} {...rest}>
      <ModalTitle />
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default GlobalModal;
