import { useMemo } from "react";
import axios from "axios";

const useApi = (url?: string) => {
  const username = process.env.REACT_APP_API_USERNAME_AUTHORIZER;
  const password = process.env.REACT_APP_API_PASSWORD_AUTHORIZER;
  const uri = url || process.env.REACT_APP_PUBLIC_API_ENDPOINT;

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: uri, // Replace with your base URL
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    });
  }, [username, password]);

  return axiosInstance;
};

export default useApi;
