import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useModal } from "@/context/DialogContext";
import { MutableRefObject, useState } from "react";
import SignaturePad from "./SignaturePad";
import SignatureCanvas from "react-signature-canvas";

interface IModalConfirmationSubmitScreeningProps {
  onOk?: (signature?: string) => Promise<void>;
  signaturePadRef?: MutableRefObject<SignatureCanvas | null>;
}

const ModalConfirmationSubmitScreening = ({
  onOk,
  signaturePadRef,
}: IModalConfirmationSubmitScreeningProps) => {
  const { closeModal } = useModal();

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [isNotSigned, setIsNotSigned] = useState(true);

  const getSign = () => {
    if (!signaturePadRef?.current?.isEmpty()) {
      const base64 = signaturePadRef?.current?.toDataURL("image/png");
      const base64WithoutPrefix = base64?.split(",")[1]; // Get the part after the comma

      return base64WithoutPrefix;
    }

    return "";
  };

  const handleClearSignature = () => {
    if (!signaturePadRef) {
      console.info("Ref did not initialized yet");
      return;
    }

    if (!signaturePadRef.current) {
      console.info("Ref did not initialized yet");
      return;
    }

    signaturePadRef.current.clear();
    setIsNotSigned(true);
  };

  const shouldShowSignaturePad = useMediaQuery(
    theme.breakpoints.between("xs", "lg")
  );

  return (
    <Box
      sx={{
        p: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
      }}
    >
      <Typography>Are you sure you want to submit?</Typography>
      {shouldShowSignaturePad && (
        <SignaturePad
          onEndSigned={(event) => {
            // setValue("customerSignature", getSign());
            setIsNotSigned(false);
          }}
          hideClear
          ref={signaturePadRef}
          isSigned={isNotSigned}
        />
      )}
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          [theme.breakpoints.between("xs", "lg")]: {
            flexDirection: "column",
          },
        })}
      >
        <Button
          disabled={loading}
          onClick={() => closeModal()}
          variant="outlined"
          fullWidth
        >
          Re-check
        </Button>

        {shouldShowSignaturePad ? (
          <>
            <Button
              disabled={loading || isNotSigned}
              onClick={handleClearSignature}
              variant="outlined"
              fullWidth
            >
              clear signature
            </Button>
            <Button
              disabled={loading || isNotSigned}
              onClick={async () => {
                setLoading(true);
                if (typeof onOk === "function") await onOk(getSign());
                setLoading(false);
              }}
              variant="contained"
              fullWidth
            >
              {loading ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </>
        ) : (
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              if (typeof onOk === "function") await onOk();
              setLoading(false);
            }}
            variant="contained"
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Save"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ModalConfirmationSubmitScreening;
