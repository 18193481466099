import { SearchOffOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" alignItems="center" gap="1rem">
        <Typography variant="h6">Not Found</Typography>
        <Typography variant="h6">|</Typography>
        <SearchOffOutlined color="primary" />
      </Box>
    </Box>
  );
};

export default NotFound;
