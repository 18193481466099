import { gql } from "@apollo/client";

export const QUERY_PHARMACY_BY_SUBURBS = gql`
  query PhamraciesBySuburb($Suburb: String!, $first: Int, $after: String) {
    queryPharmaciesBySuburb(Suburb: $Suburb, first: $first, after: $after) {
      nextToken
      items {
        id
        pharmacyAddress
        pharmacyID
        pharmacyName
        pharmacyPhoneNumber
        openTime
        closeTime
        allowPayment
      }
    }
  }
`;

export const BATCH_PHARMACY_BY_PHARMACY_IDS = gql`
  query BatchGetPharmacyQuery($pharmacyIDs: [ID]!) {
  batchPharmaciesByPharmacyIdsIndex(pharmacyIDs: $pharmacyIDs) {
     id
      Suburb
      pharmacyID
      pharmacyEmailAddress
      pharmacyAddress
      pharmacyName
      pharmacyPhoneNumber
      allowPayment
  }
}

`;

export const GET_PARMACY = gql`
  query GetPharmacy($id: ID!) {
    getPharmacy(id: $id) {
      id
      Suburb
      pharmacyID
      pharmacyEmailAddress
      pharmacyAddress
      pharmacyName
      pharmacyPhoneNumber
      openTime
      closeTime
      allowPayment
    }
  }
`;

export const LIST_PHARMACY = gql`
  query ListPharmacies(
    $filter: TablePharmacyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPharmacies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Suburb
        pharmacyID
        pharmacyEmailAddress
        pharmacyAddress
        pharmacyName
        pharmacyPhoneNumber
        openTime
        closeTime
        allowPayment
      }
      nextToken
    }
  }
`;

// PATIENT

export const LIST_PATIENT = gql`
  query ListPatient(
    $filter: TablePatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        addressLineOne
        addressLineTwo
        createdDate
        dateOfBirth
        emailAddress
        firstName
        gender
        id
        ihiNumber
        initial
        isAboriginal
        lastName
        locality
        medicareCardNumber
        medicareExpiryDate
        medicareIRN
        middleName
        mobileNumber
        onlyNameIndicator
        patientID
        pharmacistID
        pharmacyID
        phoneNumber
        postCode
        providerNumber
        state
      }
    }
  }
`;
